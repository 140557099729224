import {httpsCallable} from "firebase/functions";
import app from "../firebase";

const REGION =
  window.location.hostname == "localhost" ? "us-central1" : "europe-west3";

const functions = app.functions(REGION);

export function getOrgs() {
  return httpsCallable(functions,'admin-orgs-listOrgs')()
    .then((result) => {
      return result.data;
    });
}

export function getOrg({id} : {id: string}) {
  return httpsCallable(functions,'admin-orgs-getOrg')({id: id})
    .then((result) => {
      return result.data;
    });
}

export function searchOrgs({term} : {term: string}) {
  return httpsCallable(functions,'admin-orgs-searchOrgs')({q: term})
    .then((result) => {
      return result.data;
    });
}

export function postOrg({
  name,
  type
 } : {
  name: string,
  type: string,
 }) {
   return httpsCallable(functions,'admin-orgs-createOrg')({
    name: name,
    type: type,    
   })
     .then((result) => {
       return result.data;
     });  
 }

 export function postOrgs({ orgs } : { orgs: array }) {
     return httpsCallable(functions,'admin-orgs-createOrgs')(orgs)
     .then((result) => {
         return result.data;
     });
 }

 export function putOrg({
  id,  
  name,
 } : {
  id: string,
  name: string,  
 }) {
   return httpsCallable(functions,'admin-orgs-updateOrg')({
     id: id,
     payload: {
      name: name,
     }
   })
     .then((result) => {
       return result.data;
     });  
 }

 export function deleteOrg({id} : {id: string}) {
  return httpsCallable(functions,'admin-orgs-deleteOrg')({
    id: id,
  })
    .then((result) => {
      return result.data;
    });
}

export function listOrgUsers({id} : {id: string}) {
  return httpsCallable(functions,'admin-orgs-listOrgUsers')({
    id: id,
  })
    .then((result) => {
      return result.data;
    });
}

export function addOrgUser({id, users} : {id: string, users: any}) {
  return httpsCallable(functions,'admin-orgs-addOrgUsers')({
    id: id,
    users: users,
  })
    .then((result) => {
      return result.data;
    });
}

export function deleteOrgUser({id, users} : {id: string, users: any}) {
  return httpsCallable(functions,'admin-orgs-removeOrgUsers')({
    id: id,
    users: users,
  })
    .then((result) => {
      return result.data;
    });
}


export function getOrgCampaigns({id} : {id: string}) {
  return httpsCallable(functions,'admin-orgs-listOrgCampaigns')({
    id: id,
  })
    .then((result) => {
      return result.data;
    });
}

export function addOrgCampaign({id, campId} : {id: string, campId: string}) {
  return httpsCallable(functions,'admin-orgs-addOrgCampaign')({
    id: id,
    campId: campId,
  })
    .then((result) => {
      return result.data;
    });
}

export function deleteOrgCampaign({id, campId} : {id: string, campId: string}) {
  return httpsCallable(functions,'admin-orgs-removeOrgCampaign')({
    id: id,
    campId: campId,
  })
    .then((result) => {
      return result.data;
    });
}

export function getOrgOrgs({id} : {id: string}) {
  return httpsCallable(functions,'admin-orgs-listOrgOrgs')({id: id})
    .then((result) => {
      return result.data;
    });
}

export function addOrgOrg({id, childId} : {id: string, childId: string}) {
  return httpsCallable(functions,'admin-orgs-addOrg')({
    id: id,
    childId: childId,
  })
    .then((result) => {
      return result.data;
    });
}

export function deleteOrgOrg({id, childId} : {id: string, childId: string}) {
  return httpsCallable(functions,'admin-orgs-removeOrg')({
    id: id,
    childId: childId,
  })
    .then((result) => {
      return result.data;
    });
}


export function listOrgBrands({id} : {id: string}) {
  return httpsCallable(functions,'admin-orgs-listOrgBrands')({id: id})
    .then((result) => {
      return result.data;
    });
}


export function addOrgBrands({id, brandId} : {id: string, brandId: string}) {
  return httpsCallable(functions,'admin-orgs-addOrgBrands')({
    id: id,
    brandId: brandId,
  })
    .then((result) => {
      return result.data;
    });
}

export function removeOrgBrands({id, brandId} : {id: string, brandId: string}) {
  return httpsCallable(functions,'admin-orgs-removeOrgBrands')({
    id: id,
    brandId: brandId,
  })
    .then((result) => {
      return result.data;
    });
}