import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ReactJson from "react-json-view";

import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import { Button, SectionTitle, Form, Field, Input, ControlledSelect } from "@mutable/meiosis";

import useAsync from "../services/utils/useAsync";

import { postUsers } from "../services/users";

export default function UsersCreate(props: any) {
  const history = useHistory();

  const [updatedUsers, setUpdatedUsers] = useState({});

  const [postUsersAsync, loadingPostUsers, errorPostUsers] = useAsync<typeof postUsers>(
    (...params) => postUsers(...params), res => {
      setUpdatedUsers(res);
    });

    return (
      <LayoutGrid1>
        <div className="min-h-screen mb-10">
          <SectionTitle title="Create New Users" />
          <div className="bg-gray-100 p-5 border border-gray-200 rounded">
            <Form
              onSubmit={(data) => {
                postUsersAsync({
                  users: JSON.parse(data.users),
                });
              }}
              >
              {({ register, control, errors }) => {
                return( <>
                  <Field label="Users"
                    invalid={!!errors.users}
                    >
                    <Input
                      name="users"
                      type="text"
                      rows={30}
                      {...register("users", {
                      })}
                    />
                  </Field>
                  <br/>
                  <Button variant="primary" label="Create Users" loading={loadingPostUsers} />
                </>)}
              }
            </Form>
          </div>
          
          {updatedUsers && 
            <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
              <div className="bg-white h-96 overflow-y-auto">
                <ReactJson src={updatedUsers} />
              </div>
            </div>
          }

        </div>
      </LayoutGrid1>
    );
};
