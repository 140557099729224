import {httpsCallable} from "firebase/functions";
import app from "../firebase";

const REGION =
  window.location.hostname == "localhost" ? "us-central1" : "europe-west3";

const functions = app.functions(REGION);

export function listScheduledItems() {
  return httpsCallable(functions, "admin-schedule-listScheduledItems")().then(
    (result) => {
      return result.data;
    }
  );
}

export function getScheduledItem(id: string) {
  return httpsCallable(
    functions,
    "admin-schedule-getScheduledItem"
  )({
    id: id,
  }).then((result) => {
    return result.data;
  });
}

export function createScheduledItem({
  campaignId,
  campaignName,
  frequency,
  dayOfWeek,
  timeOfDay,
  itemType,
  emailTemplate,
  emailTemplateName,
  emailTemplateNoInteraction,
  emailTemplateNoInteractionName,
  emails,
  subject,
}: {
  campaignId: string;
  campaignName: string;
  frequency: string;
  dayOfWeek: string;
  timeOfDay: string;
  itemType: string;
  emailTemplate: string;
  emailTemplateName: string;
  emailTemplateNoInteraction: string;
  emailTemplateNoInteractionName: string;
  emails: string[];
  subject: string;
}) {
  // admin-schedule-createScheduledItem
  return httpsCallable(
    functions,
    "admin-schedule-createScheduledItem"
  )({
    campaignId: campaignId,
    campaignName: campaignName,
    frequency: frequency,
    dayOfWeek: dayOfWeek,
    timeOfDay: timeOfDay,
    itemType: itemType,
    emailTemplate: emailTemplate,
    emailTemplateName: emailTemplateName,
    emailTemplateNoInteraction: emailTemplateNoInteraction,
    emailTemplateNoInteractionName: emailTemplateNoInteractionName,
    emails: emails,
    subject: subject,
  }).then((result) => {
    return result.data;
  });
}

export function updateScheduledItem({
  id,
  emails,
}: {
  id: string;
  emails: string[];
}) {
  // admin-schedule-updateScheduledItem
  return httpsCallable(
    functions,
    "admin-schedule-updateScheduledItem"
  )({
    id: id,
    emails: emails,
  }).then((result) => {
    return result.data;
  });
}

export function deleteScheduledItem({id}: {id: string}) {
  // admin-schedule-deleteScheduledItem
  return httpsCallable(
    functions,
    "admin-schedule-deleteScheduledItem"
  )({
    id: id,
  }).then((result) => {
    return result.data;
  });
}

export function sendScheduledItem({id}: {id: string}) {
  // admin-schedule-sendScheduledItem
  return httpsCallable(
    functions,
    "admin-schedule-sendScheduledItem"
  )({
    id: id,
  }).then((result) => {
    return result.data;
  });
}

export function setScheduledItemToScheduled({id}: {id: string}) {
  // admin-schedule-setScheduledItemToScheduled
  return httpsCallable(
    functions,
    "admin-schedule-setScheduledItemToScheduled"
  )({
    id: id,
  }).then((result) => {
    return result.data;
  });
}

export function listScheduledItemsLogs() {
  return httpsCallable(
    functions,
    "admin-schedule-listScheduledItemsLogs"
  )().then((result) => {
    // console.log("admin-schedule-listScheduledItemsLogs2 > ", result);
    const logs = [];

    result.data.forEach((log) => {
      // console.log("log", log);
      const tmp = Object.assign(
        {},
        {
          id: log.id ? log.id : "",
          taskId: log.taskId ? log.taskId : "",
          createdAt: log.createdAt ? log.createdAt : "",
          subject:
            log.options && log.options.subject ? log.options.subject : "",
          campaignName:
            log.options && log.options.campaignName
              ? log.options.campaignName
              : "",
          campaignId:
            log.options && log.options.campaignId ? log.options.campaignId : "",
          emailTemplateName:
            log.options && log.options.emailTemplateName
              ? log.options.emailTemplateName
              : "",
          emailTemplateNoInteractionName:
            log.options && log.options.emailTemplateNoInteractionName
              ? log.options.emailTemplateNoInteractionName
              : "",
          emails: log.options && log.options.emails ? log.options.emails : [],
          status: log.options && log.options.status ? log.options.status : "",
        }
      );

      logs.push(tmp);
    });

    // console.log("logs >>", logs);
    return logs;
  });
}


export function getScheduledItemLog(id: string) {
  return httpsCallable(
    functions,
    "admin-schedule-getScheduledItemLog"
  )({
    id: id,
  }).then((result) => {
    return result.data;
  });
}