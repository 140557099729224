import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "../UserProvider";

const Logout = (props: any) => {
  const { logout }: any = useContext(UserContext);

  logout();  
  return <Redirect exact from="/logout" to="/" />
}

export default Logout;