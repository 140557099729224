export const en = {
  toLocalString: "en-US",
  loading: "Loading",
  header: {
    links: {
      campaigns: {
        label: "Campaigns",
      },
      login: {
        label: "Log In",
      },
      logout: {
        label: "Log Out",
      },
      account: {
        label: "Account",
      },
    },
  },
  login: {
    title: {
      label: "Sign in to Doorboost",
    },
    form: {
      fields: {
        email: {
          label: "Email address",
        },
      },
      success: {
        label:
          "Please check your email, {{email}}, and follow the instructions.",
      },
      submit: {
        label: "Sign in",
      },
      cancel: {
        label: "Try signing in again.",
      },
    },
    links: {
      termsEnglish: {
        label: "Terms of Service in English",
      },
      termsGerman: {
        label: "Terms of Service in German",
      },
    },
  },
  campaigns: {
    title: {
      label: "Campaigns",
    },
    loading: {
      label: "Loading",
    },
    error: {
      label:
        "Campaigns could not be loaded. Please contact vache@doorboost.com",
    },
    list: {
      details: {
        label: "Started on",
      },
      subDetails: {
        label: "Campaign Running",
      },
      org: {
        label: "Org: ",
      },
      brand: {
        label: "Brand: ",
      },
      goals: {
        storeVisits: {
          label: "Store Visits",
        },
        leads: {
          label: "Leads",
        },
        brandAwareness: { label: "Brand Awareness" },
        postEngagement: { label: "Post Engagement" },
        webTraffic: { label: "Web Traffic" },
        sales: { label: "Sales" },
        eventSignup: { label: "Event Signup" },
      },
    },
  },
  campaign: {
    loading: {
      label: "Loading Campaign: ",
    },
    sections: {
      campaignDetails: {
        id: {
          label: "Campaign Id",
        },
        facebookCampaignId: {
          label: "facebookCampaignId",
        },
        googleCampaignId: {
          label: "googleCampaignId",
        },
        googleCampaignSmartId: {
          label: "googleCampaignSmartId",
        },
        liCampaignId: {
          label: "liCampaignId",
        },
        currency: {
          label: "currency",
        },
        factor: {
          label: "factor",
        },
        visible: {
          label: "visible",
        },
        insights: {
          label: "insights",
        },
        createdAt: {
          label: "createdAt",
        },
        createdBy: {
          label: "createdBy",
        },
        updatedAt: {
          label: "updatedAt",
        },
        updatedBy: {
          label: "updatedBy",
        },
        deletedAt: {
          label: "deletedAt",
        },
        deletedBy: {
          label: "deletedBy",
        },
        brand_id: {
          label: "brand_id",
        },
        brandName: {
          label: "brandName",
        },
        hideMoney: {
          label: "hideMoney",
        },
        status: {
          label: "Campaign Status",
        },
        budget: {
          label: "Budget",
        },
        approvedDate: {
          label: "Approved Date",
        },
        goal: {
          label: "Campaign Goal",
        },
        dateStart: {
          label: "Start Date",
        },
        dateEnd: {
          label: "End Date",
        },
        details: {
          label: "Details",
        },
      },
      campaignSummary: {
        title: {
          label: "Campaign Summary",
        },
        cards: {
          impressions: {
            label: "Impressions",
            text: "The number of people your campaign reached who are actively searching for the products you offer near your place of business.",
          },
          conversions: {
            label: "Conversions",
            text: "Key actions people take after clicking your ads. This includes calls, registrations, use of the map function, downloads and purchases made.",
          },
          cost: {
            label: "Cost",
            text: "Key actions people take after clicking your ads. This includes calls, registrations, use of the map function, downloads and purchases made.",
          },
          clicks: {
            label: "Clicks",
            text: "Clicks show you how many of the people you reached clicked on your ad and visited your landing page to engage with your business.",
          },
          cpm: {
            label: "CPM",
          },
          cpc: {
            label: "CPC",
          },
          ctr: {
            label: "CTR",
          },
          cpa: {
            label: "cpa",
          },
        },
      },
      insights: {
        title: {
          label: "Insights",
        },
        graphs: {
          impressions: {
            title: {
              label: "Impressions",
            },
          },
          reach: {
            title: {
              label: "Reach",
            },
          },
          clicks: {
            title: {
              label: "Clicks",
            },
          },
          conversions: {
            title: {
              label: "Conversions",
            },
          },
        },
      },
      adSets: {
        title: {
          label: "Ad Sets",
        },
        table: {
          columns: {
            name: {
              label: "Name",
            },
            impressions: {
              label: "Impressions",
            },
            reach: {
              label: "Reach",
            },
            clicks: {
              label: "Clicks",
            },
            conversions: {
              label: "Conversions",
            },
            cost: {
              label: "cost",
            },
            cpm: {
              label: "cpm",
            },
            cpc: {
              label: "cpc",
            },
            cpa: {
              label: "cpa",
            },
            ctr: {
              label: "ctr",
            },
            cr: {
              label: "cr",
            },
          },
        },
      },
      ads: {
        title: {
          label: "Ads",
        },
        table: {
          columns: {
            name: {
              label: "Name",
            },
            impressions: {
              label: "Impressions",
            },
            reach: {
              label: "Reach",
            },
            clicks: {
              label: "Clicks",
            },
            conversions: {
              label: "Conversions",
            },
            cost: {
              label: "cost",
            },
            cpm: {
              label: "cpm",
            },
            cpc: {
              label: "cpc",
            },
            cpa: {
              label: "cpa",
            },
            ctr: {
              label: "ctr",
            },
            cr: {
              label: "cr",
            },
          },
        },
      },
      leads: {
        title: {
          label: "Leads",
        },
        table: {
          columns: {
            createdTime: {
              label: "Created Time",
            },
            firstName: {
              label: "First Name",
            },
            lastName: {
              label: "Last Name",
            },
            email: {
              label: "Email",
            },
            phoneNumber: {
              label: "Phone Number",
            },
            platform: {
              label: "Platform",
            },
            misc: {
              label: "Miscellaneous",
            },
          },
        },
        error: {
          label: "No Leads were found.",
        },
      },
    },
  },
  account: {
    title: {
      label: "Account",
    },
    loading: {
      label: "Loading Account",
    },
    error: {
      label: "Account Page Could not be loaded",
    },
    form: {
      fields: {
        firstName: {
          label: "First Name",
        },
        lastName: {
          label: "Last Name",
        },
        email: {
          label: "Email Address",
        },
        phone: {
          label: "Phone Number",
        },
        locale: {
          label: "Language",
        },
      },
      createdAt: {
        label: "This account was created on",
      },
      submit: {
        label: "Save",
      },
      cancel: {
        label: "Cancel",
      },
    },
  },
};

export const de = {
  toLocalString: "de-DE",
  loading: "Wird geladen",
  header: {
    links: {
      campaigns: {
        label: "Kampagnen",
      },
      login: {
        label: "Einloggen",
      },
      logout: {
        label: "Abmelden",
      },
      account: {
        label: "Konto",
      },
    },
  },
  login: {
    title: {
      label: "Bei Doorboost anmelden",
    },
    form: {
      fields: {
        email: {
          label: "E-Mail-Adresse",
        },
      },
      success: {
        label:
          "Bitte überprüfen Sie Ihre E-Mail, {{email}}, und befolgen Sie die Anweisungen.",
      },
      submit: {
        label: "Anmelden",
      },
      cancel: {
        label: "Versuchen Sie sich erneut anzumelden.",
      },
    },
    links: {
      termsEnglish: {
        label: "Terms of Service in English",
      },
      termsGerman: {
        label: "Nutzungsbedingungen auf Deutsch",
      },
    },
  },
  campaigns: {
    title: {
      label: "Kampagnen",
    },
    loading: {
      label: "Wird geladen",
    },
    error: {
      label:
        "Kampagnen konnten nicht geladen werden. Bitte wenden Sie sich an vache@doorboost.com",
    },
    list: {
      details: {
        label: "Beginnen am",
      },
      subDetails: {
        label: "Kampagne läuft",
      },
      org: {
        label: "Org: ",
      },
      brand: {
        label: "Marke: ",
      },
      goals: {
        storeVisits: {
          label: "Ladenbesuche",
        },
        leads: {
          label: "Führt",
        },
        brandAwareness: { label: "Markenbekanntheit" },
        postEngagement: { label: "Post-Engagement" },
        webTraffic: { label: "Webverkehr" },
        sales: { label: "Verkauf" },
        eventSignup: { label: "Event-Anmeldung" },
      },
    },
  },
  campaign: {
    loading: {
      label: "Kampagne wird geladen: ",
    },
    sections: {
      campaignDetails: {
        status: {
          label: "Kampagnenstatus",
        },
        budget: {
          label: "Budget",
        },
        approvedDate: {
          label: "Genehmigtes Datum",
        },
        goal: {
          label: "Kampagnenziel",
        },
        dateStart: {
          label: "Startdatum",
        },
        dateEnd: {
          label: "Endtermin",
        },
        details: {
          label: "Einzelheiten",
        },
      },
      campaignSummary: {
        title: {
          label: "Zusammenfassung der Kampagne",
        },
        cards: {
          impressions: {
            label: "Impressionen",
            text: "Die Anzahl der Personen, die Sie mit Ihrer Kampagne erreicht haben und die aktiv nach den von Ihnen angebotenen Produkten in der Nähe Ihres Geschäftssitzes suchen.",
          },
          conversions: {
            label: "Konvertierungen",
            text: "Wichtige Aktionen, die Personen nach dem Klicken auf Ihre Anzeigen durchführen. Dazu gehören Anrufe, Anmeldungen die Nutzung der Kartenfunktion, Downloads und getätigte Käufe.",
          },
          cost: {
            label: "Kosten",
            text: "Der Geldbetrag, der für die Schaltung Ihrer Anzeigen und die Anzeige bei der von uns definierten Zielgruppe auf den jeweiligen Werbeplattformen investiert wird.",
          },
          clicks: {
            label: "Klicks",
            text: "Klicks zeigen Ihnen, wie viele der von Ihnen erreichten Personen auf Ihre Anzeige geklickt und Ihre Landing Page besucht haben, um sich mit Ihrem Unternehmen zu beschäftigen.",
          },
          cpm: {
            label: "CPM",
          },
          cpc: {
            label: "CPC",
          },
          ctr: {
            label: "CTR",
          },
          cpa: {
            label: "cpa",
          },
        },
      },
      insights: {
        title: {
          label: "Einblicke",
        },
        graphs: {
          impressions: {
            title: {
              label: "Impressionen",
            },
          },
          reach: {
            title: {
              label: "Erreichen",
            },
          },
          clicks: {
            title: {
              label: "Klicks",
            },
          },
          conversions: {
            title: {
              label: "Konvertierungen",
            },
          },
        },
      },
      adSets: {
        title: {
          label: "Anzeigensets",
        },
        table: {
          columns: {
            name: {
              label: "Name",
            },
            impressions: {
              label: "Impressionen",
            },
            reach: {
              label: "Erreichen",
            },
            clicks: {
              label: "Klicks",
            },
            conversions: {
              label: "Konvertierungen",
            },
            cost: {
              label: "cost",
            },
            cpm: {
              label: "cpm",
            },
            cpc: {
              label: "cpc",
            },
            cpa: {
              label: "cpa",
            },
            ctr: {
              label: "ctr",
            },
            cr: {
              label: "cr",
            },
          },
        },
      },
      ads: {
        title: {
          label: "Anzeigen",
        },
        table: {
          columns: {
            name: {
              label: "Name",
            },
            impressions: {
              label: "Impressionen",
            },
            reach: {
              label: "Erreichen",
            },
            clicks: {
              label: "Klicks",
            },
            conversions: {
              label: "Konvertierungen",
            },
            cost: {
              label: "cost",
            },
            cpm: {
              label: "cpm",
            },
            cpc: {
              label: "cpc",
            },
            cpa: {
              label: "cpa",
            },
            ctr: {
              label: "ctr",
            },
            cr: {
              label: "cr",
            },
          },
        },
      },
      leads: {
        title: {
          label: "Leads",
        },
        table: {
          columns: {
            createdTime: {
              label: "Geschaffene Zeit",
            },
            firstName: {
              label: "Vorname",
            },
            lastName: {
              label: "Nachname",
            },
            email: {
              label: "E-Mail-Adresse",
            },
            phoneNumber: {
              label: "Telefonnummer",
            },
            platform: {
              label: "Plattform",
            },
            misc: {
              label: "Sonstig",
            },
          },
        },
        error: {
          label: "Es wurden keine Leads gefunden.",
        },
      },
    },
  },
  account: {
    title: {
      label: "Konto",
    },
    loading: {
      label: "Konto wird geladen",
    },
    error: {
      label: "Kontoseite konnte nicht geladen werden",
    },
    form: {
      fields: {
        firstName: {
          label: "Vorname",
        },
        lastName: {
          label: "Nachname",
        },
        email: {
          label: "E-Mail-Adresse",
        },
        phone: {
          label: "Telefonnummer",
        },
        locale: {
          label: "Sprache",
        },
      },
      createdAt: {
        label: "Dieses Konto wurde am erstellt",
      },
      submit: {
        label: "Speichern",
      },
      cancel: {
        label: "Abbrechen",
      },
    },
  },
};
