// Called for initialization
import firebase from "./firebase";
firebase;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "../boilerplate/public/boilerplate.scss";
import "react-day-picker/dist/style.css";
import { UserProvider } from "./UserProvider";

import Landing from "./pages/Landing";
import Orgs from "./pages/Orgs";
import Org from "./pages/Org";
import OrgCreate from "./pages/OrgCreate";

import OrgsCreate from "./pages/OrgsCreate";

import Campaigns from "./pages/Campaigns";
import Campaign from "./pages/Campaign";
import CampaignReport from "./pages/CampaignReport";
import CampaignCreate from "./pages/CampaignCreate";
import CampaignsCreate from "./pages/CampaignsCreate";
import BrandCampaigns from "./pages/BrandCampaigns";
import BrandRetailers from "./pages/BrandRetailers";

import Brands from "./pages/Brands";
import BrandCreate from "./pages/BrandCreate";
import Brand from "./pages/Brand";

import Users from "./pages/Users";
import UserCreate from "./pages/UserCreate";
import User from "./pages/User";
import UsersCreate from "./pages/UsersCreate";

import ScheduledItems from "./pages/ScheduledItems";
import ScheduledItem from "./pages/ScheduledItem";
import ScheduledItemsCreate from "./pages/ScheduledItemsCreate";

import ScheduledItemsLogs from "./pages/ScheduledItemsLogs";

import Emails from "./pages/Emails";

import Login from "./pages/Login";
import LoginCallback from "./pages/LoginCallback";
import Logout from "./pages/Logout";
import ProtectedRoute from "./routeComponents/ProtectedRoute";

import TosEng from "./pages/TosEng";
import TosDe from "./pages/TosDe";

//import flowbite across the project
import "flowbite";

function App() {
  return (
    <UserProvider>
      <Router>
        <Switch>
          <ProtectedRoute exact path="/" component={Landing} />
          <ProtectedRoute exact path="/orgs" component={Orgs} />
          <ProtectedRoute exact path="/orgs/:id" component={Org} />
          <ProtectedRoute exact path="/create/org" component={OrgCreate} />
          <ProtectedRoute exact path="/create/orgs" component={OrgsCreate} />

          <ProtectedRoute exact path="/campaigns" component={Campaigns} />
          <ProtectedRoute exact path="/campaigns/:id" component={Campaign} />
          <ProtectedRoute
            exact
            path="/campaigns/:id/report"
            component={CampaignReport}
          />

          <ProtectedRoute
            exact
            path="/create/campaign"
            component={CampaignCreate}
          />
          <ProtectedRoute
            exact
            path="/create/campaigns"
            component={CampaignsCreate}
          />

          <ProtectedRoute exact path="/brands" component={Brands} />
          <ProtectedRoute exact path="/brands/:id" component={Brand} />

          <ProtectedRoute
            exact
            path="/brands/:id/campaigns"
            component={BrandCampaigns}
          />

          <ProtectedRoute
            exact
            path="/brands/:id/retailers"
            component={BrandRetailers}
          />

          <ProtectedRoute exact path="/create/brand" component={BrandCreate} />

          <ProtectedRoute exact path="/users" component={Users} />
          <ProtectedRoute exact path="/users/:id" component={User} />
          <ProtectedRoute exact path="/create/user" component={UserCreate} />
          <ProtectedRoute exact path="/create/users" component={UsersCreate} />

          <ProtectedRoute exact path="/scheduled" component={ScheduledItems} />
          <ProtectedRoute
            exact
            path="/scheduled/:id"
            component={ScheduledItem}
          />
          <ProtectedRoute
            exact
            path="/create/schedule"
            component={ScheduledItemsCreate}
          />

          <ProtectedRoute
            exact
            path="/scheduled-logs"
            component={ScheduledItemsLogs}
          />

          <ProtectedRoute exact path="/emails" component={Emails} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/callback" component={LoginCallback} />
          <Route exact path="/logout" component={Logout} />

          <Route exact path="/tos-eng" component={TosEng} />
          <Route exact path="/tos-de" component={TosDe} />
        </Switch>
      </Router>
    </UserProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
