import {
  getAuth,
  connectAuthEmulator,
} from "firebase/auth";

const auth = getAuth();

if (window.location.href.includes("localhost")) {
  console.log("use auth local");
  connectAuthEmulator(auth, "http://localhost:9099");
}

import {httpsCallable} from "firebase/functions";
import app from "../firebase";

const REGION =
  window.location.hostname == "localhost" ? "us-central1" : "europe-west3";

const functions = app.functions(REGION);

import firebase from "firebase/compat/app";

var provider = new firebase.auth.GoogleAuthProvider();
provider.addScope("email");

export function sendMagicLink({email, url}: {email: string; url: string}) {
  const auth = firebase.auth();

  const actionCodeSettings = {
    url,
    handleCodeInApp: true,
  };

  return httpsCallable(functions,"admin-users-isAdmin")({email: email})
    .then((isAdminResponse) => {
      if (isAdminResponse.data.isAdmin) {
        return auth.sendSignInLinkToEmail(email, actionCodeSettings);
      } else {
        throw new Error("Please try another email address.");
      }
    })
    .then(() => {
      window.localStorage.setItem("emailForSignIn", email);
      return "Login Link Sent, please check your email.";
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode);
      console.error(errorMessage);
    });
}

export function magicLink({email, url}: {email: string; url: string}) {
  const auth = firebase.auth();
  return httpsCallable(functions,"admin-users-isAdmin")({email: email})
    .then((isAdminResponse) => {
      if (auth.isSignInWithEmailLink(url) && isAdminResponse.data.isAdmin) {
        return auth
          .signInWithEmailLink(email, url)
          .then((userCredential) => {
            window.localStorage.removeItem("emailForSignIn");
            const user = JSON.parse(JSON.stringify(userCredential)).user;

            const userResponse = Object.assign(
              {},
              {
                companyName: "Doorboost",
                email: user.email,
                firstName: user.displayName,
                id: user.uid,
                isAdmin: true,
                lastName: user.displayName,
                locale: "en",
              }
            );
            return userResponse;
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode);
            console.error(errorMessage);
          });
      } else {
        throw new Error("Please try logging in again.");
      }
    })
    .catch((error) => {
      localStorage.removeItem("USER");
      auth.signOut();

      console.error(error);
      return error;
    });
}

export function googleAuth({email, url}: {email: string; url: string}) {
  const auth = firebase.auth();

  return httpsCallable(functions,"admin-users-isAdmin")({email: email})
    .then((isAdminResponse) => {
      return firebase
        .auth()
        .signInWithRedirect(provider)
        .then((userCredential) => {
          // var credential = result.credential;
          // var token = credential.accessToken;
          // var user = result.user;

          window.localStorage.removeItem("emailForSignIn");
          const user = JSON.parse(JSON.stringify(userCredential)).user;
          const additionalUserInfo = JSON.parse(
            JSON.stringify(userCredential)
          ).additionalUserInfo;
          const _email = additionalUserInfo?.profile.email;

          // console.log("_email", _email, additionalUserInfo);

          const userResponse = Object.assign(
            {},
            {
              companyName: "Doorboost",
              email: user.email || _email,
              firstName: user.displayName,
              id: user.uid,
              isAdmin: true,
              lastName: user.displayName,
              locale: "en",
            }
          );
          return userResponse;
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode);
          console.error(errorMessage);
        });
    })
    .catch((error) => {
      localStorage.removeItem("USER");
      auth.signOut();

      console.error(error);
      return error;
    });
}

export function googleAuthRedirect({something}: {something: string}) {
  // console.log("googleAuthRedirect");

  const auth = firebase.auth();

  return firebase
    .auth()
    .getRedirectResult()
    .then((userCredential) => {
      if (userCredential?.user) {
        // var credential = result.credential;
        // var token = credential.accessToken;
        // var user = result.user;

        window.localStorage.removeItem("emailForSignIn");
        const user = JSON.parse(JSON.stringify(userCredential)).user;
        const additionalUserInfo = JSON.parse(
          JSON.stringify(userCredential)
        ).additionalUserInfo;
        const _email = additionalUserInfo?.profile.email;
        
        const userResponse = Object.assign(
          {},
          {
            companyName: "Doorboost",
            email: user?.email || _email,
            firstName: user.displayName,
            id: user.uid,
            isAdmin: true,
            lastName: user.displayName,
            locale: "en",
          }
        );
        return userResponse;
      }
      return false;
    })
    .catch((error) => {
      localStorage.removeItem("USER");
      auth.signOut();

      console.error(error);
      return error;
    });
}
