import React, { PureComponent } from 'react';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const sortBy = (key) => {
  return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
};

export default class AreaChartWrapper extends PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { data, uniqueId, width, height, stroke, fill, xAxis, yAxis, syncId } = this.props;

    const formattedData = [];
    const uniqueData = [];

    if(data) {
      Object.keys(data).forEach((parent)=> {
        if (!data[parent]) return;
        data[parent].map(child => {
          if(!child) return;
          let obj = {}
          obj[xAxis] = child[xAxis];
          obj['value'] = child[yAxis];
          obj['id'] = child[uniqueId];
          if(child[uniqueId] === 0) return;
          formattedData.push(obj);
        })
      })

      formattedData.forEach(item => {
        const index = uniqueData.findIndex(match => match['id'] === item['id'])
        if(index >= 0 ){
          uniqueData[index][yAxis] = uniqueData[index]['value'] + item['value']
        } else {
          uniqueData.push(item)
        }
      })
    }

    return (
      <ResponsiveContainer width="100%" aspect={width/3 > 200 ? 5 : 3}>
        <AreaChart
          width={width}
          height={height}
          data={uniqueData.concat().sort(sortBy("id"))}
          syncId={syncId}
          margin={{
            top: 20,
            right: 15,
            left: -10,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxis}/>
          <YAxis />
          <Tooltip />
          <Area connectNulls type="monotone" dataKey={'value'} stroke={stroke} fill={fill} />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}