import React from 'react'
import firebase from "firebase/compat/app";
import {en} from "./locales";

export const UserContext = React.createContext<{
  getUser: () => string;
  login: (string) => void;
  logout: () => void;
  isLoggedIn: () => boolean;
  locale: () => any;
} | undefined
>(null);

export const UserProvider = (props: any) => {
  let _user: any = JSON.parse(localStorage.getItem('USER') || 'null') || null;

  const getUser = () => {
    if (!_user) {
      return null;
    }
    
    return _user;
  };

  const login = (user: object) => {
      return new Promise((resolve, reject) => {
        if (user) {
          localStorage.setItem('USER', JSON.stringify(user));
          _user = user;
          return resolve('User has been set in localStorage and in State.');
        } else {
          return reject('User not provided.');
        }
      });
  };

  const logout = () => {
    localStorage.removeItem('USER');
    window.localStorage.removeItem('emailForSignIn');
    _user = '';
    return firebase.auth().signOut();
  }

  const isLoggedIn = (): boolean => {
    return !!_user;
  };

  const locale = (): any => {
    return en;
  };

  return (
    <UserContext.Provider value={{
      getUser,
      isLoggedIn,
      login,
      logout,
      locale
    }}>
      {props.children}
    </UserContext.Provider>
  );
}