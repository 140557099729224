import React, { useState, useRef, useEffect, useContext } from "react";

const LayoutGrid1 = (props: any) => {
  const { children } = props;

  return (
    <div className="mx-auto max-w-screen-2xl">      
      <section className="overflow-hidden mx-10">
        {children}
      </section>      
    </div>
  )
}

export default LayoutGrid1;