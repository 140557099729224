import React, {useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";
import { Button, SectionTitle, DataTable, Alert} from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import { getUsers, searchUsers } from "../services/users";

export default function Users(props: any) {
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [errorSearch, setErrorSearch] = useState([]);

  const [getUsersAsync, loadingUsers, errorGetUsers] = useAsync<typeof getUsers>(
    (...params) => getUsers(...params), res => {
      return setUsers(res);
    });

  const [searchUsersAsync, loadingSearchUsers, errorSearchUsers] = useAsync<typeof searchUsers>(
    (...params) => searchUsers(...params), res => {
      return setUsers(res);
    });

  useEffect(() => {
    getUsersAsync();
  }, [])

  const onSearch = (query) => {
    setErrorSearch([]);
    if(query.term.length >= 3){
      searchUsersAsync(query)
    } else if(query.term.length === 0) {
      getUsersAsync()
    }
    else {
      setErrorSearch(["Please type more than 3 characters"]);
    }
  }

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Users" />
        <div className="bg-green-100 p-5 border border-green-200 rounded">
          <ActionSectionHeader name={`Create a New User`} />
          <Button variant="white" label="Create" onClick={() => {
            history.push(`/create/user`);
          }} />
        </div>
        <br/>
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {errorSearch && errorSearch.length > 0 && <Alert type="error" message={errorSearch} />}
          {errorGetUsers && <Alert type="error" message={errorGetUsers} />}
          <DataTable
            isLoading={loadingUsers || loadingSearchUsers}
            columns={[
              {id: 'email', display: 'Email', type: 'string', searchable: true, sortable: true},
              {id: 'firstName', display: 'First Name', type: 'string', sortable: true},
              {id: 'lastName', display: 'Last Name', type: 'string', sortable: true},
              {id: 'orgs', display: 'Orgs', type: 'custom'},
              {id: 'edit', display: 'Edit', type: 'custom'},
            ]}
            rows={users && users.map((item: any) => {
              return {
                id: item.id,
                email: item.email,
                firstName: item.firstName,
                lastName: item.lastName,
                orgs: item.orgs && item.orgs.length ? item.orgs.map((org) => {
                  return (<div className="my-2" key={org.id}><Button variant="white" label={org.name}
                  onClick={() => {
                    history.push(`/orgs/${org.id}`);
                  }} /></div>)
                } ) : '',
                edit: <Button variant="secondary" label="Edit"
                onClick={() => {
                  history.push(`/users/${item.id}`);
                }} />
              }
            })
            }
            onRowClick={(item, index) => {
              history.push(`/users/${item.id}`);
            }}
            onSearch={onSearch}
          />
        </div>
      </div>
    </LayoutGrid1>
  )
};