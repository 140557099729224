import React from "react";

const Header = (props: any) => {
  const { children } = props;
  return (
    <header className="h-16 bg-dark shadow-sm sticky top-0 lg:overflow-y-visible" style={{ zIndex: 1000 }}>
      <div className="w-full py-5 flex items-center justify-between">
        <div className="mx-auto flex items-center">
          <div>
            {children[0]}
          </div>
          <div>
            {children[1]}
          </div>
          <div>
            {children[2]}
          </div>        
        </div>
      </div>
    </header>
  )
};

export default Header;