import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";

import {Button, SectionTitle, DataTable, Alert} from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import {listScheduledItemsLogs} from "../services/schedule";

export default function ScheduledItems(props: any) {
  const history = useHistory();

  const [scheduledItemsLogs, setScheduledItemsLogs] = useState([]);
  const [
    listScheduledItemsLogsAsync,
    loadingListScheduledItemsLogs,
    errorListScheduledItemsLogs,
  ] = useAsync<typeof listScheduledItemsLogs>(
    (...params) => listScheduledItemsLogs(...params),
    (res) => {
      return setScheduledItemsLogs(res);
    }
  );

  useEffect(() => {
    listScheduledItemsLogsAsync();
  }, []);

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Scheduled Items" />
        <div className="bg-green-100 p-5 border border-green-200 rounded">
          <ActionSectionHeader name={`Create a New Scheduled Item`} />
          <Button
            variant="white"
            label="Create"
            onClick={() => {
              history.push(`/create/schedule`);
            }}
          />
        </div>
        <br />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {errorListScheduledItemsLogs && (
            <Alert type="error" message={errorListScheduledItemsLogs} />
          )}
          <DataTable
            isLoading={loadingListScheduledItemsLogs}
            columns={[
              {
                id: "createdAt",
                display: "Created At",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "subject",
                display: "Subject",
                type: "string",
                searchable: true,
                sortable: true,
              },
              {
                id: "campaignName",
                display: "Campaign Name",
                type: "string",
                searchable: true,
                sortable: false,
              },
              {
                id: "campaignId",
                display: "campaignId",
                type: "string",
                searchable: true,
                sortable: false,
              },
              {
                id: "emailTemplateName",
                display: "emailTemplateName",
                type: "string",
                searchable: false,
                sortable: false,
              },
              {
                id: "emailTemplateNoInteractionName",
                display: "emailTemplateNoInteractionName",
                type: "string",
                searchable: false,
                sortable: false,
              },
              {
                id: "emails",
                display: "Emails",
                type: "string",
                searchable: false,
                sortable: false,
              },
              {
                id: "status",
                display: "Status",
                type: "string",
                searchable: false,
                sortable: false,
              },
              {
                id: "view",
                display: "View Log",
                type: "string",
                searchable: false,
                sortable: false,
              },
              {
                id: "task",
                display: "View Task",
                type: "string",
                searchable: false,
                sortable: false,
              },
            ]}
            rows={
              scheduledItemsLogs &&
              scheduledItemsLogs.map((item: any) => {
                return {
                  id: item.id,
                  createdAt: item.createdAt,
                  subject: item.subject,
                  campaignName: item.campaignName,
                  campaignId: item.campaignId,
                  emailTemplateName: item.emailTemplateName,
                  emailTemplateNoInteractionName: item.emailTemplateNoInteractionName,
                  emails:
                    item.emails && item.emails.length
                      ? item.emails.map((email) => {
                          return (
                            <div className="my-2" key={email}>
                              <Button
                                variant="white"
                                label={email}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                          );
                        })
                      : "",
                  status: item.status,
                  view: (
                    <Button
                      variant="secondary"
                      label="View Log"
                      onClick={() => {
                        history.push(`/scheduled-logs/${item.id}`);
                      }}
                    />
                  ),
                  task: (
                    <Button
                      variant="secondary"
                      label="View Task"
                      onClick={() => {
                        history.push(`/scheduled/${item.taskId}`);
                      }}
                    />
                  ),
                };
              })
            }
            onRowClick={(item, index) => {
              history.push(`/scheduled-logs/${item.id}`);
            }}
          />
        </div>
      </div>
    </LayoutGrid1>
  );
}
