import {httpsCallable} from "firebase/functions";
import app from "../firebase";

const REGION =
  window.location.hostname == "localhost" ? "us-central1" : "europe-west3";

const functions = app.functions(REGION);

export function getMessages() {
  return httpsCallable(functions,'admin-emails-getMessages')()
    .then((result) => {
      return result.data?.messages;
    });
}

export function getTemplates() {
  return httpsCallable(functions,'admin-emails-getTemplates')()
    .then((result) => {
      return result.data?.result;
    });
}

export function getCampaignReportSummary({id} : {id: string}) {
  return httpsCallable(functions,'admin-emails-getCampaignReportSummary')({campId: id})
    .then((result) => {
      return result?.data;
    });
}

