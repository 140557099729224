import React from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import { SectionTitle, Button } from "@mutable/meiosis";

export default function Landing(props: any) {
  const history = useHistory();

  return (
    <LayoutGrid1>
      <SectionTitle title="Welcome to Doorboost Admin Dashboard" />
      <div className="my-5 grid gap-y-2">
        <Button
          variant="secondary"
          label="List All Campaigns"
          onClick={() => {
            history.push("/campaigns");
          }}
        />
        <Button
          variant="secondary"
          label="Create a New Campaign"
          onClick={() => {
            history.push("/create/campaign");
          }}
        />
        <Button
          variant="secondary"
          label="Create Multiple Campaigns"
          onClick={() => {
            history.push("/create/campaigns");
          }}
        />
        <hr />
        <Button
          variant="secondary"
          label="List All Orgs"
          onClick={() => {
            history.push("/orgs");
          }}
        />
        <Button
          variant="secondary"
          label="Create a New Org"
          onClick={() => {
            history.push("/create/org");
          }}
        />
        <Button
          variant="secondary"
          label="Create Multiple Orgs"
          onClick={() => {
            history.push("/create/orgs");
          }}
        />
        <hr />
        <Button
          variant="secondary"
          label="List all Brands"
          onClick={() => {
            history.push("/brands");
          }}
        />
        <Button
          variant="secondary"
          label="Create a New Brand"
          onClick={() => {
            history.push("/create/brand");
          }}
        />
        <hr />
        <Button
          variant="secondary"
          label="List all Users"
          onClick={() => {
            history.push("/users");
          }}
        />
        <Button
          variant="secondary"
          label="Create a New User"
          onClick={() => {
            history.push("/create/user");
          }}
        />
        <Button
          variant="secondary"
          label="Create Multiple Users "
          onClick={() => {
            history.push("/create/users");
          }}
        />
      </div>
    </LayoutGrid1>
  );
}
