import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import ReactJson from "react-json-view";

import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import {
  Button,
  SectionTitle,
  Form,
  Field,
  Input,
  ControlledSelect,
} from "@mutable/meiosis";

import useAsync from "../services/utils/useAsync";

import {postCampaigns} from "../services/campaigns";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {dark} from "react-syntax-highlighter/dist/esm/styles/prism";

export default function CampaignsCreate(props: any) {
  const history = useHistory();

  const [createdCampaigns, setCreatedCampaigns] = useState({});

  const [postCampaignsAsync, loadingPostCampaigns, errorPostCampaigns] =
    useAsync<typeof postCampaigns>(
      (...params) => postCampaigns(...params),
      (res) => {
        setCreatedCampaigns(res);
      }
    );

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Create New Campaigns" />
        <h3>Example JSON snippet</h3>
        <SyntaxHighlighter language="json" style={dark}>
          {`[
  {
    name: "002 LEICHT | Adam Küchen  | K2 2022",
    facebookCampaignId: "23850301270340288",
    googleCampaignId: "17450893802",
    goal: "storeVisits",
    currency: "eur",
    factor: "1.2",
    status: "active",
    visible: "TRUE",
    startDate: "2022-06-01T00:00:00.000Z",
    endDate: "2022-07-11T00:00:00.000Z",
  }
]`        }
        </SyntaxHighlighter>
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          <Form
            onSubmit={(data) => {
              postCampaignsAsync({
                campaigns: JSON.parse(data.campaigns),
              });
            }}
          >
            {({register, control, errors}) => {
              return (
                <>
                  <Field label="Campaigns" invalid={!!errors.campaigns}>
                    <Input
                      name="campaigns"
                      type="text"
                      rows={30}
                      {...register("campaigns", {})}
                    />
                  </Field>
                  <br />
                  <Button
                    variant="primary"
                    label="Create Campaigns"
                    loading={loadingPostCampaigns}
                  />
                </>
              );
            }}
          </Form>
        </div>

        {createdCampaigns && (
          <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
            <div className="bg-white h-96 overflow-y-auto">
              <ReactJson src={createdCampaigns} />
            </div>
          </div>
        )}
      </div>
    </LayoutGrid1>
  );
}
