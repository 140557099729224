import React from "react";
import { useHistory } from "react-router-dom";

import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import {
  Button,
  SectionTitle,
  Form,
  Field,
  Input,
  ControlledSelect,
  ControlledReactSelect,
} from "@mutable/meiosis";

import useAsync from "../services/utils/useAsync";

import { postUser } from "../services/users";
import { getBrands } from "../services/brands";

export default function UserCreate(props: any) {
  const history = useHistory();

  const [postUserAsync, loadingPostUser, errorPostUser] = useAsync<
    typeof postUser
  >(
    (...params) => postUser(...params),
    (res) => {
      history.push(`/users`);
    }
  );
  const [brands, setBrands] = React.useState();
  const [getBrandsAsync, loadingBrands, errorGetBrands] = useAsync<
    typeof getBrands
  >(
    (...params) => getBrands(...params),
    (res) => {
      return setBrands(res);
    }
  );

  React.useEffect(() => {
    getBrandsAsync();
  }, []);

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Create a New User" />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {brands && (
            <Form
              onSubmit={(data) => {
                const _userLocale =
                  typeof data.locale === "object"
                    ? data.locale && data.locale.value
                    : data.locale;

                const _brandValue =
                  typeof data.brand === "object"
                    ? data.brand && data.brand.value
                    : data.brand;

                const _userType =
                  typeof data.userType === "object"
                    ? data.userType && data.userType.value
                    : data.userType;

                postUserAsync({
                  firstName: data.firstName,
                  lastName: data.lastName,
                  email: data.email.toLowerCase(),
                  locale: _userLocale || "de",
                  phone: data.phone,
                  userType: _userType || "user",
                  brand_id: _brandValue || null,
                });
              }}
            >
              {({ register, control, errors }) => {
                const _brands = brands.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                });
                return (
                  <>
                    <Field label="First Name" invalid={!!errors.firstName}>
                      <Input
                        name="firstName"
                        type="text"
                        {...register("firstName", {})}
                      />
                    </Field>
                    <Field label="Last Name" invalid={!!errors.lastName}>
                      <Input
                        name="lastName"
                        type="text"
                        {...register("lastName", {})}
                      />
                    </Field>
                    <Field label="Email" invalid={!!errors.email}>
                      <Input
                        name="email"
                        type="text"
                        {...register("email", {})}
                      />
                    </Field>
                    <Field
                      label="Locale"
                      invalid={!!errors.type}
                      error="Please select a locale."
                    >
                      <ControlledSelect
                        name="locale"
                        control={control}
                        options={[
                          { id: "de", value: "de" },
                          { id: "en", value: "en" },
                        ]}
                        defaultSelected={{ id: "de", value: "de" }}
                      />
                    </Field>
                    <Field
                      label="User Type"
                      invalid={!!errors.type}
                      error="Please select a locale."
                    >
                      <ControlledSelect
                        name="userType"
                        control={control}
                        options={[
                          { id: "user", value: "user" },
                          { id: "brand", value: "brand" },
                          { id: "admin", value: "admin" },
                        ]}
                        defaultSelected={{ id: "user", value: "user" }}
                      />
                    </Field>
                    <Field
                      label="Select a Brand"
                      invalid={!!errors.type}
                      error="Please select a locale."
                    >
                      <ControlledReactSelect
                        name="brand"
                        control={control}
                        options={_brands}
                        searchable
                      />
                    </Field>
                    <Field label="Phone" invalid={!!errors.phone}>
                      <Input
                        name="phone"
                        type="text"
                        {...register("phone", {})}
                      />
                    </Field>
                    <br />
                    <Button
                      variant="primary"
                      label="Create User"
                      loading={loadingPostUser}
                    />
                  </>
                );
              }}
            </Form>
          )}
        </div>
      </div>
    </LayoutGrid1>
  );
}
