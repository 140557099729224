import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";
import WithLoading from "../services/utils/WithLoading";

import { Button, SectionTitle, DataTable, Alert } from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import { getBrandRetailers } from "../services/campaigns";
import { getBrand } from "../services/brands";

export default function BrandRetailers(props: any) {
  const history = useHistory();

  const [campaigns, setCampaigns] = useState([]);
  const [brandRetailers, setbrandRetailers] = useState([]);
  const [brandId, setBrandId] = useState<string>(props.match.params.id);
  const [brand, setBrand] = useState();

  const SectionTitleLoading = WithLoading(SectionTitle);
  // const [getCampaignsAsync, loadingCampaigns, errorGetCampaigns] = useAsync<
  //   typeof getBrandCampaigns
  // >(
  //   (...params) => getBrandCampaigns(...params),
  //   (res) => {
  //     return setCampaigns(res);
  //   }
  // );

  const [
    getbrandRetailersAsync,
    loadingbrandRetailers,
    errorGetbrandRetailers,
  ] = useAsync<typeof getBrandRetailers>(
    (...params) => getBrandRetailers(...params),
    (res) => {
      return setbrandRetailers(res);
    }
  );

  const [getBrandAsync, loadingGetBrand, errorGetBrand] = useAsync<
    typeof getBrand
  >(
    (...params) => getBrand(...params),
    (res) => {
      return setBrand(res);
    }
  );

  useEffect(() => {
    setBrandId(props.match.params.id);
    getbrandRetailersAsync({ brand_id: brandId });
    getBrandAsync({ id: brandId });
  }, []);

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        {brand && brand.name && (
          <SectionTitleLoading
            isLoading={loadingGetBrand}
            title={`${brand && brand.name} Retailers Summary`}
          />
        )}
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {errorGetbrandRetailers && (
            <Alert type="error" message={errorGetbrandRetailers} />
          )}
          <DataTable
            isLoading={loadingbrandRetailers}
            columns={[
              {
                id: "org_name",
                display: "Retailer",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "total_campaigns",
                display: "Campaigns",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "active_campaigns",
                display: "Active Campaigns",
                type: "string",
                searchable: false,
                sortable: true,
              },
            ]}
            rows={
              brandRetailers &&
              brandRetailers.map((item: any) => {
                return {
                  org_id: item.org_id,
                  org_name: item.org_name,
                  total_campaigns: item.total_campaigns,
                  active_campaigns: item.active_campaigns,
                };
              })
            }
            onRowClick={(item, index) => {
              history.push(`/orgs/${item.org_id}`);
            }}
          />
        </div>
      </div>
    </LayoutGrid1>
  );
}
