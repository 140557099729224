export default function goalConverter(goal: string, locale: any) {
  let _goal = "";
  if (goal === "storeVisits")
    _goal = locale.campaigns.list.goals.storeVisits.label;
  else if (goal === "leads") _goal = locale.campaigns.list.goals.leads.label;
  else if (goal === "brandAwareness")
    _goal = locale.campaigns.list.goals.brandAwareness.label;
  else if (goal === "webTraffic")
    _goal = locale.campaigns.list.goals.webTraffic.label;
  else if (goal === "sales") _goal = locale.campaigns.list.goals.sales.label;
  else if (goal === "eventSignup")
    _goal = locale.campaigns.list.goals.eventSignup.label;
  else _goal = locale.campaigns.list.goals.storeVisits.label;
  return _goal;
}
