import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";
import AreaChartWrapper from "../components/AreaChartWrapper";
import StackedBarChartWrapper from "../components/StackedBarChartWrapper";
import TableWrapper from "../components/TableWrapper";
import CardList from "../components/CardList";
import Loading from "../components/Loading";

import { CSVLink } from "react-csv";

import { Button, SectionTitle, Table, Alert } from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import WithLoading from "../services/utils/WithLoading";
import {
  getCampaign,
  getCampaignSummary,
  getCampaignInsights,
  getCampaignAdSets,
  getCampaignAds,
  getCampaignLeads,
} from "../services/campaigns";
import CampaignDetails from "../components/CampaignDetails";
import SectionLoader from "../components/SectionLoader";
import Warning from "../components/Warning";

import { useUserContext } from "../UserContext";

import impressions from "../public/images/impressions.svg";
import clicks from "../public/images/clicks.svg";
import conversions from "../public/images/conversions.svg";
import cost from "../public/images/cost.svg";
import cpm from "../public/images/cpm.svg";
import cpc from "../public/images/cpc.svg";
import cpa from "../public/images/cpa.svg";
import ctr from "../public/images/ctr.svg";

const TableWithLoading = WithLoading(Table);

export default function CampaignReport(props: any) {
  const history = useHistory();
  const { locale } = useUserContext();
  const _locale = locale();

  const [campaignId, setCampaignId] = useState<string>(props.match.params.id);
  const [campaign, setCampaign] = useState();
  const [campaignSummary, setCampaignSummary] = useState();
  const [campaignInsights, setCampaignInsights] = useState();
  const [campaignAdSets, setCampaignAdSets] = useState();
  const [campaignAds, setCampaignAds] = useState();
  const [campaignLeads, setCampaignLeads] = useState();

  const [width, setWidth] = useState(1000);

  const [getCampaignAsync, loadingCampaign, errorGetCampaign] = useAsync<
    typeof getCampaign
  >(
    (...params) => getCampaign(...params),
    (res) => {
      return Promise.all([
        setCampaign(res),
        setWidth(() => {
          return document.getElementsByClassName("themainwrapper")
            ? document.getElementsByClassName("themainwrapper")[0].offsetWidth
            : 1000;
        }),
      ]);
    }
  );

  const [
    getCampaignSummaryAsync,
    loadingCampaignSummary,
    errorGetCampaignSummary,
  ] = useAsync<typeof getCampaignSummary>(
    (...params) => getCampaignSummary(...params),
    (res) => {
      return Promise.all([setCampaignSummary(res)]);
    }
  );

  const [
    getCampaignInsightsAsync,
    loadingCampaignInsights,
    errorGetCampaignInsights,
  ] = useAsync<typeof getCampaignInsights>(
    (...params) => getCampaignInsights(...params),
    (res) => {
      return Promise.all([setCampaignInsights(res)]);
    }
  );

  const [
    getCampaignAdSetsAsync,
    loadingCampaignAdSets,
    errorGetCampaignAdSets,
  ] = useAsync<typeof getCampaignAdSets>(
    (...params) => getCampaignAdSets(...params),
    (res) => {
      return Promise.all([setCampaignAdSets(res)]);
    }
  );

  const [getCampaignAdsAsync, loadingCampaignAds, errorGetCampaignAds] =
    useAsync<typeof getCampaignAds>(
      (...params) => getCampaignAds(...params),
      (res) => {
        return Promise.all([setCampaignAds(res)]);
      }
    );

  const [getCampaignLeadsAsync, loadingCampaignLeads, errorGetCampaignLeads] =
    useAsync<typeof getCampaignLeads>(
      (...params) => getCampaignLeads(...params),
      (res) => {
        return Promise.all([setCampaignLeads(res)]);
      }
    );

  useEffect(() => {
    setCampaignId(props.match.params.id);
    getCampaignAsync({ id: campaignId });
    getCampaignSummaryAsync({ id: campaignId });
    getCampaignInsightsAsync({ id: campaignId });
    getCampaignAdSetsAsync({ id: campaignId });
    getCampaignAdsAsync({ id: campaignId });
    getCampaignLeadsAsync({ id: campaignId });
  }, []);

  const summaries = (data, filters) => {
    const arr = filters.map((filter) => {
      return {
        name: filter,
        stat: 0,
      };
    });

    data.forEach((child) => {
      if (!child) return;
      Object.keys(child).forEach((key) => {
        const index = arr.findIndex((match) => match["name"] === key);
        if (index >= 0) {
          arr[index]["stat"] += child[key];
        }
      });
    });
    return arr;
  };

  const csvPrep = (data, filters) => {
    const arr = filters.map((filter) => {
      return {
        name: filter,
        stat: 0,
      };
    });

    data.forEach((child) => {
      if (!child) return;
      Object.keys(child).forEach((key) => {
        const index = arr.findIndex((match) => match["name"] === key);
        if (index >= 0) {
          arr[index]["stat"] += child[key];
        }
      });
    });

    const obj = {};
    arr.forEach((child) => {
      obj[child.name] = child.stat;
    });
    return [obj];
  };

  type CampaignSummaryCard = {
    title: string;
    icon: string;
    color: string;
    stat: string;
    currency?: boolean;
    percent?: boolean;
    text?: string;
  };

  const CampaignSummaryCard: React.FunctionComponent<CampaignSummaryCard> = ({
    title,
    icon,
    stat,
    color,
    currency,
    percent,
    text,
  }) => (
    <aside>
      <div
        key={`summary-card-${title}`}
        className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div className={`absolute bg-${color}-600 rounded-md p-3`}>
            <img className="w-5 h-5 flex-shrink-0" src={icon} alt={title} />
          </div>
          <p className="ml-16 text-sm font-medium text-gray-500 truncate">
            {title}
          </p>
        </dt>
        <dd className="ml-16 pb-6 items-baseline sm:pb-7">
          <p className="text-2xl font-semibold text-gray-900">
            {/* {!stat && (typeof stat === "number") && "-"} */}
            {stat && typeof stat === "number" && stat > 0 && (
              <>
                {!currency &&
                  !percent &&
                  Number(stat).toLocaleString(_locale.toLocalString)}
                {currency &&
                  !percent &&
                  Number(stat).toLocaleString(_locale.toLocalString, {
                    style: "currency",
                    currency: campaign?.currency,
                  })}
                {percent &&
                  !currency &&
                  (Number(stat) / 100).toLocaleString(_locale.toLocalString, {
                    style: "percent",
                    minimumFractionDigits: 2,
                  })}
              </>
            )}
          </p>
        </dd>
      </div>
      {text && (
        <div className="inset-x-0 bg-gray-50 rounded-md px-2 py-1 sm:px-1">
          <div className="text-sm">
            <p className="text-sm p-2 text-gray-500">{text}</p>
          </div>
        </div>
      )}
    </aside>
  );

  type SectionHeader = {
    title: string;
    paragraph?: string;
    loading?: boolean;
  };

  const SectionHeader: React.FunctionComponent<SectionHeader> = ({
    title,
    paragraph,
    loading,
  }) => (
    <aside>
      <div className="py-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {loading && <SectionLoader msg={`${_locale.loading} ${title}`} />}
          {!loading && title}
        </h3>
        {paragraph && (
          <p className="mt-2 max-w-4xl text-sm text-gray-500">{paragraph}</p>
        )}
      </div>
    </aside>
  );

  return (
    <LayoutGrid1>
      <div id="themainwrapper" className="themainwrapper min-h-screen mb-10">
        {campaign && (
          <>
            <div className="mt-5">
              <Button
                variant="secondary"
                label="Return to Campaign Details"
                onClick={() => {
                  history.push(`/campaigns/${campaign.id}`);
                }}
              />
            </div>
            <div className="bg-gray-50 mt-5 p-5 border border-gray-200 rounded">
              {loadingCampaign && <Loading />}

              {!loadingCampaign && campaign && (
                <CampaignDetails item={campaign} />
              )}

              {!loadingCampaignSummary && campaignSummary && (
                <>
                  <div className="bg-gray-50 mt-5 p-5 border border-gray-200 rounded">
                    <CSVLink
                      data={csvPrep(
                        [campaignSummary],
                        [
                          "impressions",
                          "reach",
                          "clicks",
                          "cost",
                          "cpm",
                          "cpc",
                          "ctr",
                        ]
                      )}
                      filename={`${campaign.name} - Stats.csv`}
                    >
                      <Button variant="primary" label="Download Stats CSV" />
                    </CSVLink>
                  </div>

                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4 lg:grid-cols-4">
                    <CampaignSummaryCard
                      stat={campaignSummary.impressions}
                      title={
                        _locale.campaign.sections.campaignSummary.cards
                          .impressions.label
                      }
                      icon={impressions}
                      color="gray"
                      text={
                        _locale.campaign.sections.campaignSummary.cards
                          .impressions.text
                      }
                    />
                    <CampaignSummaryCard
                      stat={campaignSummary.clicks}
                      title={
                        _locale.campaign.sections.campaignSummary.cards.clicks
                          .label
                      }
                      icon={clicks}
                      color="gray"
                      text={
                        _locale.campaign.sections.campaignSummary.cards.clicks
                          .text
                      }
                    />
                    <CampaignSummaryCard
                      stat={campaignSummary.conversions}
                      title={
                        _locale.campaign.sections.campaignSummary.cards
                          .conversions.label
                      }
                      icon={conversions}
                      color="gray"
                      text={
                        _locale.campaign.sections.campaignSummary.cards
                          .conversions.text
                      }
                    />
                    <CampaignSummaryCard
                      stat={campaignSummary.cost}
                      title={
                        _locale.campaign.sections.campaignSummary.cards.cost
                          .label
                      }
                      icon={cost}
                      color="gray"
                      currency
                      text={
                        _locale.campaign.sections.campaignSummary.cards.cost
                          .text
                      }
                    />
                    <CampaignSummaryCard
                      stat={campaignSummary.cpm}
                      title="CPM"
                      icon={cpm}
                      currency
                      color="gray"
                    />
                    <CampaignSummaryCard
                      stat={campaignSummary.cpc}
                      title="CPC"
                      icon={cpc}
                      currency
                      color="gray"
                    />
                    <CampaignSummaryCard
                      stat={campaignSummary.ctr}
                      title="CTR"
                      icon={ctr}
                      color="gray"
                      percent
                    />
                    <CampaignSummaryCard
                      stat={campaignSummary.cpa}
                      title="CPA"
                      icon={cpa}
                      color="gray"
                      currency
                    />
                  </dl>
                </>
              )}

              <SectionHeader
                title={_locale.campaign.sections.insights.title.label}
                loading={loadingCampaignInsights}
              />

              {!loadingCampaignInsights && campaignInsights && (
                <>
                  <ul
                    id="chart"
                    role="list"
                    className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-2"
                  >
                    <li className="col-span-1 bg-white p-1 rounded-lg shadow">
                      <div className="p-4 pb-0">
                        <h1>
                          {_locale.campaign.sections.insights.title.label}
                        </h1>
                      </div>
                      <AreaChartWrapper
                        data={{
                          facebook: campaignInsights,
                          google: [],
                          linkedin: [],
                        }}
                        uniqueId={"id"}
                        yAxis={"impressions"}
                        key={"impressions"}
                        width={width / 2}
                        height={width / 3}
                        stroke={"#2b2b2c"}
                        fill={"#2b2b2c"}
                        xAxis={"datePretty"}
                        syncId={"insights"}
                      />
                    </li>
                    <li className="col-span-1 bg-white p-1 rounded-lg shadow">
                      <div className="p-4 pb-0">
                        <h1>
                          {
                            _locale.campaign.sections.insights.graphs
                              .impressions.title.label
                          }
                        </h1>
                      </div>
                      <AreaChartWrapper
                        data={{
                          facebook: campaignInsights,
                          google: [],
                          linkedin: [],
                        }}
                        uniqueId={"id"}
                        yAxis={"reach"}
                        key={"reach"}
                        width={width / 2}
                        height={width / 3}
                        stroke={"#9b9b9b"}
                        fill={"#9b9b9b"}
                        xAxis={"datePretty"}
                        syncId={"insights"}
                      />
                    </li>
                    <li className="col-span-1 bg-white p-1 rounded-lg shadow">
                      <div className="p-4 pb-0">
                        <h1>
                          {
                            _locale.campaign.sections.insights.graphs.clicks
                              .title.label
                          }
                        </h1>
                      </div>
                      <AreaChartWrapper
                        data={{
                          facebook: campaignInsights,
                          google: [],
                          linkedin: [],
                        }}
                        uniqueId={"id"}
                        yAxis={"clicks"}
                        key={"clicks"}
                        width={width / 2}
                        height={width / 3}
                        stroke={"#1ec0da"}
                        fill={"#1ec0da"}
                        xAxis={"datePretty"}
                        syncId={"insights"}
                      />
                    </li>
                    <li className="col-span-1 bg-white p-1 rounded-lg shadow">
                      <div className="p-4 pb-0">
                        <h1>
                          {
                            _locale.campaign.sections.insights.graphs
                              .conversions.title.label
                          }
                        </h1>
                      </div>
                      <AreaChartWrapper
                        data={{
                          facebook: campaignInsights,
                          google: [],
                          linkedin: [],
                        }}
                        uniqueId={"id"}
                        yAxis={"conversions"}
                        key={"conversions"}
                        width={width / 2}
                        height={width / 3}
                        stroke={"#9c85be"}
                        fill={"#9c85be"}
                        xAxis={"datePretty"}
                        syncId={"insights"}
                      />
                    </li>
                  </ul>
                </>
              )}

              <SectionHeader
                title={_locale.campaign.sections.adSets.title.label}
                loading={loadingCampaignAdSets}
              />

              {!loadingCampaignAdSets && campaignAdSets && (
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              {[
                                _locale.campaign.sections.adSets.table.columns
                                  .name.label,
                                _locale.campaign.sections.adSets.table.columns
                                  .impressions.label,
                                _locale.campaign.sections.adSets.table.columns
                                  .reach.label,
                                _locale.campaign.sections.adSets.table.columns
                                  .clicks.label,
                                _locale.campaign.sections.adSets.table.columns
                                  .conversions.label,
                                _locale.campaign.sections.adSets.table.columns
                                  .cost.label,
                                _locale.campaign.sections.adSets.table.columns
                                  .cpm.label,
                                _locale.campaign.sections.adSets.table.columns
                                  .cpc.label,
                                _locale.campaign.sections.adSets.table.columns
                                  .cpa.label,
                                _locale.campaign.sections.adSets.table.columns
                                  .ctr.label,
                                _locale.campaign.sections.adSets.table.columns
                                  .cr.label,
                                "platform",
                              ].map((column: string) => {
                                return (
                                  <th
                                    key={`AdSets-${column}`}
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {column}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {campaignAdSets.map(
                              (adSet: {
                                ad_set_id: React.Key | null | undefined;
                                ad_set_name: string;
                                impressions: string;
                                reach: string;
                                clicks: string;
                                conversions: string;
                                cost: string;
                                cpm: string;
                                cpc: string;
                                cpa: string;
                                ctr: string;
                                cr: string;
                                platform: string;
                              }) => (
                                <tr key={adSet?.ad_set_id}>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {adSet?.ad_set_name}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {adSet?.impressions
                                      ? Number(
                                          adSet?.impressions
                                        ).toLocaleString(_locale.toLocalString)
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {adSet?.reach
                                      ? Number(adSet?.reach).toLocaleString(
                                          _locale.toLocalString
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {adSet?.clicks
                                      ? Number(adSet?.clicks).toLocaleString(
                                          _locale.toLocalString
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {adSet?.conversions
                                      ? Number(
                                          adSet?.conversions
                                        ).toLocaleString(_locale.toLocalString)
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {adSet?.cost
                                      ? Number(adSet?.cost).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "currency",
                                            currency: campaign?.currency,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {adSet?.cpm
                                      ? Number(adSet?.cpm).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "currency",
                                            currency: campaign?.currency,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {adSet?.cpc
                                      ? Number(adSet?.cpc).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "currency",
                                            currency: campaign?.currency,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {adSet?.cpa
                                      ? Number(adSet?.cpa).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "currency",
                                            currency: campaign?.currency,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {adSet?.ctr
                                      ? (
                                          Number(adSet?.ctr) / 100
                                        ).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "percent",
                                            minimumFractionDigits: 2,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {adSet?.cr
                                      ? (
                                          Number(adSet?.cr) / 100
                                        ).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "percent",
                                            minimumFractionDigits: 2,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {adSet?.platform}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <SectionHeader
                title={_locale.campaign.sections.ads.title.label}
                loading={loadingCampaignAds}
              />

              {!loadingCampaignAds && campaignAds && (
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              {[
                                _locale.campaign.sections.ads.table.columns.name
                                  .label,
                                _locale.campaign.sections.ads.table.columns
                                  .impressions.label,
                                _locale.campaign.sections.ads.table.columns
                                  .reach.label,
                                _locale.campaign.sections.ads.table.columns
                                  .clicks.label,
                                _locale.campaign.sections.ads.table.columns
                                  .conversions.label,
                                _locale.campaign.sections.ads.table.columns.cost
                                  .label,
                                _locale.campaign.sections.ads.table.columns.cpm
                                  .label,
                                _locale.campaign.sections.ads.table.columns.cpc
                                  .label,
                                _locale.campaign.sections.ads.table.columns.cpa
                                  .label,
                                _locale.campaign.sections.ads.table.columns.ctr
                                  .label,
                                _locale.campaign.sections.ads.table.columns.cr
                                  .label,
                                "platform",
                              ].map((column: string) => {
                                return (
                                  <th
                                    key={`AdSets-${column}`}
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {column}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {campaignAds.map(
                              (ad: {
                                id: React.Key | null | undefined;
                                ad_name: string;
                                impressions: string;
                                reach: string;
                                clicks: string;
                                conversions: string;
                                cost: string;
                                cpm: string;
                                cpc: string;
                                cpa: string;
                                ctr: string;
                                cr: string;
                                platform: string;
                              }) => (
                                <tr key={ad?.id}>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {ad?.ad_name}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {ad?.impressions
                                      ? Number(ad?.impressions).toLocaleString(
                                          _locale.toLocalString
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {ad?.reach
                                      ? Number(ad?.reach).toLocaleString(
                                          _locale.toLocalString
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {ad?.clicks
                                      ? Number(ad?.clicks).toLocaleString(
                                          _locale.toLocalString
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {ad?.conversions
                                      ? Number(ad?.conversions).toLocaleString(
                                          _locale.toLocalString
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {ad?.cost
                                      ? Number(ad?.cost).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "currency",
                                            currency: campaign?.currency,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {ad?.cpm
                                      ? Number(ad?.cpm).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "currency",
                                            currency: campaign?.currency,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {ad?.cpc
                                      ? Number(ad?.cpc).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "currency",
                                            currency: campaign?.currency,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {ad?.cpa
                                      ? Number(ad?.cpa).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "currency",
                                            currency: campaign?.currency,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {ad?.ctr
                                      ? (Number(ad?.ctr) / 100).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "percent",
                                            minimumFractionDigits: 2,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {ad?.cr
                                      ? (Number(ad?.cr) / 100).toLocaleString(
                                          _locale.toLocalString,
                                          {
                                            style: "percent",
                                            minimumFractionDigits: 2,
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {ad?.platform}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <SectionHeader
                title={_locale.campaign.sections.leads.title.label}
                loading={loadingCampaignLeads}
              />
              {!loadingCampaignLeads && errorGetCampaignLeads && (
                <Warning msg={_locale.campaign.sections.leads.error.label} />
              )}

              {!loadingCampaignLeads && !errorGetCampaignLeads && (
                <>
                  <div className="bg-gray-50 mt-5 p-5 border border-gray-200 rounded">
                    <CSVLink
                      data={
                        campaignLeads &&
                        campaignLeads.map((item: any) => {
                          return {
                            leadId: item.leadId,
                            createdTime: item.createdTime,
                            email: item.email,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            misc: item.misc,
                            phoneNumber: item.phoneNumber,
                            platform: item.platform,
                          };
                        })
                      }
                      filename={`${campaign.name} - Leads`}
                    >
                      <Button variant="primary" label="Download Leads CSV" />
                    </CSVLink>
                  </div>

                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {[
                                  "leadId",
                                  _locale.campaign.sections.leads.table.columns
                                    .createdTime.label,
                                  _locale.campaign.sections.leads.table.columns
                                    .firstName.label,
                                  _locale.campaign.sections.leads.table.columns
                                    .lastName.label,
                                  _locale.campaign.sections.leads.table.columns
                                    .email.label,
                                  _locale.campaign.sections.leads.table.columns
                                    .phoneNumber.label,
                                  _locale.campaign.sections.leads.table.columns
                                    .platform.label,
                                  _locale.campaign.sections.leads.table.columns
                                    .misc.label,
                                ].map((column: string) => {
                                  return (
                                    <th
                                      key={`Leads-${column}`}
                                      scope="col"
                                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      {column}
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {campaignLeads.map(
                                (lead: {
                                  leadId: string;
                                  createdTime: string;
                                  firstName: string;
                                  lastName: string;
                                  email: string;
                                  phoneNumber: string;
                                  platform: string;
                                  misc: string;
                                }) => (
                                  <tr key={lead?.leadId}>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {lead?.leadId ? lead?.leadId : "-"}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      {lead?.createdTime
                                        ? new Date(
                                            lead?.createdTime
                                          ).toLocaleString(
                                            _locale.toLocalString,
                                            {
                                              weekday: "long",
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                            }
                                          )
                                        : "-"}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {lead?.firstName ? lead?.firstName : "-"}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {lead?.lastName ? lead?.lastName : "-"}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {lead?.email ? lead?.email : "-"}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {lead?.phoneNumber
                                        ? lead?.phoneNumber
                                        : "-"}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {lead?.platform ? lead?.platform : "-"}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {lead?.misc ? lead?.misc : "-"}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </LayoutGrid1>
  );
}
