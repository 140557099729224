import { httpsCallable } from "firebase/functions";
import app from "../firebase";

const REGION =
  window.location.hostname == "localhost" ? "us-central1" : "europe-west3";

const functions = app.functions(REGION);

export function getUsers() {
  return httpsCallable(functions, "admin-users-listUsers")().then((result) => {
    return result.data;
  });
}

export function getUser({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-users-getUser"
  )({
    id: id,
  }).then((result) => {
    return result.data;
  });
}

export function searchUsers({ term }: { term: string }) {
  return httpsCallable(
    functions,
    "admin-users-searchUsers"
  )({ q: term }).then((result) => {
    return result.data;
  });
}

export function postUser({
  firstName,
  lastName,
  email,
  locale,
  phone,
  userType,
  brand_id,
}: {
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
  phone: string;
  userType: string;
  brand_id: string;
}) {
  return httpsCallable(
    functions,
    "admin-users-createUser"
  )({
    payload: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      locale: locale,
      phone: phone,
      userType: userType,
      brand_id: brand_id,
    },
  }).then((result) => {
    return result.data;
  });
}

export function postUsers({ users }: { users: any[] }) {
  return httpsCallable(
    functions,
    "admin-users-createUsers"
  )({ payload: users }).then((result) => {
    return result.data;
  });
}

export function putUser({
  id,
  firstName,
  lastName,
  email,
  locale,
  phone,
  userType,
  brand_id,
}: {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
  phone: string;
  userType: string;
  brand_id: string;
}) {
  return httpsCallable(
    functions,
    "admin-users-updateUser"
  )({
    id: id,
    payload: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      locale: locale,
      phone: phone,
      userType: userType,
      brand_id: brand_id,
    },
  }).then((result) => {
    return result.data;
  });
}

export function putUsersPassword({ users }: { users: any[] }) {
  return httpsCallable(
    functions,
    "admin-users-updateUsersPassword"
  )(users).then((result) => {
    return result.data;
  });
}

export function deleteUser({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-users-deleteUser"
  )({ id: id }).then((result) => {
    return result.data;
  });
}

export function makeAdmin({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-users-makeAdmin"
  )({ id: id }).then((result) => {
    return result.data;
  });
}

export function addUserOrgs({ id, orgId }: { id: string; orgId: string }) {
  return httpsCallable(
    functions,
    "admin-users-addUserOrgs"
  )({
    id: id,
    orgId: orgId,
  }).then((result) => {
    return result.data;
  });
}

export function removeUserOrgs({ id, orgId }: { id: string; orgId: string }) {
  return httpsCallable(
    functions,
    "admin-users-removeUserOrgs"
  )({
    id: id,
    orgId: orgId,
  }).then((result) => {
    return result.data;
  });
}

export function listUserOrgs({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-users-listUserOrgs"
  )({ id: id }).then((result) => {
    return result.data;
  });
}
