import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import {
  Button,
  SectionTitle,
  Form,
  Field,
  Input,
  ControlledSelect,
  ControlledReactSelect,
} from "@mutable/meiosis";

import useAsync from "../services/utils/useAsync";
import {
  deleteScheduledItem,
  sendScheduledItem,
  getScheduledItem,
  getScheduledItemLog,
  updateScheduledItem,
  setScheduledItemToScheduled,
} from "../services/schedule";
import {getUsers} from "../services/users";
import ActionSectionHeader from "../components/ActionSectionHeader";
import ReactJson from "react-json-view";

export default function ScheduledItem(props: any) {
  const history = useHistory();

  const [scheduledItemId, setScheduledItemId] = useState<string>(
    props.match.params.id
  );
  const [scheduledItem, setScheduledItem] = useState();

  const [getScheduledItemAsync, loadingScheduledItem, errorGetScheduledItem] =
    useAsync<typeof getScheduledItem>(
      (...params) => getScheduledItem(...params),
      (res) => {
        setEmailsSelected(res.emails);
        return setScheduledItem(res);
      }
    );

  const [scheduledItemLog, setScheduledItemLog] = useState();

  const [
    getScheduledItemLogAsync,
    loadingScheduledItemLog,
    errorGetScheduledItemLog,
  ] = useAsync<typeof getScheduledItemLog>(
    (...params) => getScheduledItemLog(...params),
    (res) => {
      return setScheduledItemLog(res);
    }
  );

  const [emails, setEmails] = useState();
  const [getEmailsAsync, loadingGetEmails, errorGetEmails] = useAsync<
    typeof getUsers
  >(
    (...params) => getUsers(...params),
    (res) => {
      return setEmails(res);
    }
  );

  const [emailsListSelected, setEmailsListSelected] = useState<string>("");
  const [emailsSelected, setEmailsSelected] = useState<string[]>([]);

  const [
    updateScheduledItemAsync,
    loadingUpdateScheduledItem,
    errorUpdateScheduledItem,
  ] = useAsync<typeof updateScheduledItem>(
    (...params) => updateScheduledItem(...params),
    (res) => {
      history.go(0);
    }
  );

  const [
    deleteScheduledItemAsync,
    loadingDeleteScheduledItem,
    errorDeleteScheduledItem,
  ] = useAsync<typeof deleteScheduledItem>(
    (...params) => deleteScheduledItem(...params),
    (res) => {
      history.push(`/scheduled`);
    }
  );

  const [
    sendScheduledItemAsync,
    loadingSendScheduledItem,
    errorSendScheduledItem,
  ] = useAsync<typeof sendScheduledItem>(
    (...params) => sendScheduledItem(...params),
    (res) => {
      console.log("Sent");
      history.go(0);
    }
  );

  const [
    setScheduledItemToScheduledAsync,
    loadingSetScheduledItemToScheduled,
    errorSetScheduledItemToScheduled,
  ] = useAsync<typeof setScheduledItemToScheduled>(
    (...params) => setScheduledItemToScheduled(...params),
    (res) => {
      history.go(0);
    }
  );

  useEffect(() => {
    getScheduledItemAsync(scheduledItemId);
    getScheduledItemLogAsync(scheduledItemId);
    getEmailsAsync();
  }, []);

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title={`Update ${scheduledItemId}`} />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {scheduledItem && (
            <>
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Scheduled Item Internal ID
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {scheduledItem.id}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Selected Campaign
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {scheduledItem.campaignName}
                        <br />
                        {scheduledItem.campaignId}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Email address
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <ul
                          role="list"
                          className="border border-gray-200 rounded-md divide-y divide-gray-200"
                        >
                          {emailsSelected.map((email) => {
                            return (
                              <div className="my-2" key={email}>
                                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                  <div className="w-0 flex-1 flex items-center">
                                    <span className="ml-2 flex-1 w-0 truncate">
                                      {email}
                                    </span>
                                  </div>
                                </li>
                              </div>
                            );
                          })}
                        </ul>
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Email Subject and Template
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {scheduledItem.subject}
                        <br />
                        {scheduledItem.emailTemplateName}
                        <br />
                        {scheduledItem.emailTemplate}
                        <br />
                        No Interaction Template
                        <br />
                        {scheduledItem.emailTemplateNoInteractionName}
                        <br />
                        {scheduledItem.emailTemplateNoInteraction}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Details
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        Status: {scheduledItem.status}
                        <br />
                        Day of Week: {scheduledItem.dayOfWeek}
                        <br />
                        Time of Day: {scheduledItem.timeOfDay}
                        <br />
                        Frequency: {scheduledItem.frequency}
                      </dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Timestamps
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        Created at: {scheduledItem.createdAt}
                        <br />
                        Created by: {scheduledItem.createdBy}
                        <br />
                        Updated at: {scheduledItem.updatedAt}
                        <br />
                        Updated by: {scheduledItem.updatedBy}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <br />
              <hr />
              <Form
                onSubmit={(data) => {
                  updateScheduledItemAsync({
                    id: scheduledItemId,
                    emails: emailsSelected,
                  });
                }}
                defaultValues={scheduledItem}
              >
                {({register, control, errors}) => {
                  return (
                    <>
                      {emails && (
                        <>
                          <h4 className="font-normal text-gray-900 mb-2">
                            Add additional email to the report
                          </h4>
                          <ControlledReactSelect
                            name="emails"
                            control={control}
                            options={emails.map((item) => ({
                              value: item.id,
                              label: item.email,
                            }))}
                            defaultSelected={emails.map(
                              (item) =>
                                ({
                                  value: item.id,
                                  label: item.email,
                                }[0])
                            )}
                            onChange={(e) => {
                              setEmailsListSelected(e.label);
                            }}
                            searchable
                          />

                          <Button
                            className="my-4"
                            variant="secondary"
                            label="Add Email to Report"
                            onClick={(e) => {
                              e.preventDefault();
                              const _temp: string[] =
                                emailsSelected && emailsSelected.length > 0
                                  ? [...emailsSelected]
                                  : [];
                              if (emailsListSelected.length < 1) return false;
                              if (_temp.includes(emailsListSelected))
                                return false;
                              _temp.push(emailsListSelected);
                              setEmailsSelected(_temp);
                            }}
                          />
                        </>
                      )}
                      {emailsSelected && (
                        <>
                          <br />
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Email
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                >
                                  <span className="sr-only">Remove</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {emailsSelected.map((email) => (
                                <tr key={email}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {email}
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <Button
                                      variant="pink"
                                      label="Remove"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const _temp: string[] =
                                          emailsSelected &&
                                          emailsSelected.length > 0
                                            ? [...emailsSelected]
                                            : [];
                                        const _tempCleaned: string[] =
                                          _temp.filter((v) => v !== email);
                                        setEmailsSelected(_tempCleaned);
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                      <br />

                      <Button
                        variant="primary"
                        label="Update Scheduled Item"
                        loading={loadingUpdateScheduledItem}
                      />
                    </>
                  );
                }}
              </Form>

              <div className="bg-green-100 mt-5 p-5 border border-green-200 rounded">
                <ActionSectionHeader
                  name={`Current Status: ${scheduledItem.status} `}
                />
                <Button
                  variant="white"
                  label="Set Report To Approved for Automatic Email"
                  loading={loadingSetScheduledItemToScheduled}
                  onClick={() => {
                    setScheduledItemToScheduledAsync({id: scheduledItemId});
                  }}
                />
                <br />
              </div>

              <div className="bg-green-100 mt-5 p-5 border border-green-200 rounded">
                <ActionSectionHeader name={`Send Report Now `} />
                <Button
                  variant="white"
                  label="Send Report Now"
                  loading={loadingSendScheduledItem}
                  onClick={() => {
                    sendScheduledItemAsync({id: scheduledItemId});
                  }}
                />
                <br />
              </div>

              {scheduledItemLog && (
                <>
                  <div className="bg-white mt-5 p-5 border border-white-200 rounded">
                    <ActionSectionHeader name={`Log`} />
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <ReactJson src={scheduledItemLog} />
                    </div>
                  </div>
                </>
              )}

              <div className="bg-red-100 mt-5 p-5 border border-red-200 rounded">
                <ActionSectionHeader name={`Delete ${scheduledItem.subject}`} />
                <Button
                  variant="white"
                  label="Delete"
                  loading={loadingDeleteScheduledItem}
                  onClick={() => {
                    deleteScheduledItemAsync({id: scheduledItemId});
                  }}
                />
                <br />
              </div>
            </>
          )}
        </div>
      </div>
    </LayoutGrid1>
  );
}
