import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";
import WithLoading from "../services/utils/WithLoading";
import { Button, SectionTitle, DataTable, Alert } from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import { getBrandSummary, getBrandCampaigns } from "../services/campaigns";
import { getBrand } from "../services/brands";

export default function BrandCampaigns(props: any) {
  const history = useHistory();

  const [campaigns, setCampaigns] = useState([]);
  const [brandSummary, setBrandSummary] = useState([]);
  const [brand, setBrand] = useState();
  const [brandId, setBrandId] = useState<string>(props.match.params.id);

  const SectionTitleLoading = WithLoading(SectionTitle);

  const [getCampaignsAsync, loadingCampaigns, errorGetCampaigns] = useAsync<
    typeof getBrandCampaigns
  >(
    (...params) => getBrandCampaigns(...params),
    (res) => {
      return setCampaigns(res);
    }
  );

  const [getBrandSummaryAsync, loadingBrandSummary, errorGetBrandSummary] =
    useAsync<typeof getBrandSummary>(
      (...params) => getBrandSummary(...params),
      (res) => {
        return setBrandSummary(res);
      }
    );

  const [getBrandAsync, loadingGetBrand, errorGetBrand] = useAsync<
    typeof getBrand
  >(
    (...params) => getBrand(...params),
    (res) => {
      return setBrand(res);
    }
  );

  useEffect(() => {
    setBrandId(props.match.params.id);
    getCampaignsAsync({ brand_id: brandId });
    getBrandSummaryAsync({ brand_id: brandId });
    getBrandAsync({ id: brandId });
  }, []);

  const totalBrandSummary = Object.entries(brandSummary)
    .splice(0, 6)
    .map(([key, value]) => {
      return {
        [key]: value,
      };
    });

  return (
    <LayoutGrid1>
      <div className="mb-10">
        {brand && brand.name && (
          <SectionTitleLoading
            isLoading={loadingGetBrand}
            title={`${brand && brand.name} Summary`}
          />
        )}
        <div className="">
          {errorGetBrandSummary && (
            <Alert type="error" message={errorGetBrandSummary} />
          )}

          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3">
            {Object.entries(brandSummary)
              .slice(6, 12)
              .map(([key, value], index) => (
                <div
                  key={key}
                  className="relative overflow-hidden rounded-lg bg-white px-4 pb-5 pt-5 shadow sm:px-6 sm:pt-6"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {key.split("_")[1]}
                    </p>
                  </dt>
                  <dd className="flex items-baseline pb-6 sm:pb-7">
                    <p className="text-2xl font-semibold text-gray-900">
                      {value ? Number(value).toLocaleString("en-us") : 0}
                      <span className="ml-2 text-sm font-medium text-gray-500">
                        from{" "}
                        {Object.entries(totalBrandSummary[index]).map(
                          ([keys, value]) =>
                            key.split("_")[1].includes(keys.split("_")[1])
                              ? Number(value).toLocaleString("en-us", {
                                  maximumFractionDigits: 0,
                                })
                              : ""
                        )}
                      </span>
                    </p>
                  </dd>
                </div>
              ))}
          </dl>
        </div>
      </div>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Campaigns" />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {errorGetCampaigns && (
            <Alert type="error" message={errorGetCampaigns} />
          )}
          <DataTable
            isLoading={loadingCampaigns}
            columns={[
              {
                id: "name",
                display: "Name",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "clicks",
                display: "Clicks",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "conversions",
                display: "Conversions",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "cost",
                display: "Cost",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "impressions",
                display: "Impressions",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "reach",
                display: "Reach",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "currency",
                display: "Currency",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "org_name",
                display: "OrgName",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "visible",
                display: "Visible",
                type: "string",
                sortable: true,
              },
              {
                id: "status",
                display: "Status",
                type: "string",
                sortable: true,
              },
              { id: "goal", display: "Goal", type: "string", sortable: true },
              {
                id: "start_date",
                display: "start_date",
                type: "string",
                sortable: true,
              },
              {
                id: "end_date",
                display: "end_date",
                type: "string",
                sortable: true,
              },
              { id: "edit", display: "Edit", type: "custom" },
              { id: "report", display: "Report", type: "custom" },
            ]}
            rows={
              campaigns &&
              campaigns.map((item: any) => {
                return {
                  id: item.id,
                  name: item.name,
                  clicks: Number(item.clicks).toLocaleString("en-us"),
                  conversions: Number(item.conversions).toLocaleString("en-us"),
                  cost: Number(item.cost).toLocaleString("en-us", {
                    maximumFractionDigits: 0,
                  }),
                  impressions: Number(item.impressions).toLocaleString("en-us"),
                  reach: Number(item.reach).toLocaleString("en-us"),
                  currency: item.currency,
                  org_name: item.org_name,
                  visible: item.visible.toString(),
                  status: item.status,
                  goal: item.goal,
                  start_date: item.start_date,
                  end_date: item.end_date,

                  edit: (
                    <Button
                      variant="secondary"
                      label="Edit"
                      onClick={() => {
                        history.push(`/campaigns/${item.id}`);
                      }}
                    />
                  ),
                  report: (
                    <Button
                      variant="secondary"
                      label="Report"
                      onClick={() => {
                        history.push(`/campaigns/${item.id}/report`);
                      }}
                    />
                  ),
                };
              })
            }
            onRowClick={(item, index) => {
              history.push(`/campaigns/${item.id}`);
            }}
          />
        </div>
      </div>
    </LayoutGrid1>
  );
}
