import React, { useContext } from "react";

export default function ActionSectionHeader(props) {  
  return (
    <div className="relative mb-2">
      <div className="relative flex justify-start">
        <span className="pr-3 text-base font-medium text-gray-900">{props.name}</span>
      </div>
    </div>
  )
};