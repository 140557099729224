import React from "react";
import { XCircleIcon } from '@heroicons/react/solid'

type Props = {
  msg?: string;
};

function Warning(props: Props) {
  return (
    <div className="rounded-md bg-orange-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          {props?.msg && (
            <div className="mt-2 text-sm text-orange-700">
              <p>{props?.msg}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Warning;
