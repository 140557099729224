import React, { Fragment, useState } from "react";
import goalConverter from "../libs/goalConverter";
import { useUserContext } from "../UserContext";

type Campaign = {
  campaignId: string;
  displayName: string;
  details: string;
  landingPage: string;
  previewLink: string;
  status: string;
  budget: string;
  currency: string;
  goal: string;
  startDate: string;
  endDate: string;
  facebookCampaignId: string;
  googleCampaignId: string;
  liCampaignId: string;
};

type Props = {
  item: Campaign;
};

export default function CampaignDetails(props: Props) {
  const { locale } = useUserContext();
  const _locale = locale();

  const { item } = props;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {item?.displayName}
        </h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.id.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.id ? item?.id : "-"}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {
                _locale.campaign.sections.campaignDetails.facebookCampaignId
                  .label
              }
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.facebookCampaignId ? item?.facebookCampaignId : "-"}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.googleCampaignId.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.googleCampaignId ? item?.googleCampaignId : "-"}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {
                _locale.campaign.sections.campaignDetails.googleCampaignSmartId
                  .label
              }
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.googleCampaignSmartId ? item?.googleCampaignSmartId : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.liCampaignId.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.liCampaignId ? item?.liCampaignId : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.currency.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.currency ? item?.currency : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.factor.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.factor
                ? Number(item?.factor).toLocaleString(_locale.toLocalString)
                : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.visible.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.visible ? item?.visible.toString() : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.insights.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.insights ? item?.insights.toString() : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.createdAt.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.createdAt
                ? new Date(item?.createdAt).toLocaleString(
                    _locale.toLocalString,
                    {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )
                : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.createdBy.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.createdBy ? item?.createdBy : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.updatedAt.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.updatedAt
                ? new Date(item?.updatedAt).toLocaleString(
                    _locale.toLocalString,
                    {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )
                : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.updatedBy.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.updatedBy ? item?.updatedBy : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.brand_id.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.brand_id ? item?.brand_id : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.brandName.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.brandName ? item?.brandName : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.hideMoney.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.hideMoney ? item?.hideMoney : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.status.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.status ? item?.status : "-"}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.budget.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.budget
                ? Number(item?.budget).toLocaleString(_locale.toLocalString, {
                    style: "currency",
                    currency: item?.currency,
                  })
                : "-"}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.dateStart.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.startDate
                ? new Date(item?.startDate).toLocaleString(
                    _locale.toLocalString,
                    {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )
                : "-"}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.goal.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.goal ? goalConverter(item.goal, _locale) : "-"}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.dateEnd.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.endDate
                ? new Date(item?.endDate).toLocaleString(
                    _locale.toLocalString,
                    {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )
                : "-"}
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              {_locale.campaign.sections.campaignDetails.details.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.details ? item?.details : "-"}
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Landingpage</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.landingPage ? item?.landingPage : "-"}
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">previewLink</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.previewLink ? item?.previewLink : "-"}
            </dd>
          </div>
          {/* <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul
                role="list"
                className="border border-gray-200 rounded-md divide-y divide-gray-200"
              >
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      resume_back_end_developer.pdf
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Download
                    </a>
                  </div>
                </li>
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      coverletter_back_end_developer.pdf
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div> */}
        </dl>
      </div>
    </div>
  );
}
