import React from "react";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import { SectionTitle } from "@mutable/meiosis";
import StickyMenuLoggedOut from "../components/StickyMenuLoggedOut";

export default function TosEng(props: any) {
  return (
    <LayoutGrid1>
      <StickyMenuLoggedOut />
      <SectionTitle title="Doorboost Terms of Service" />
      <div>
        <p>Terms of service</p>
        <p>I. General information</p>
        <p>1. contractual partner, scope of application, definitions</p>
        <p>1.1 The contractual partner is Doorboost GmbH, Friedrichstrasse 171, 10117 Berlin (hereinafter referred to as "Doorboost" or "we" or "us").</p>
        <p>1.2 Our terms and conditions shall apply exclusively; conflicting terms and conditions or other terms and conditions shall not apply, except if we agree to them in writing. We do not accept any deviating terms and conditions unless we agree to them and their validity in writing. Our terms and conditions shall also apply if we have knowledge of conflicting or deviating terms and conditions when providing a service. Our terms and conditions shall also apply to all future</p>
        <p>Business.</p>
        <p>1.3 Manufacturers are all companies that manufacture products under one or more brands and that sell them through distribution partners, in particular to consumers.</p>
        <p>1.4 Dealerships are all companies with a single location with only one physical address that</p>
        <p>are in contractual relations with the manufacturer or multiple manufacturers and sell their products, or provides their services.</p>
        <p>1.5 Users are all companies to which the range of services is directed, i.e. manufacturers and</p>
        <p>Dealers.</p>
        <p>1.6 Platform is the Internet platform offered by us under doorboost.com including</p>
        <p>all functions through which we offer and provide our services.</p>
        <p>1.7 Campaigns are online advertising campaigns by manufacturers to promote their products.</p>
        <p>1.8 Content is all content provided by the manufacturer and/or retailer either itself or through third parties or contents created by Doorboost on behalf of the manufacturer or dealer (in particular texts, photographs, images, graphics, drawings, videos, films, music, sounds) for campaigns.</p>
        <p>1.9 User Data means any data or content which Users upload, transmit or otherwise</p>
        <p>on or over the platform.</p>
        <p>1.10 Aggregated data is user data that is (i) anonymised so that it does not contain any individually identifiable information and is, or will be (ii) combined with the data of other users or additional data sources.</p>
        <p>2. Services of Doorboost</p>
        <p>2.1 Doorboost offers access via the platform to functions and services which are provided to help develop online marketing content and share it with their dealerships. In some cases</p>
        <p>this includes campaigns that allow dealerships to access the content and/or campaigns of the manufacturers, as well as to participate in, adapt and share them with consumers (including through online channels such as Facebook, Google, landing pages, email and/or platforms of other third parties). Doorboost's services consist solely of the provision of services; a particular</p>
        <p>Success is not due. As far as possible, Doorboost also offers consulting services for the creation or adaptation of campaigns.</p>
        <p>2.2 As part of our main obligation to perform our services, we offer an average availability of the platform of 99% per month within the framework of the technical and operational</p>
        <p>possibilities of us and our providers. We are entitled to shut down the platform for purposes of updating or technical inspection and maintenance. The user consents to all necessary or useful shutdowns - also short-term and unannounced shutdowns. In return, we try to carry out such shutdowns only at times during which the retrieval of data is only minor.</p>
        <p>2.3. Service Availability Time is calculated as 100% less Downtime, where Downtime consists of the total number of minutes per month in which a User Accessible Application is not available to Users for 15 consecutive minutes or more divided by the number of minutes available in such month, excluding Scheduled Downtime. Calculation as follows:</p>
        <p>Service Availability (in minutes) = Planned Service Availability - (Planned Downtime + Unplanned Downtime)</p>
        <p>Service Availability (in percent) = (Service Availability (in minutes) / (Scheduled Service Availability (in minutes) - Scheduled Downtime (in minutes)) * 100%</p>
        <p>If the cause of the unavailability cannot be determined or is disputed, a joint review will be conducted with the User and all other third parties involved (if necessary) to agree on an appropriate sharing of responsibility for the unavailability among the relevant party(s). The results of the service availability review shall be made transparently available to all parties.</p>
        <p>In the event that the applications accessible to the user have an availability of less than</p>
        <p>99% in a given month, the User is entitled to a Doorboost service availability credit as defined in the table below. The amount of the Service Availability Credit is based on the amount of the current monthly platform fee as agreed between Doorboost and the User. In the event of a Service Availability Credit being granted, it will be credited to the User within the following month in which the Service Failure occurred. The credit will be offset against the next monthly platform fee, or in the event of a contract expiring, transferred to the account normally used to pay the monthly platform fee.</p>
        <p>Service Credit/Refund. If the actual service availability for the Service in a particular calendar month is less than the Service Availability Guarantee, the User will receive a credit equal to a percentage of the monthly Platform Fee for that month as shown in the table below:</p>
        <p>&nbsp;</p>
        <table>
          <tbody>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>Service availability greater than or equal to</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>Service availability less than:</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>Service availability credit (percentage of monthly platform fee)</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>95%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>98.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>5%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>90%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>94.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>10%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>85%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>89.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>15%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>80%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>84.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>20%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>75%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>79.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>25%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>50%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>74.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>50%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>0%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>49.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>100%</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>2.4 Insofar as we offer services free of charge, we are entitled at any time and without prior notice to change, restrict or no longer offer these services. Services provided free of charge do not constitute essential contractual obligations on our part.</p>
        <hr />
        <p>3. Technical requirements of the user</p>
        <p>3.1 A web browser is required for the contractual use of our content. The Adobe Acrobat Reader is required to view the documents contained on the website in pdf format.</p>
        <p>3.2 Otherwise, the user requires his own provider for an Internet connection. The user is solely responsible for errors or downtimes of his provider.</p>
        <p>4. Content from users</p>
        <p>4.1 For all content of the user, as well as for its correctness and legal admissibility, the user alone bears the responsibility. In particular, the user warrants that the content posted by him does not violate the rights of third parties, in particular copyrights, personal rights, design rights and/or trademark rights of third parties and does not violate legally valid, in particular competition law and/or criminal law and/or data protection regulations.</p>
        <p>4.2 If the user posts content, he undertakes to provide truthful information only.</p>
        <p>4.3 We are entitled, but not obliged, to check the content posted for the impairment of the corresponding rights of third parties or for the violation of general legal provisions (in particular the Unfair Competition Act or penal provisions). We shall always be entitled to remove or block content until disputes have been settled as to whether content violates the rights of third parties or general legal provisions. Insofar as the user violates the obligations arising from the above Clause 4.1. or Clause 4.2., the user is obliged to indemnify us against any claims by third parties arising from this breach of duty. This shall also include any fines, penalties or costs in connection with criminal prosecution as well as our possible judicial and extrajudicial costs in the context of a civil law dispute. The parties will always support each other to the best of their ability in defending themselves against third-party claims.</p>
        <p>5. Further obligations of users</p>
        <p>5.1 The User may not store or upload any data on the Platform which, by its nature or quality (e.g. viruses, damaged files, "Trojan horses" or other contaminating or destructive features), size or duplication (e.g. spamming), is such as to endanger the existence or operation of the computer centre or data network of us or of third parties commissioned by us. The user will not take any action that could impose an unreasonable or disproportionately large load on our infrastructure or cause damage or disrupt the functioning of our systems or services.</p>
        <p>5.2 Insofar as the content posted by the user is protected by copyright, trademark or other proprietary rights, he grants us a simple right of use for the reproduction of the protected content during the term of the contract, in particular the corresponding reproduction, distribution and processing rights and the right to make it publicly available.</p>
        <p>5.3 The user is obliged to exercise the greatest possible care when using passwords, user names or other security devices made available in connection with our services and to ensure that their disclosure to third parties is prevented. The user shall be liable for damages caused by unauthorised use of passwords by unauthorised third parties.</p>
        <p>5.4 The user shall not publish and disseminate any inadmissible advertising such as chain letters and pyramid schemes.</p>
        <p>6. Set-off and right of retention</p>
        <p>6.1 Any counterclaims may only be set off if the counterclaims are undisputed or have been legally established.</p>
        <p>6.2 A user may only refuse performance or retain performance on the grounds of any counterclaims if the counterclaims are undisputed or have been established as final and absolute.</p>
        <p>7. Third party websites</p>
        <p>7.1 Our Platform and Services include systems that enable the User, among other things, to access and publish content on advertising platforms or advertising systems (e.g. Facebook, Google, Instagram, email, other websites and third party platforms) and to access websites and services controlled by third parties (collectively, the "Advertising Platform"). The user hereby grants us all necessary authorizations to access these advertising platforms on his behalf in order to make our platform available to him, including the use and analysis of the information made available by the advertising platforms. Access to the Advertising Platforms is provided to the User as an additional service and we are not responsible for the content, actions or performance of the Advertising Platforms or any third party websites or services linked or referred to from such Advertising Platforms. The user expressly agrees to use any advertising platform at his/her own risk. Each advertising platform accessed through our platform is independent of us, and we have no control over the content, privacy policy and terms of use of such advertising platforms. Each advertising platform may have its own terms of use and privacy policies and the user should review the terms and policies, including privacy and data collection practices, before beginning or continuing to use them. In no event shall a reference to a third party product or advertising platform be construed as an endorsement or approval by us of that third party or of any product or service provided by that third party. You agree to comply with the terms and conditions of the applicable advertising platform.</p>
        <p>7.2 We may terminate the ability of any Advertising Platform to interact with the Platform at any time, with or without notice and at our sole discretion. Any Advertising Platform may at any time and without our intervention take measures to impair or prevent the functionality of the Platform. We are not responsible for reviewing any changes or updates or the quality, content, guidelines, nature or reliability of any Advertising Platform.</p>
        <p>8. User data</p>
        <p>The user hereby grants Doorboost the non-exclusive, worldwide, perpetual, royalty-free right to (a) provide the service and all related services to the user and to aggregate, use, copy, store derivative information about user data and the user data itself, (b) use aggregated data for our business purposes, including but not limited to developing, publishing and marketing benchmarks, predictive models, predictive analyses and actions based on aggregated data. Our rights with respect to aggregated data shall survive the expiration or termination of this Agreement. We are the sole owner of the aggregated data.</p>
        <p>9. contract duration - termination</p>
        <p>9.1 The duration of the contract results from the respective services used and the corresponding conditions or individual agreements for these. If no individual notice period or notice period recognisable from the special provisions has been agreed, ordinary notice of termination with a period of four weeks to the end of the month is possible.</p>
        <p>9.2 Notwithstanding the foregoing, the contract may be terminated prematurely by any party for good cause without notice, in particular if</p>
        <p>a) for Doorboost, if the user is in default of payment despite an appropriate limited reminder and/or the user uploads content contrary to I.4.1 or I.4.2,</p>
        <p>b) for both parties, if the other party violates an essential contractual obligation and/or insolvency proceedings are opened against the assets of the other party despite an appropriately limited reminder combined with a request for remedy.</p>
        <p>9.3 Any notice of termination or reminder must be given in writing (e-mail sufficient) in order to be effective.</p>
        <p>10. Other</p>
        <p>10.1 The language of the contract shall be German. Even if the contract text is to be translated into another language, the German contract text shall remain binding.</p>
        <p>10.2 German law shall apply to all legal relations between the user and us in addition to these provisions, in particular the service contract law of the German Civil Code, excluding the provisions of the Convention of 11.04.1980 on Contracts for the International Sale of Goods (CISG).</p>
        <p>10.3 The place of jurisdiction agreed with merchants shall be Doorboost's place of business; however, we shall also be entitled to institute legal proceedings at other statutory places of jurisdiction. Doorboost's place of business shall only be agreed as the place of jurisdiction with other users if the user concerned does not have a general place of jurisdiction in the Federal Republic of Germany.</p>
        <p>10.4 Should one or more of the contractual provisions be invalid or void, this shall not affect the validity of the remainder of the contract.</p>
        <p>II. special provisions for contracts with manufacturers</p>
        <p>1. Campaigns</p>
        <p>The manufacturer shall bear all costs for the creation and broadcasting of the campaigns, including any associated shipping costs, taxes and other related fees. The Manufacturer is responsible for all necessary licenses, approvals, authorizations and registrations required to create and deliver Campaigns. The Manufacturer undertakes to create, or have created, campaigns in a safe and professional manner and in accordance with legal requirements. The Manufacturer is solely responsible for compliance with all representations, warranties and/or guarantees made in connection with the Campaigns and for all user support, general support and warranty services for the Manufacturer's products. The manufacturer agrees to provide sufficient contact information for all customer enquiries, complaints or claims.</p>
        <p>2. Information for dealers</p>
        <p>The manufacturer shall inform the dealers about the terms of the framework agreement concluded with Doorboost and in particular about the remuneration to be paid by the manufacturer to Doorboost and in particular about the functioning of the platform and the provisions of these GTC.</p>
        <p>and draw their attention to the fact that use of the platform is only possible for dealers who accept these terms and conditions and the associated obligations.</p>
        <p>3. Remuneration of Doorboost</p>
        <p>3.1 Any merchant who has used the Platform within the last ninety (90) days shall be recorded; a "merchant account" shall mean a merchant who is granted access to the Platform. To the extent applicable and agreed for the use of the Platform, the number of Participating Dealers linked to the Manufacturer's Account shall be calculated on the last day of each calendar month (hereinafter referred to as the "Dealer Account").</p>
        <p>"Measurement date"). Unless otherwise agreed, the applicable subscription fee associated with the use of the Platform (the "Subscription Fee") will remain fixed during the applicable subscription period, unless the Manufacturer: (i) exceeds the maximum number of Participating Dealers specified in the Order Form; (ii) changes the Products or Base Packages specified in the Order Form by written notice; or (iii) orders additional features or services. If the Subscription Fee increases for any of the above reasons, it will remain in effect for the then-current Subscription Term, even if the number of Participating Merchants associated with the Account decreases.</p>
        <p>3.2 Invoices shall be issued in accordance with the respective order form. Unless otherwise stated in an order form, all invoices are due for payment within fourteen (14) days of the invoice date. All payments shall be made in Euro or US dollars, by cheque, credit card or bank transfer from the account specified by us in writing or by the credit card payment processor designated by us.</p>
        <p>3.3 The fees charged may include sales, usage, turnover, excise or similar taxes depending on the manufacturer's place of business. You will pay all such taxes on the basis of these terms. You will also reimburse any interest you may have on such taxes if they are not due to our delay.</p>
        <p>III. special conditions for traders</p>
        <p>1. Right of use</p>
        <p>A merchant is only entitled to use the platform if a manufacturer with whom he is contractually associated has concluded a contract with Doorboost and has been provided with access data and access authorisation. The merchant's access authorization always ends when the manufacturer is no longer authorized to use the platform. A separate termination by Doorboost is not required in such a case.</p>
        <p>2. Remuneration of Doorboost</p>
        <p>The remuneration is based on the terms of the manufacturer's framework agreement with Doorboost. These conditions can be found on the underlying order form. If additional services are booked (e.g. landing pages, additional advice, sales support, email dispatch), an additional fee may be due here, the amount of which is also based on the order form.</p>
      </div>
    </LayoutGrid1>
  )
};