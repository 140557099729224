import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { addDays, format } from "date-fns";
import {
  DateRange,
  DayPicker,
  SelectSingleEventHandler,
} from "react-day-picker";

const now = new Date();

import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";
import Loading from "../components/Loading";
import {
  Button,
  SectionTitle,
  Table,
  Form,
  Field,
  Input,
  ControlledSelect,
  ControlledReactSelect,
  Alert,
} from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import WithLoading from "../services/utils/WithLoading";
import { UserContext } from "../UserProvider";
import {
  getCampaign,
  getOrgs,
  getCampaignOrganizations,
  putCampaign,
  deleteCampaign,
  postCampaignOrganization,
  deleteCampaignOrganization,
  getSendReport,
} from "../services/campaigns";
import { getUsers } from "../services/users";
import { getCampaignReportSummary } from "../services/emails";
import CampaignReportSummary from "../components/CampaignReportSummary";
import { getBrands } from "../services/brands";
import { start } from "repl";

const TableWithLoading = WithLoading(Table);

export default function Campaign(props: any) {
  const history = useHistory();

  const [campaignId, setCampaignId] = useState<string>(props.match.params.id);
  const [campaign, setCampaign] = useState();
  const [organizations, setOrganizations] = useState();
  const [campaignOrganizations, setCampaignOrganizations] = useState();
  const [sendReportSuccess, setSendReportSuccess] = useState();
  const [campaignReportSummary, setCampaignReportSummary] = useState();
  const [errorStartDate, setErrorStartDate] = useState<string | null>(null);
  const [errorEndDate, setErrorEndDate] = useState<string | null>(null);

  const [getCampaignAsync, loadingCampaign, errorGetCampaign] = useAsync<
    typeof getCampaign
  >(
    (...params) => getCampaign(...params),
    (res) => {
      return setCampaign(res);
    }
  );

  const [getOrganizationsAsync, loadingOrgs, errorGetOrgs] = useAsync<
    typeof getOrgs
  >(
    (...params) => getOrgs(...params),
    (res) => {
      return setOrganizations(res);
    }
  );

  const [
    getCampaignOrganizationsAsync,
    loadingCampaignOrganizations,
    errorGetCampaignOrganizations,
  ] = useAsync<typeof getCampaignOrgs>(
    (...params) => getCampaignOrganizations(...params),
    (res) => {
      return setCampaignOrganizations(res);
    }
  );

  const [putCampaignAsync, loadingPutCampaign, errorPutCampaign] = useAsync<
    typeof putCampaign
  >(
    (...params) => putCampaign(...params),
    (res) => {
      return window.location.reload();
    }
  );

  const [deleteCampaignAsync, loadingDeleteCampaign, errorDeleteCampaign] =
    useAsync<typeof deleteCampaign>(
      (...params) => deleteCampaign(...params),
      (res) => {
        history.push(`/campaigns`);
      }
    );

  const [
    postCampaignOrganizationAsync,
    loadingPostCampaignOrganization,
    errorPostCampaignOrganization,
  ] = useAsync<typeof postCampaignOrganization>(
    (...params) => postCampaignOrganization(...params),
    (res) => {
      return getCampaignOrganizationsAsync({ id: campaignId });
    }
  );

  const [
    deleteCampaignOrganizationAsync,
    loadingDeleteCampaignOrganization,
    errorDeleteCampaignOrganization,
  ] = useAsync<typeof deleteCampaignOrganization>(
    (...params) => deleteCampaignOrganization(...params),
    (res) => {
      return getCampaignOrganizationsAsync({ id: campaignId });
    }
  );

  const [getSendReportAsync, loadingGetSendReport, errorGetSendReport] =
    useAsync<typeof getSendReport>(
      (...params) => getSendReport(...params),
      (res) => {
        return setSendReportSuccess(res);
      }
    );

  const [
    getCampaignReportSummaryAsync,
    loadingGetCampaignReportSummary,
    errorGetCampaignReportSummary,
  ] = useAsync<typeof getCampaignReportSummary>(
    (...params) => getCampaignReportSummary(...params),
    (res) => {
      setCampaignReportSummary(res);
    }
  );

  // getCampaignReportSummary

  const [brands, setBrands] = useState();
  const [getBrandsAsync, loadingBrands, errorGetBrands] = useAsync<
    typeof getBrands
  >(
    (...params) => getBrands(...params),
    (res) => {
      return setBrands(res);
    }
  );

  useEffect(() => {
    setCampaignId(props.match.params.id);
    getCampaignAsync({ id: campaignId });
    getOrganizationsAsync();
    getCampaignOrganizationsAsync({ id: campaignId });
    getBrandsAsync();
  }, []);

  const [selectedStartDate, setSelectedStartDate] = useState<Date>();

  const footerStartDate = selectedStartDate ? (
    <>
      {errorStartDate && <p style={{ color: "red" }}>{errorStartDate}</p>}
      <p>You selected {format(selectedStartDate, "PPP")}.</p>
    </>
  ) : (
    <>
      {errorStartDate && <p style={{ color: "red" }}>{errorStartDate}</p>}
      <p>Please pick a Start Date.</p>
    </>
  );

  const [selectedEndDate, setSelectedEndDate] = useState<Date>();

  const footerEndDate = selectedEndDate ? (
    <>
      {errorEndDate && <p style={{ color: "red" }}>{errorEndDate}</p>}
      <p>You selected {format(selectedEndDate, "PPP")}.</p>
    </>
  ) : (
    <>
      {errorEndDate && <p style={{ color: "red" }}>{errorEndDate}</p>}
      <p>Please pick a End Date.</p>
    </>
  );

  const handleStartDateSelect = (date: Date) => {
    if (selectedEndDate && date > selectedEndDate) {
      setErrorStartDate("Start date cannot be after end date");
    } else {
      setErrorStartDate(null);
      setSelectedStartDate(date);
    }
  };

  const handleEndDateSelect = (date: Date) => {
    if (selectedStartDate && date < selectedStartDate) {
      setErrorEndDate("End date cannot be before start date");
    } else {
      setErrorEndDate(null);
      setSelectedEndDate(date);
    }
  };

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        {campaign && brands && (
          <>
            <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
              {loadingCampaign && <Loading />}
              {!loadingCampaign && campaign && (
                <SectionTitle title={`${campaign.name}`} />
              )}

              {/* <Button
                variant="secondary"
                label="View Campaign Report"
                onClick={() => {
                  history.push(
                    `/dashboard.doorboost.com/campaigns/${campaign.id}`
                  );
                }}
              /> */}

              <a
                className="underline text-indigo-500"
                target="_blank"
                href={`https://dashboard.doorboost.com/campaigns/${campaign.id}`}
              >
                View Campaign Report
              </a>
            </div>

            <div className="bg-gray-100 p-5 border border-gray-200 rounded">
              <ActionSectionHeader name={`Edit ${campaign.name}`} />
              <Form
                onSubmit={(data) => {
                  const _currency =
                    typeof data.currency === "object"
                      ? data.currency.value
                      : data.currency;
                  const _goal =
                    typeof data.goal === "object" ? data.goal.value : data.goal;
                  const _status =
                    typeof data.status === "object"
                      ? data.status.value
                      : data.status;
                  const _visible =
                    typeof data.visible === "object"
                      ? data.visible.value
                      : data.visible;

                  const _hideMoney =
                    typeof data.hideMoney === "object"
                      ? data.hideMoney.value
                      : data.hideMoney;

                  const _brandValue =
                    typeof data.brand === "object"
                      ? data.brand.value
                      : data.brand;

                  // const _budget = typeof data.budget === "object" ?

                  let _startDate;
                  if (selectedStartDate) {
                    _startDate = format(selectedStartDate, "yyyy-MM-dd");
                  } else if (campaign.startDate) {
                    _startDate = campaign.startDate;
                  } else {
                    _startDate = null;
                  }

                  let _endDate;
                  if (selectedEndDate) {
                    _endDate = format(selectedEndDate, "yyyy-MM-dd");
                  } else if (campaign.endDate) {
                    _endDate = campaign.endDate;
                  } else {
                    _endDate = null;
                  }

                  console.log(data.budget);

                  putCampaignAsync({
                    id: campaignId,
                    name: data.name,
                    details: data.details || null,
                    landingPage: data.landingPage || null,
                    previewLink: data.previewLink || null,
                    endDate: _endDate || null,
                    startDate: _startDate || null,
                    facebookCampaignId: data.facebookCampaignId || null,
                    googleCampaignId: data.googleCampaignId || null,
                    googleCampaignSmartId: data.googleCampaignSmartId || null,
                    liCampaignId: data.liCampaignId || null,
                    factor: data.factor || null,
                    currency: _currency,
                    goal: _goal,
                    status: _status,
                    visible: _visible,
                    budget: data.budget,
                    hideMoney: _hideMoney,
                    brand_id: !!_brandValue ? _brandValue : campaign?.brand_id,
                  });
                }}
                defaultValues={campaign}
              >
                {({ register, control, errors }) => {
                  const _brands = brands.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  });
                  return (
                    <>
                      <Field label="Internal Id">
                        <Input
                          name="id"
                          type="text"
                          {...register("id")}
                          disabled
                        />
                      </Field>

                      <Field
                        label="Campaign Name"
                        invalid={!!errors.name}
                        error="Campaign Name is required."
                      >
                        <Input
                          name="name"
                          type="text"
                          {...register("name", {
                            required: true,
                          })}
                        />
                      </Field>

                      <Field
                        label="Visible to the User"
                        invalid={!!errors.visible}
                        error="Please select a true or false."
                      >
                        <ControlledSelect
                          name="visible"
                          control={control}
                          options={[
                            { id: "true", value: "true" },
                            { id: "false", value: "false" },
                          ]}
                          defaultSelected={
                            campaign.visible === false
                              ? { id: "false", value: "false" }
                              : {
                                  id: campaign.visible.toString(),
                                  value: campaign.visible.toString(),
                                }
                          }
                        />
                      </Field>

                      <Field
                        label="Hide Money - Cost and Budget"
                        invalid={!!errors.hideMoney}
                        error="Please select a true or false."
                      >
                        <ControlledSelect
                          name="hideMoney"
                          control={control}
                          options={[
                            { id: "true", value: "true" },
                            { id: "false", value: "false" },
                          ]}
                          defaultSelected={
                            campaign.hideMoney === false
                              ? { id: "false", value: "false" }
                              : {
                                  id: campaign.hideMoney.toString(),
                                  value: campaign.hideMoney.toString(),
                                }
                          }
                        />
                      </Field>

                      <Field
                        label="Status"
                        invalid={!!errors.status}
                        error="Please select a status."
                      >
                        <ControlledSelect
                          name="status"
                          control={control}
                          options={[
                            { id: "null", value: "null" },
                            { id: "active", value: "active" },
                            { id: "draft", value: "draft" },
                            { id: "pending", value: "pending" },
                            { id: "completed", value: "completed" },
                          ]}
                          defaultSelected={{
                            id: campaign.status,
                            value: campaign.status,
                          }}
                        />
                      </Field>

                      <Field
                        label="Facebook Id"
                        invalid={!!errors.facebookCampaignId}
                        error="Facebook Id."
                      >
                        <Input
                          name="facebookCampaignId"
                          type="text"
                          {...register("facebookCampaignId", {})}
                        />
                      </Field>

                      <Field
                        label="Google Id"
                        invalid={!!errors.googleCampaignId}
                        error="Google Id"
                      >
                        <Input
                          name="googleCampaignId"
                          type="text"
                          {...register("googleCampaignId", {})}
                        />
                      </Field>
                      <Field
                        label="Google Smart Campaign Id"
                        invalid={!!errors.googleCampaignSmartId}
                        error="Google Smart Campaign Id"
                      >
                        <Input
                          name="googleCampaignSmartId"
                          type="text"
                          {...register("googleCampaignSmartId", {})}
                        />
                      </Field>
                      <Field
                        label="LinkedIn Campaign Id"
                        invalid={!!errors.liCampaignId}
                        error="LinkedIn Campaign Id"
                      >
                        <Input
                          name="liCampaignId"
                          type="text"
                          {...register("liCampaignId", {})}
                        />
                      </Field>

                      <Field
                        label="Budget"
                        invalid={!!errors.budget}
                        error="Please Set a Budget"
                      >
                        <Input
                          name="budget"
                          type="text"
                          {...register("budget", {})}
                        />
                      </Field>

                      <Field
                        label="Goal"
                        invalid={!!errors.goal}
                        error="Please select a goal."
                      >
                        <ControlledSelect
                          name="goal"
                          control={control}
                          options={[
                            { id: "null", value: "null" },
                            { id: "leads", value: "leads" },
                            { id: "storeVisits", value: "storeVisits" },
                            { id: "sales", value: "sales" },
                            { id: "postEngagement", value: "postEngagement" },
                            { id: "webTraffic", value: "webTraffic" },
                            { id: "eventSignup", value: "eventSignup" },
                          ]}
                          defaultSelected={{
                            id: campaign.goal,
                            value: campaign.goal,
                          }}
                        />
                      </Field>

                      <Field
                        label="Currency"
                        invalid={!!errors.currency}
                        error="Please select a currency."
                      >
                        <ControlledSelect
                          name="currency"
                          control={control}
                          options={[
                            { id: "null", value: "null" },
                            { id: "usd", value: "usd" },
                            { id: "eur", value: "eur" },
                          ]}
                          defaultSelected={{
                            id: campaign.currency,
                            value: campaign.currency,
                          }}
                        />
                      </Field>

                      <Field
                        label="Factor"
                        invalid={!!errors.factor}
                        error="Factor"
                      >
                        <Input
                          name="factor"
                          type="number"
                          min="0"
                          max="2"
                          step="0.01"
                          {...register("factor")}
                        />
                      </Field>

                      <Field label="Selected Brand">
                        <Input
                          name="brandName"
                          type="text"
                          {...register("brandName")}
                          disabled
                        />
                      </Field>
                      <h3>Select a brand, to change it</h3>
                      <ControlledReactSelect
                        name="brand"
                        control={control}
                        options={_brands}
                        searchable
                      />

                      <Field
                        label="Details"
                        invalid={!!errors.details}
                        error="Details"
                      >
                        <Input
                          name="details"
                          type="text"
                          {...register("details", {})}
                        />
                      </Field>
                      <Field
                        label="landingPage"
                        invalid={!!errors.landingPage}
                        error="landingPage"
                      >
                        <Input
                          name="landingPage"
                          type="text"
                          {...register("landingPage", {})}
                        />
                      </Field>
                      <Field
                        label="previewLink"
                        invalid={!!errors.previewLink}
                        error="previewLink"
                      >
                        <Input
                          name="previewLink"
                          type="text"
                          {...register("previewLink", {})}
                        />
                      </Field>

                      <div className="bg-white p-4 flex flex-nowrap">
                        <div className="m-2">
                          <h3>Current Start Date {campaign.startDate}</h3>
                          <DayPicker
                            id="startDatePicker"
                            mode="single"
                            defaultMonth={
                              campaign.startDate
                                ? new Date(campaign.startDate)
                                : now
                            }
                            selected={
                              selectedStartDate
                                ? selectedStartDate
                                : new Date(campaign.startDate)
                            }
                            footer={footerStartDate}
                            onSelect={
                              handleStartDateSelect as SelectSingleEventHandler
                            }
                            captionLayout="dropdown"
                            fromYear={2019}
                            toYear={2026}
                          />
                        </div>
                        <div className="m-2">
                          <h3>Current End Date {campaign.endDate}</h3>
                          <DayPicker
                            id="startEndPicker"
                            mode="single"
                            defaultMonth={
                              campaign.endDate
                                ? new Date(campaign.endDate)
                                : now
                            }
                            selected={
                              selectedEndDate
                                ? selectedEndDate
                                : new Date(campaign.endDate)
                            }
                            footer={footerEndDate}
                            onSelect={
                              handleEndDateSelect as SelectSingleEventHandler
                            }
                            captionLayout="dropdown"
                            fromYear={2019}
                            toYear={2026}
                          />
                        </div>
                      </div>
                      <>
                        {errorPutCampaign ? (
                          <h6 style={{ color: "red" }}>
                            start date is bigger than end date
                          </h6>
                        ) : (
                          ""
                        )}
                      </>
                      <br />
                      <Button
                        variant="primary"
                        label="Update Campaign Details"
                        loading={loadingPutCampaign}
                      />
                    </>
                  );
                }}
              </Form>
            </div>

            <div className="bg-red-100 mt-5 p-5 border border-red-200 rounded">
              <ActionSectionHeader name={`Delete ${campaign.name} Campaign`} />
              <Button
                variant="pink"
                label="Delete"
                loading={loadingDeleteCampaign}
                onClick={() => {
                  deleteCampaignAsync({ id: campaignId });
                }}
              />
              <br />
            </div>
          </>
        )}

        <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
          <ActionSectionHeader
            name={`Select and Connect an Organization to this Campaign`}
          />
          {organizations && (
            <Form
              onSubmit={(data) => {
                postCampaignOrganizationAsync({
                  id: campaign.id,
                  org: data.organization.value,
                });
              }}
              defaultValues={organizations}
            >
              {({ register, control, errors }) => {
                const _organizations = organizations.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                });

                return (
                  <>
                    <Field
                      label="Organizations"
                      invalid={!!errors.organization}
                      error="Please select an organization."
                    >
                      <ControlledReactSelect
                        name="organization"
                        control={control}
                        options={_organizations}
                        defaultSelected={_organizations[0]}
                        searchable
                      />
                    </Field>

                    <br />
                    <Button
                      variant="primary"
                      label="Connect Organization"
                      loading={loadingPostCampaignOrganization}
                    />
                  </>
                );
              }}
            </Form>
          )}
          <br />
          <ActionSectionHeader
            name={`Organizations Connected to the Campaign`}
          />

          {campaignOrganizations && (
            <TableWithLoading
              isLoading={loadingCampaignOrganizations}
              columns={["Org Name", "Type", ""]}
              rows={
                campaignOrganizations &&
                campaignOrganizations.map((item: any) => {
                  return {
                    org: (
                      <Button
                        variant="secondary"
                        label={item.name}
                        onClick={() => {
                          history.push(`/orgs/${item.id}`);
                        }}
                      />
                    ),
                    type: item.type,
                    remove: (
                      <Button
                        variant="pink"
                        label="Remove"
                        onClick={() => {
                          deleteCampaignOrganizationAsync({
                            id: campaign.id,
                            org: item.id,
                          });
                        }}
                      />
                    ),
                  };
                })
              }
            />
          )}
        </div>

        {/* getCampaignReportSummaryAsync */}

        {/* <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
          <ActionSectionHeader name="Get Campaign Report" />

          <br />
          <Button
            label="Get Campaign Report"
            variant="primary"
            onClick={() => {
              getCampaignReportSummaryAsync({ id: campaign.id });
            }}
            loading={loadingGetCampaignReportSummary}
          />
          <br />

          {!loadingGetCampaignReportSummary &&
            !errorGetCampaignReportSummary &&
            campaignReportSummary && (
              <CampaignReportSummary item={campaignReportSummary} />
            )}
        </div> */}

        {/* <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
          <ActionSectionHeader name="Send Campaign Report" />

          <br/>
          {sendReportSuccess && <Alert type="success" message={sendReportSuccess} />}
          <Button label="Send Report Now"
            variant="primary"
            onClick={() => {
              getSendReportAsync({ id: campaign.id })
            }}
            loading={loadingGetSendReport}
          />
          <br/>
                    
        </div> */}
      </div>
    </LayoutGrid1>
  );
}
