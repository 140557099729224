import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActionSectionHeader from "../components/ActionSectionHeader";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import { addDays, format } from "date-fns";
import {
  DateRange,
  DayPicker,
  SelectSingleEventHandler,
} from "react-day-picker";

import Devider from "../components/Devider";
import {
  Button,
  SectionTitle,
  Table,
  Form,
  Field,
  Input,
  ControlledSelect,
  ControlledReactSelect,
  Alert,
} from "@mutable/meiosis";
import { getBrands } from "../services/brands";
import useAsync from "../services/utils/useAsync";

import { postCampaign } from "../services/campaigns";

// https://www.geeksforgeeks.org/how-to-upload-files-in-firebase-storage-using-reactjs/

export default function CampaignCreate(props: any) {
  const history = useHistory();
  const [errorStartDate, setErrorStartDate] = useState<string | null>(null);
  const [errorEndDate, setErrorEndDate] = useState<string | null>(null);

  const [postCampaignAsync, loadingPostCampaign, errorPostCampaign] = useAsync<
    typeof postCampaign
  >(
    (...params) => postCampaign(...params),
    (res) => {
      history.push(`/campaigns/${res.id}`);
    }
  );

  const [brands, setBrands] = useState();
  const [getBrandsAsync, loadingBrands, errorGetBrands] = useAsync<
    typeof getBrands
  >(
    (...params) => getBrands(...params),
    (res) => {
      return setBrands(res);
    }
  );

  useEffect(() => {
    getBrandsAsync();
  }, []);

  const [selectedStartDate, setSelectedStartDate] = useState<Date>();

  const footerStartDate = selectedStartDate ? (
    <>
      {errorStartDate && <p style={{ color: "red" }}>{errorStartDate}</p>}
      <p>You selected {format(selectedStartDate, "PPP")}.</p>
    </>
  ) : (
    <>
      {errorStartDate && <p style={{ color: "red" }}>{errorStartDate}</p>}
      <p>Please pick a Start Date.</p>
    </>
  );

  const [selectedEndDate, setSelectedEndDate] = useState<Date>();

  const footerEndDate = selectedEndDate ? (
    <>
      {errorEndDate && <p style={{ color: "red" }}>{errorEndDate}</p>}
      <p>You selected {format(selectedEndDate, "PPP")}.</p>
    </>
  ) : (
    <>
      {errorEndDate && <p style={{ color: "red" }}>{errorEndDate}</p>}
      <p>Please pick a End Date.</p>
    </>
  );

  const handleStartDateSelect = (date: Date) => {
    if (selectedEndDate && date > selectedEndDate) {
      setErrorStartDate("Start date cannot be after end date");
    } else {
      setErrorStartDate(null);
      setSelectedStartDate(date);
    }
  };

  const handleEndDateSelect = (date: Date) => {
    if (selectedStartDate && date < selectedStartDate) {
      setErrorEndDate("End date cannot be before start date");
    } else {
      setErrorEndDate(null);
      setSelectedEndDate(date);
    }
  };

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Create a New Campaign" />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {brands && (
            <Form
              onSubmit={(data) => {
                let _startDate;
                if (selectedStartDate) {
                  _startDate = format(selectedStartDate, "yyyy-MM-dd");
                } else if (campaign.startDate) {
                  _startDate = campaign.startDate;
                } else {
                  _startDate = null;
                }

                let _endDate;
                if (selectedEndDate) {
                  _endDate = format(selectedEndDate, "yyyy-MM-dd");
                } else if (campaign.endDate) {
                  _endDate = campaign.endDate;
                } else {
                  _endDate = null;
                }

                let _factor;
                if (data.factor && data.factor.value) {
                  _factor = data.factor.value;
                } else {
                  _factor = "1.25";
                }

                const _brandValue =
                  typeof data.brand === "object"
                    ? data.brand.value
                    : data.brand;

                postCampaignAsync({
                  name: data.name,
                  facebookCampaignId: data.facebookCampaignId,
                  googleCampaignId: data.googleCampaignId,
                  googleCampaignSmartId: data.googleCampaignSmartId,
                  liCampaignId: data.liCampaignId,
                  goal: data.goal.value,
                  currency: data.currency.value,
                  factor: _factor,
                  status: data.status.value,
                  visible: data.visible.value === "true" ? true : false,
                  budget: data.budget,
                  hideMoney: data.hideMoney.value === "true" ? true : false,
                  details: data.details,
                  landingPage: data.landingPage,
                  previewLink: data.previewLink,
                  endDate: _endDate || null,
                  startDate: _startDate || null,
                  brand_id: _brandValue || null,
                });
              }}
            >
              {({ register, control, errors }) => {
                const _brands = brands.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                });

                return (
                  <>
                    <Field
                      label="Campaign Name"
                      invalid={!!errors.name}
                      error="Campaign Name is required."
                    >
                      <Input
                        name="name"
                        type="text"
                        {...register("name", {
                          required: true,
                        })}
                      />
                    </Field>

                    <Field
                      label="Visible to the User"
                      invalid={!!errors.visible}
                      error="Please select a true or false."
                    >
                      <ControlledSelect
                        name="visible"
                        control={control}
                        options={[
                          { id: "true", value: "true" },
                          { id: "false", value: "false" },
                        ]}
                        defaultSelected={{ id: "true", value: "true" }}
                      />
                    </Field>
                    <Field
                      label="Hide Money - Cost and Budget"
                      invalid={!!errors.hideMoney}
                      error="Please select a true or false."
                    >
                      <ControlledSelect
                        name="hideMoney"
                        control={control}
                        options={[
                          { id: "true", value: "true" },
                          { id: "false", value: "false" },
                        ]}
                        defaultSelected={{ id: "false", value: "false" }}
                      />
                    </Field>

                    <Field
                      label="Status"
                      invalid={!!errors.status}
                      error="Please select a status."
                    >
                      <ControlledSelect
                        name="status"
                        control={control}
                        options={[
                          { id: "active", value: "active" },
                          { id: "draft", value: "draft" },
                          { id: "pending", value: "pending" },
                          { id: "completed", value: "completed" },
                        ]}
                        defaultSelected={{ id: "draft", value: "draft" }}
                      />
                    </Field>

                    <Field
                      label="Facebook Id"
                      invalid={!!errors.facebookCampaignId}
                      error="Facbook Id."
                    >
                      <Input
                        name="facebookCampaignId"
                        type="text"
                        {...register("facebookCampaignId", {})}
                      />
                    </Field>

                    <Field
                      label="Google Id"
                      invalid={!!errors.googleCampaignId}
                      error="Google Id"
                    >
                      <Input
                        name="googleCampaignId"
                        type="text"
                        {...register("googleCampaignId", {})}
                      />
                    </Field>

                    <Field
                      label="Googe Smart Id"
                      invalid={!!errors.googleCampaignSmartId}
                      error="Google Id"
                    >
                      <Input
                        name="googleCampaignSmartId"
                        type="text"
                        {...register("googleCampaignSmartId", {})}
                      />
                    </Field>

                    <Field
                      label="LinkedIn Id"
                      invalid={!!errors.liCampaignId}
                      error="LinkedIn Id"
                    >
                      <Input
                        name="liCampaignId"
                        type="text"
                        {...register("liCampaignId", {})}
                      />
                    </Field>

                    <Field
                      label="Budget"
                      invalid={!!errors.budget}
                      error="Please Set a Budget"
                    >
                      <Input
                        name="budget"
                        type="text"
                        {...register("budget", {})}
                      />
                    </Field>

                    <Field
                      label="Goal"
                      invalid={!!errors.goal}
                      error="Please select a goal."
                    >
                      <ControlledSelect
                        name="goal"
                        control={control}
                        rules={{ required: true }}
                        options={[
                          { id: "leads", value: "leads" },
                          { id: "sales", value: "sales" },
                          { id: "postEngagement", value: "postEngagement" },
                          { id: "webTraffic", value: "webTraffic" },
                          { id: "eventSignup", value: "eventSignup" },
                        ]}
                      />
                    </Field>

                    <Field
                      label="Currency"
                      invalid={!!errors.currency}
                      error="Please select a currency."
                    >
                      <ControlledSelect
                        name="currency"
                        control={control}
                        options={[
                          { id: "usd", value: "usd" },
                          { id: "eur", value: "eur" },
                        ]}
                        defaultSelected={{ id: "eur", value: "eur" }}
                      />
                    </Field>
                    <Field
                      label="Factor"
                      invalid={!!errors.factor}
                      error="Factor"
                    >
                      <ControlledSelect
                        name="factor"
                        type="number"
                        control={control}
                        options={[
                          { id: "option1", value: "1.25" },
                          { id: "option2", value: "1.1" },
                          { id: "option3", value: "1" },
                        ]}
                        defaultSelected={{ id: "option1", value: "1.25" }}
                        {...register("factor")}
                      />
                    </Field>
                    <h3>Select a brand.</h3>
                    <ControlledReactSelect
                      name="brand"
                      control={control}
                      options={_brands}
                      searchable
                      {...register("brand", {
                        required: true,
                      })}
                      invalid={!!errors.brand}
                    />
                    {!!errors.brand ? (
                      <h6 style={{ color: "red" }}>Brand is Required</h6>
                    ) : (
                      ""
                    )}
                    <Field
                      label="Details"
                      invalid={!!errors.details}
                      error="Details"
                    >
                      <Input
                        name="details"
                        type="text"
                        {...register("details", {})}
                      />
                    </Field>
                    <Field
                      label="Landingpage"
                      invalid={!!errors.landingPage}
                      error="Landingpage"
                    >
                      <Input
                        name="landingPage"
                        type="text"
                        {...register("landingPage", {})}
                      />
                    </Field>
                    <Field
                      label="previewLink"
                      invalid={!!errors.previewLink}
                      error="previewLink"
                    >
                      <Input
                        name="previewLink"
                        type="text"
                        {...register("previewLink", {})}
                      />
                    </Field>

                    <div className="bg-white p-4 flex flex-nowrap">
                      <div className="m-2">
                        <DayPicker
                          id="startDatePicker"
                          mode="single"
                          defaultMonth={new Date()}
                          selected={selectedStartDate}
                          footer={footerStartDate}
                          onSelect={
                            handleStartDateSelect as SelectSingleEventHandler
                          }
                          captionLayout="dropdown"
                          fromYear={2019}
                          toYear={2026}
                        />
                      </div>
                      <div className="m-2">
                        <DayPicker
                          id="startEndPicker"
                          mode="single"
                          defaultMonth={new Date()}
                          selected={selectedEndDate}
                          footer={footerEndDate}
                          onSelect={
                            handleEndDateSelect as SelectSingleEventHandler
                          }
                          captionLayout="dropdown"
                          fromYear={2019}
                          toYear={2026}
                        />
                      </div>
                    </div>

                    {/* <h3>Use this format 2022-04-30 00:00:00+00 </h3>
                    <Field
                      label="startDate"
                      invalid={!!errors.startDate}
                      error="startDate"
                    >
                      <Input
                        name="startDate"
                        type="text"
                        {...register("startDate", {})}
                      />
                    </Field>
                    <h3>Use this format 2022-04-30 00:00:00+00 </h3>
                    <Field
                      label="endDate"
                      invalid={!!errors.endDate}
                      error="endDate"
                    >
                      <Input
                        name="endDate"
                        type="text"
                        {...register("endDate", {})}
                      />
                    </Field> */}
                    <>
                      {errorPostCampaign ? (
                        <h6 style={{ color: "red" }}>
                          start date is bigger than end date
                        </h6>
                      ) : (
                        ""
                      )}
                    </>
                    <br />
                    <Button
                      variant="primary"
                      label="Create Campaign"
                      loading={loadingPostCampaign}
                    />
                  </>
                );
              }}
            </Form>
          )}
        </div>
      </div>
    </LayoutGrid1>
  );
}
