import {httpsCallable} from "firebase/functions";
import app from "../firebase";

const REGION =
  window.location.hostname == "localhost" ? "us-central1" : "europe-west3";

const functions = app.functions(REGION);

export function getBrands() {
  return httpsCallable(functions,'admin-brands-listBrands')()
    .then((result) => {
      return result.data;
    });
}

export function getBrand({id} : {id: string}) {
  return httpsCallable(functions,'admin-brands-getBrand')({id: id})
    .then((result) => {
      return result.data;
    });
}

export function searchBrands({term} : {term: string}) {
  return httpsCallable(functions,'admin-brands-searchBrands')({q: term})
    .then((result) => {
      return result.data;
    });
}

export function postBrand({
  name,
  description,
  website,
 } : {
  name: string,
  description: string,
  website: string,
 }) {
   return httpsCallable(functions,'admin-brands-createBrand')({
    name: name,
    description: description,
    website: website,
   })
     .then((result) => {
       return result.data;
     });  
 }

 export function putBrand({
  id,  
  name,
  description,
  website,
 } : {
  id: string,
  name: string,
  description: string,
  website: string,
 }) {
   return httpsCallable(functions,'admin-brands-updateBrand')({
     id: id,
     payload: {
      name: name,
      description: description,
      website: website,
     }
   })
     .then((result) => {
       return result.data;
     });  
 }

 export function deleteBrand({id} : {id: string}) {
  return httpsCallable(functions,'admin-brands-deleteBrand')({
    id: id,
  })
    .then((result) => {
      return result.data;
    });
}