import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useContext } from 'react';
import { UserContext } from "../UserProvider";
import StickyMenu from "../components/StickyMenu";

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const { isLoggedIn }: any = useContext(UserContext);
  
  return (isLoggedIn() ?
    <Route {...rest} render={
      props =>
        <>
          <StickyMenu />
          <Component {...rest} {...props} />
        </>
    } /> :
    <Redirect to="/login" />
  );
}

export default ProtectedRoute;