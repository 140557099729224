import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/firestore";

const config = {
  apiKey: "AIzaSyBGVayZg-g2J8sbMz__8E4zOuU8oYVcGMw",
  authDomain: "admin.doorboost.com",
  databaseURL: "https://doorboost-b3a57.firebaseio.com",
  projectId: "doorboost-b3a57",
  storageBucket: "doorboost-b3a57.appspot.com",
  messagingSenderId: "193494327233",
  appId: "1:193494327233:web:958d49b82beac7c30c9940",
  measurementId: "G-FQH21KBNE7"
};

const app = firebase.initializeApp(config);

if (window.location.hostname === "localhost") {  
  app.functions().useEmulator("localhost", 5001);
  app.auth().useEmulator("http://localhost:9099");
}

export default app;