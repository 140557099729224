import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../boilerplate/header";
import logo from "../public/doorboost-white.png";

export default function StickyMenu() {
  return (
    <Header>
      <>
        <NavLink
          to="/"
          className="px-3 py-6 text-white text-sm font-medium hover:text-pink-500 hover:opacity-80"
        >
          <img src={logo} alt="Logo" className="h-6 inline-flex" />
        </NavLink>
      </>
      <>
        <NavLink
          to="/campaigns"
          className="px-3 py-6 text-white text-sm font-medium hover:text-pink-500"
        >
          Campaigns
        </NavLink>
        <NavLink
          to="/brands"
          className="px-3 py-6 text-white text-sm font-medium hover:text-pink-500"
        >
          Brands
        </NavLink>
        <NavLink
          to="/orgs"
          className="px-3 py-6 text-white text-sm font-medium hover:text-pink-500"
        >
          Orgs
        </NavLink>
        <NavLink
          to="/users"
          className="px-3 py-6 text-white text-sm font-medium hover:text-pink-500"
        >
          Users
        </NavLink>
      </>
      <>
        <NavLink
          to="/logout"
          className="px-3 py-6 text-white text-sm font-medium hover:text-pink-500"
        >
          Logout
        </NavLink>
      </>
    </Header>
  );
}
