import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";
import {
  Table,
  Button,
  SectionTitle,
  Form,
  Field,
  Input,
  ControlledSelect,
  ControlledReactSelect,
  Alert,
} from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import WithLoading from "../services/utils/WithLoading";
import {
  getUser,
  putUser,
  deleteUser,
  listUserOrgs,
  addUserOrgs,
  removeUserOrgs,
} from "../services/users";
import {getOrgs} from "../services/orgs";
import {getBrands} from "../services/brands";

const TableWithLoading = WithLoading(Table);

export default function User(props: any) {
  const history = useHistory();

  const [userId, setUserId] = useState<string>(props.match.params.id);
  const [user, setUser] = useState();
  const [userOrgs, setUserOrgs] = useState();
  const [organizations, setOrgs] = useState();

  const [getUserAsync, loadingUser, errorGetUser] = useAsync<typeof getUser>(
    (...params) => getUser(...params),
    (res) => {
      return setUser(res);
    }
  );

  const [putUserAsync, loadingPutUser, errorPutUser] = useAsync<typeof putUser>(
    (...params) => putUser(...params),
    (res) => {
      return getUserAsync({id: userId});
    }
  );

  const [deleteUserAsync, loadingDeleteUser, errorDeleteUser] = useAsync<
    typeof deleteUser
  >(
    (...params) => deleteUser(...params),
    (res) => {
      history.push(`/users`);
    }
  );

  const [listUserOrgsAsync, loadingListUserOrgs, errorListUserOrgs] = useAsync<
    typeof listUserOrgs
  >(
    (...params) => listUserOrgs(...params),
    (res) => {
      return setUserOrgs(res);
    }
  );

  const [addUserOrgsAsync, loadingAddUserOrgs, errorAddUserOrgs] = useAsync<
    typeof addUserOrgs
  >(
    (...params) => addUserOrgs(...params),
    (res) => {
      return listUserOrgsAsync({id: userId});
    }
  );

  const [removeUserOrgsAsync, loadingRemoveUserOrgs, errorRemoveUserOrgs] =
    useAsync<typeof removeUserOrgs>(
      (...params) => removeUserOrgs(...params),
      (res) => {
        return listUserOrgsAsync({id: userId});
      }
    );

  const [listOrgsAsync, loadingListOrgs, errorListOrgs] = useAsync<
    typeof getOrgs
  >(
    (...params) => getOrgs(...params),
    (res) => {
      return setOrgs(res);
    }
  );

  const [brands, setBrands] = React.useState();
  const [getBrandsAsync, loadingBrands, errorGetBrands] = useAsync<
    typeof getBrands
  >(
    (...params) => getBrands(...params),
    (res) => {
      return setBrands(res);
    }
  );

  useEffect(() => {
    setUserId(props.match.params.id);
    getUserAsync({id: userId});
    listUserOrgsAsync({id: userId});
    listOrgsAsync();
    getBrandsAsync();
  }, []);

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        {user && (
          <>
            <SectionTitle title={`${user.email}`} />
            <div className="bg-gray-100 p-5 border border-gray-200 rounded">
              <ActionSectionHeader name={`Edit ${user.email}`} />
              {brands && (
                <Form
                  onSubmit={(data) => {
                    const _userLocale =
                      typeof data.locale === "object"
                        ? data.locale && data.locale.value
                        : data.locale;

                    const _brandValue =
                      typeof data.brand === "object"
                        ? data.brand && data.brand.value
                        : data.brand;

                    const _userType =
                      typeof data.userType === "object"
                        ? data.userType && data.userType.value
                        : data.userType;

                    putUserAsync({
                      id: userId,
                      firstName: data.firstName,
                      lastName: data.lastName,
                      email: data.email,
                      locale: _userLocale || "de",
                      phone: data.phone,
                      userType: _userType || "user",
                      brand_id: _brandValue || null,
                    });
                  }}
                  defaultValues={user}
                >
                  {({register, control, errors}) => {
                    const _brands = brands.map((item) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    });

                    return (
                      <>
                        <Field label="Internal User Id">
                          <Input
                            name="id"
                            type="text"
                            {...register("id")}
                            disabled
                          />
                        </Field>
                        <Field label="First Name" invalid={!!errors.firstName}>
                          <Input
                            name="firstName"
                            type="text"
                            {...register("firstName", {})}
                          />
                        </Field>
                        <Field label="Last Name" invalid={!!errors.lastName}>
                          <Input
                            name="lastName"
                            type="text"
                            {...register("lastName", {})}
                          />
                        </Field>
                        <Field label="Email" invalid={!!errors.email}>
                          <Input
                            name="email"
                            type="text"
                            {...register("email", {})}
                          />
                        </Field>
                        <Field
                          label="Locale"
                          invalid={!!errors.type}
                          error="Please select a locale."
                        >
                          <ControlledSelect
                            name="locale"
                            control={control}
                            options={[
                              {id: "de", value: "de"},
                              {id: "en", value: "en"},
                            ]}
                            defaultSelected={
                              user.locale
                                ? {id: user.locale, value: user.locale}
                                : {id: "de", value: "de"}
                            }
                          />
                        </Field>
                        <Field
                          label="User Type"
                          invalid={!!errors.type}
                          error="Please select a user type."
                        >
                          <ControlledSelect
                            name="userType"
                            control={control}
                            options={[
                              {id: "user", value: "user"},
                              {id: "brand", value: "brand"},
                              {id: "admin", value: "admin"},
                            ]}
                            defaultSelected={
                              user.userType
                                ? {id: user.userType, value: user.userType}
                                : {id: "user", value: "user"}
                            }
                          />
                        </Field>
                        <Field label="Selected Brand">
                          <Input
                            name="brandName"
                            type="text"
                            {...register("brandName")}
                            disabled
                          />
                        </Field>
                        <Field
                          label="Select a Brand"
                          invalid={!!errors.type}
                          error="Please select a locale."
                        >
                          <ControlledReactSelect
                            name="brand"
                            control={control}
                            options={_brands}
                            searchable
                          />
                        </Field>
                        <Field label="Phone" invalid={!!errors.phone}>
                          <Input
                            name="phone"
                            type="text"
                            {...register("phone", {})}
                          />
                        </Field>

                        <br />
                        <Button
                          variant="primary"
                          label="Update"
                          loading={loadingPutUser}
                        />
                      </>
                    );
                  }}
                </Form>
              )}
            </div>

            <div className="bg-red-100 mt-5 p-5 border border-red-200 rounded">
              <ActionSectionHeader name={`Delete ${user.email}`} />
              <Button
                variant="pink"
                label="Delete"
                loading={loadingDeleteUser}
                onClick={() => {
                  deleteUserAsync({id: userId});
                }}
              />
            </div>
          </>
        )}

        <br />

        <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
          <ActionSectionHeader name={`Admin Access`} />
          {user && (
            <div>
              {user.userType && (
                <>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="userType"
                      id="userType"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      value={user.userType}
                      disabled
                    />
                  </div>
                  <br />
                </>
              )}
            </div>
          )}
        </div>

        <br />

        <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
          <ActionSectionHeader
            name={`Select and Connect an Organization to this User`}
          />
          {organizations && (
            <Form
              onSubmit={(data) => {
                addUserOrgsAsync({
                  id: userId,
                  orgId: data.organization.value,
                });
              }}
              defaultValues={organizations}
            >
              {({register, control, errors}) => {
                const _organizations = organizations.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                });

                return (
                  <>
                    <Field
                      label="Organizations"
                      invalid={!!errors.organization}
                      error="Please select an organization."
                    >
                      <ControlledReactSelect
                        name="organization"
                        control={control}
                        options={_organizations}
                        searchable
                      />
                    </Field>

                    <br />
                    <Button
                      variant="primary"
                      label="Connect Organization"
                      loading={loadingAddUserOrgs}
                    />
                  </>
                );
              }}
            </Form>
          )}
          <br />

          <ActionSectionHeader name={`Organizations this User is Part Of`} />
          {userOrgs && (
            <TableWithLoading
              isLoading={loadingListUserOrgs}
              columns={["Org Name", ""]}
              rows={
                userOrgs &&
                userOrgs.map((item: any) => {
                  return {
                    name: (
                      <Button
                        variant="secondary"
                        label={item.name}
                        onClick={() => {
                          history.push(`/orgs/${item.id}`);
                        }}
                      />
                    ),
                    remove: (
                      <Button
                        variant="pink"
                        label="Remove"
                        onClick={() => {
                          removeUserOrgsAsync({id: userId, orgId: item.id});
                        }}
                      />
                    ),
                  };
                })
              }
            />
          )}
        </div>
      </div>
    </LayoutGrid1>
  );
}
