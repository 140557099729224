// import { Spinner } from '@mutable/meiosis';
import { Icon } from '@mutable/meiosis';
import React from 'react';

export function joinClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
export interface SpinnerProps {
  color?: string;
  backgroundColor?: string;
  size?: number;
  fullHeight?: boolean;
}

export const Spinner: React.FC<SpinnerProps & React.HTMLAttributes<HTMLDivElement>> = ({ fullHeight = true, color = "gray-400", backgroundColor = "white", size = 6, className, ...props }) => (
  <div className={joinClassNames(`bg-${backgroundColor} overflow-hidden flex justify-center`, fullHeight ? "h-screen" : "mt-20", className)} {...props}>
    <div className="animate-spin m-auto">
      <Icon type="spinner" size={size} className={`text-${color}`} />
    </div>
  </div>
);

export default function WithLoading(Component) {

  return function WihLoadingComponent({ isLoading, ...props }) {

    if (!isLoading) return (<Component {...props} />);

    return (<Spinner fullHeight={false} />);
  }
}
