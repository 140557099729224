import { httpsCallable } from "firebase/functions";
import app from "../firebase";

const REGION =
  window.location.hostname == "localhost" ? "us-central1" : "europe-west3";

const functions = app.functions(REGION);

export function getCampaigns() {
  return httpsCallable(functions, "admin-campaigns-listCampaigns")().then(
    (result) => {
      return result.data;
    }
  );
}

export function getBrandRetailers({ brand_id }: { brand_id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-getBrandRetailers"
  )({ brandId: brand_id }).then((result) => {
    return result.data;
  });
}

export function getBrandCampaigns({ brand_id }: { brand_id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-getBrandCampaigns"
  )({ brandId: brand_id }).then((result) => {
    return result.data;
  });
}

export function getBrandSummary({ brand_id }: { brand_id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-getBrandSummary"
  )({ brandId: brand_id }).then((result) => {
    return result.data;
  });
}

export function searchCampaigns({ term }: { term: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-searchCampaigns"
  )({ q: term }).then((result) => {
    return result.data;
  });
}

export function getCampaign({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-getCampaign"
  )({ campId: id }).then((result) => {
    return result.data;
  });
}

export function getCampaignSummary({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-getCampaignSummary"
  )({ campId: id }).then((result) => {
    return result.data;
  });
}

export function getCampaignInsights({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-getCampaignInsights"
  )({ campId: id }).then((result) => {
    return result.data;
  });
}

export function getCampaignAdSets({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-getCampaignAdSets"
  )({ campId: id }).then((result) => {
    return result.data;
  });
}

export function getCampaignAds({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-getCampaignAds"
  )({ campId: id }).then((result) => {
    return result.data;
  });
}

export function getCampaignLeads({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-getCampaignLeads"
  )({ campId: id }).then((result) => {
    return result.data;
  });
}

export function getOrgs() {
  return httpsCallable(functions, "admin-orgs-listOrgs")().then((result) => {
    return result.data;
  });
}

export function getCampaignOrganizations({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-listCampaignOrgs"
  )({ campId: id }).then((result) => {
    return result.data;
  });
}

export function postCampaign({
  name,
  facebookCampaignId,
  googleCampaignId,
  googleCampaignSmartId,
  liCampaignId,
  goal,
  currency,
  factor,
  status,
  visible,
  budget,
  details,
  landingPage,
  previewLink,
  endDate,
  startDate,
  brand_id,
  hideMoney,
}: {
  name: string;
  facebookCampaignId: string;
  googleCampaignId: string;
  googleCampaignSmartId: string;
  liCampaignId: string;
  goal: string;
  currency: string;
  factor: number;
  status: string;
  visible: boolean;
  budget: number;
  details: string;
  landingPage: string;
  previewLink: string;
  endDate: Date;
  startDate: Date;
  brand_id: string;
  hideMoney: boolean;
}) {
  // admin-campaigns-createCampaign
  return httpsCallable(
    functions,
    "admin-campaigns-createCampaign"
  )({
    payload: {
      name: name,
      facebookCampaignId: facebookCampaignId,
      googleCampaignId: googleCampaignId,
      googleCampaignSmartId: googleCampaignSmartId,
      liCampaignId: liCampaignId,
      goal: goal,
      currency: currency,
      status: status,
      factor: factor,
      visible: visible,
      budget: budget,
      details: details,
      landingPage: landingPage,
      previewLink: previewLink,
      endDate: endDate,
      startDate: startDate,
      brand_id: brand_id,
      hideMoney: hideMoney,
    },
  }).then((result) => {
    return result.data;
  });
}

export function postCampaigns({ campaigns }: { campaigns: array }) {
  return httpsCallable(
    functions,
    "admin-campaigns-createCampaigns"
  )({ campaigns: campaigns }).then((result) => {
    return result.data;
  });
}

export function putCampaign({
  id,
  name,
  facebookCampaignId,
  googleCampaignId,
  googleCampaignSmartId,
  liCampaignId,
  goal,
  currency,
  factor,
  status,
  visible,
  details,
  landingPage,
  previewLink,
  endDate,
  startDate,
  brand_id,
  budget,
  hideMoney,
}: {
  id: string;
  name: string;
  facebookCampaignId: string;
  googleCampaignId: string;
  googleCampaignSmartId: string;
  liCampaignId: string;
  goal: string;
  currency: string;
  factor: number;
  status: string;
  visible: boolean;
  details: string;
  landingPage: string;
  previewLink: string;
  endDate: Date;
  startDate: Date;
  brand_id: string;
  budget: number;
  hideMoney: boolean;
}) {
  // admin-campaigns-updateCampaign
  return httpsCallable(
    functions,
    "admin-campaigns-updateCampaign"
  )({
    campId: id,
    payload: {
      id: id,
      name: name,
      facebookCampaignId: facebookCampaignId,
      googleCampaignId: googleCampaignId,
      googleCampaignSmartId: googleCampaignSmartId,
      liCampaignId: liCampaignId,
      goal: goal,
      currency: currency,
      factor: factor,
      status: status,
      visible: visible,
      details: details,
      landingPage: landingPage,
      previewLink: previewLink,
      endDate: endDate,
      startDate: startDate,
      brand_id: brand_id,
      budget: budget,
      hideMoney: hideMoney,
    },
  }).then((result) => {
    return result.data;
  });
}

// admin-campaigns-deleteCampaign
export function deleteCampaign({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-campaigns-deleteCampaign"
  )({
    campId: id,
  }).then((result) => {
    return result.data;
  });
}

// admin-orgs-addOrgCampaign
export function postCampaignOrganization({
  id,
  org,
  userId,
}: {
  id: string;
  org: string;
  userId: string;
}) {
  return httpsCallable(
    functions,
    "admin-orgs-addOrgCampaign"
  )({
    campId: id,
    id: org, // org id
  }).then((result) => {
    return result.data;
  });
}

// admin-orgs-removeOrgCampaign
export function deleteCampaignOrganization({
  id,
  org,
}: {
  id: string;
  org: string;
}) {
  return httpsCallable(
    functions,
    "admin-orgs-removeOrgCampaign"
  )({
    campId: id,
    id: org,
  }).then((result) => {
    return result.data;
  });
}

export function getSendReport({ id }: { id: string }) {
  return httpsCallable(
    functions,
    "admin-emails-sendEmailReport"
  )({ campId: id }).then((result) => {
    return result.data;
  });
}
