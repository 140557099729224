import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";

import { Button, SectionTitle, DataTable, Alert } from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import { getCampaigns, searchCampaigns } from "../services/campaigns";

export default function Campaigns(props: any) {
  const history = useHistory();

  const [campaigns, setCampaigns] = useState([]);
  const [errorSearch, setErrorSearch] = useState([]);

  const [getCampaignsAsync, loadingCampaigns, errorGetCampaigns] = useAsync<
    typeof getCampaigns
  >(
    (...params) => getCampaigns(...params),
    (res) => {
      return setCampaigns(res);
    }
  );

  const [searchCampaignsAsync, loadingSearchCampaigns, errorSearchCampaigns] =
    useAsync<typeof searchCampaigns>(
      (...params) => searchCampaigns(...params),
      (res) => {
        return setCampaigns(res);
      }
    );

  useEffect(() => {
    getCampaignsAsync();
  }, []);

  const onSearch = (query) => {
    setErrorSearch([]);
    if (query.term.length >= 3) {
      searchCampaignsAsync(query);
    } else if (query.term.length === 0) {
      getCampaignsAsync();
    } else {
      setErrorSearch(["Please type more than 3 characters"]);
    }
  };

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Campaigns" />
        <div className="bg-green-100 p-5 border border-green-200 rounded">
          <ActionSectionHeader name={`Create a New Campaign`} />
          <Button
            variant="white"
            label="Create"
            onClick={() => {
              history.push(`/create/campaign`);
            }}
          />
        </div>
        <br />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {errorSearch && errorSearch.length > 0 && (
            <Alert type="error" message={errorSearch} />
          )}
          {errorGetCampaigns && (
            <Alert type="error" message={errorGetCampaigns} />
          )}
          <DataTable
            isLoading={loadingCampaigns || loadingSearchCampaigns}
            columns={[
              {
                id: "name",
                display: "Name",
                type: "string",
                searchable: true,
                sortable: true,
              },
              {
                id: "visible",
                display: "Visible",
                type: "string",
                sortable: true,
              },
              {
                id: "status",
                display: "Status",
                type: "string",
                sortable: true,
              },
              { id: "goal", display: "Goal", type: "string", sortable: true },
              {
                id: "factor",
                display: "Factor",
                type: "string",
                sortable: true,
              },
              { id: "edit", display: "Edit", type: "custom" },
              { id: "report", display: "Report", type: "custom" },
            ]}
            rows={
              campaigns &&
              campaigns.map((item: any) => {
                return {
                  id: item.id,
                  name: item.name,
                  visible: item.visible.toString(),
                  status: item.status,
                  goal: item.goal,
                  factor: item.factor,
                  edit: (
                    <Button
                      variant="secondary"
                      label="Edit"
                      onClick={() => {
                        history.push(`/campaigns/${item.id}`);
                      }}
                    />
                  ),
                  report: (
                    <Button
                      variant="secondary"
                      label="Report"
                      onClick={() => {
                        history.push(`/campaigns/${item.id}/report`);
                      }}
                    />
                  ),
                };
              })
            }
            onRowClick={(item, index) => {
              history.push(`/campaigns/${item.id}`);
            }}
            onSearch={onSearch}
          />
        </div>
      </div>
    </LayoutGrid1>
  );
}
