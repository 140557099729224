import React, { useContext } from "react";
import { Icon } from "@mutable/meiosis";

export default function Loading(props) {
  return (
    <div className="flex m-2">
      <Icon src="spinner" className={"animate-spin -ml-1 mr-2"} aria-hidden="true" />
      {props.message ? props.message : 'Loading...'}
    </div>
  )
};