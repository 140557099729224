import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../boilerplate/header";
import logo from "../public/doorboost-white.png";


export default function StickyMenuLoggedOut() {  
  return (
    <Header>
      <>        
        <NavLink to="/" className="px-3 py-6 text-white text-sm font-medium hover:text-pink-500 hover:opacity-80">
          <img src={logo} alt="Logo" className="h-6 inline-flex"/>
        </NavLink>
      </>
      <>        
      </>      
      <>
        <NavLink to="/" className="px-3 py-6 text-white text-sm font-medium hover:text-pink-500">
          Login
        </NavLink>
      </>    
    </Header>
  )
};