import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";

import {Button, SectionTitle, DataTable, Alert} from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import {getMessages, getTemplates} from "../services/emails";

export default function Emails(props: any) {
  const history = useHistory();

  const [emails, setEmails] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [listMessagesAsync, loadingListMessages, errorListMessages] = useAsync<
    typeof getMessages
  >(
    (...params) => getMessages(...params),
    (res) => {
      return setEmails(res);
    }
  );

  const [listTemplatesAsync, loadingListTemplates, errorListTemplates] =
    useAsync<typeof getTemplates>(
      (...params) => getTemplates(...params),
      (res) => {
        return setTemplates(res);
      }
    );

  useEffect(() => {
    listMessagesAsync();
    listTemplatesAsync();
  }, []);

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Emails" />
        <br />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {errorListMessages && (
            <Alert type="error" message={errorListMessages} />
          )}
          {emails && (
            <DataTable
              isLoading={loadingListMessages}
              columns={[
                {
                  id: "subject",
                  display: "Subject",
                  type: "string",
                  searchable: true,
                  sortable: true,
                },
                {
                  id: "to_email",
                  display: "To",
                  type: "string",
                  searchable: true,
                  sortable: true,
                },
                {
                  id: "status",
                  display: "Status",
                  type: "string",
                  searchable: false,
                  sortable: true,
                },
                {
                  id: "opens_count",
                  display: "Open Count",
                  type: "string",
                  searchable: false,
                  sortable: true,
                },
                {
                  id: "last_event_time",
                  display: "Time Stamp",
                  type: "string",
                  searchable: false,
                  sortable: true,
                },
              ]}
              rows={
                emails &&
                emails.map((item: any) => {
                  return {
                    subject: item.subject,
                    to_email: item.to_email,
                    status: item.status,
                    opens_count: item.opens_count,
                    last_event_time: item.last_event_time,
                  };
                })
              }
              onRowClick={(item, index) => {
                history.push(`/emails/${item.id}`);
              }}
            />
          )}
        </div>
        <br />
        <SectionTitle title="Templates" />
        <br />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {errorListTemplates && (
            <Alert type="error" message={errorListTemplates} />
          )}
          {templates && (
            <DataTable
              isLoading={loadingListTemplates}
              columns={[
                {
                  id: "id",
                  display: "ID",
                  type: "string",
                  searchable: true,
                  sortable: true,
                },
                {
                  id: "name",
                  display: "Name",
                  type: "string",
                  searchable: true,
                  sortable: true,
                },
              ]}
              rows={
                templates &&
                templates.map((item: any) => {
                  return {
                    id: item.id,
                    name: item.name,
                  };
                })
              }
            />
          )}
        </div>
      </div>
    </LayoutGrid1>
  );
}
