import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";
import {
  Table,
  Button,
  SectionTitle,
  Form,
  Field,
  Input,
  ControlledReactSelect,
} from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import WithLoading from "../services/utils/WithLoading";
import { getBrand, putBrand, deleteBrand } from "../services/brands";

const TableWithLoading = WithLoading(Table);

export default function Brand(props: any) {
  const history = useHistory();

  const [brandId, setBrandId] = useState<string>(props.match.params.id);
  const [brand, setBrand] = useState();
  const [brandOrgs, setBrandOrgs] = useState();
  const [orgs, setOrgs] = useState();

  const [getBrandAsync, loadingGetBrand, errorGetBrand] = useAsync<
    typeof getBrand
  >(
    (...params) => getBrand(...params),
    (res) => {
      return setBrand(res);
    }
  );

  const [putBrandAsync, loadingPutBrand, errorPutBrand] = useAsync<
    typeof putBrand
  >(
    (...params) => putBrand(...params),
    (res) => {
      return getBrandAsync({ id: brandId });
    }
  );

  const [deleteBrandAsync, loadingDeleteBrand, errorDeleteBrand] = useAsync<
    typeof deleteBrand
  >(
    (...params) => deleteBrand(...params),
    (res) => {
      history.push(`/brands`);
    }
  );

  useEffect(() => {
    setBrandId(props.match.params.id);
    getBrandAsync({ id: brandId });
  }, []);

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        {brand && (
          <>
            <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
              <SectionTitle title={`${brand.name}`} />

              <Button
                variant="secondary"
                label="View Campaigns"
                onClick={() => {
                  history.push(`/brands/${brandId}/campaigns`);
                }}
              />

              <Button
                className="mx-5"
                variant="secondary"
                label="View Retailers"
                onClick={() => {
                  history.push(`/brands/${brandId}/retailers`);
                }}
              />
            </div>

            <div className="bg-gray-100 p-5 border border-gray-200 rounded">
              <ActionSectionHeader name={`Edit ${brand.name}`} />
              <Form
                onSubmit={(data) => {
                  putBrandAsync({
                    id: data.id,
                    name: data.name,
                    description: data.description,
                    website: data.website,
                  });
                }}
                defaultValues={brand}
              >
                {({ register, control, errors }) => {
                  return (
                    <>
                      <Field
                        label="Name"
                        invalid={!!errors.name}
                        error="Name is required."
                      >
                        <Input
                          name="name"
                          type="text"
                          {...register("name", {
                            required: true,
                          })}
                        />
                      </Field>
                      <Field label="Description" invalid={!!errors.description}>
                        <Input
                          name="description"
                          type="text"
                          {...register("description", {})}
                        />
                      </Field>
                      <Field label="Website" invalid={!!errors.website}>
                        <Input
                          name="website"
                          type="text"
                          {...register("website", {})}
                        />
                      </Field>

                      <br />
                      <Button
                        variant="primary"
                        label="Update"
                        loading={loadingPutBrand}
                      />
                    </>
                  );
                }}
              </Form>
            </div>

            <div className="bg-red-100 mt-5 p-5 border border-red-200 rounded">
              <ActionSectionHeader name={`Delete ${brand.name} Brand`} />
              <Button
                variant="pink"
                label="Delete"
                loading={loadingDeleteBrand}
                onClick={() => {
                  deleteBrandAsync({ id: brandId });
                }}
              />
              <br />
            </div>
          </>
        )}
      </div>
    </LayoutGrid1>
  );
}
