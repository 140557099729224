import React from "react";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import { SectionTitle } from "@mutable/meiosis";
import StickyMenuLoggedOut from "../components/StickyMenuLoggedOut";

export default function TosDe(props: any) {
  return (
    <LayoutGrid1>
      <StickyMenuLoggedOut />
      <SectionTitle title="Allgemeine Geschäftsbedingungen von Doorboost" />
      <div>
        <ol start={1}>
          <li>Allgemeines</li>
        </ol>
        <ol start={1}>
          <li>Vertragspartner, Geltungsbereich, Definitionen</li>
        </ol>
        <ol start={1}>
          <li>Vertragspartner ist die Doorboost GmbH, Friedrichstrasse 171, 10117 Berlin (nachfolgend:</li>
        </ol>
        <p>„Doorboost“ oder „wir“ oder „uns“).</p>
        <ol start={2}>
          <li>Es gelten ausschließlich unsere Geschäftsbedingungen; entgegenstehende oder von unseren Geschäftsbedingungen abweichende Bedingungen erkennen wir nicht an, es sei denn, wir stimmen schriftlich deren Geltung zu. Unsere Geschäftsbedingungen gelten auch dann, wenn wir unsere Leistungen in Kenntnis entgegenstehender oder von unseren Geschäftsbedingungen abweichender Bedingungen vorbehaltlos ausführen. Unsere Geschäftsbedingungen gelten ebenfalls für alle künftigen Geschäfte.</li>
        </ol>
        <ol start={3}>
          <li>Hersteller sind alle Unternehmen, die unter einer oder mehreren Marken Produkte herstellen und diese durch und über Vertriebspartner insbesondere an Verbraucher verkaufen.</li>
        </ol>
        <ol start={4}>
          <li>Händler ist ein Unternehmen mit einem einzelnen Standort mit nur einer physischen Adresse der in Vertragsbeziehungen zum Hersteller steht und u.a. dessen Produkte verkauft.</li>
        </ol>
        <ol start={5}>
          <li>Nutzer sind alle Unternehmen, an die sich das Leistungsangebot richtet, also Hersteller und Händler.</li>
        </ol>
        <ol start={6}>
          <li>Plattform ist die von uns unter doorboost.com angebotene Internetplattform einschließlich sämtlicher Funktionen, über die wir unsere Dienstleistungen anbieten und erbringen.</li>
        </ol>
        <ol start={7}>
          <li>Kampagnen sind Online-Werbekampagnen von Herstellern zur Bewerbung ihrer Produkte.</li>
        </ol>
        <ol start={8}>
          <li>Content sind alle vom Hersteller und/oder Händler entweder selbst oder durch beauftragte Dritte oder durch Doorboost im Auftrag des Herstellers oder Händlers erstellten Inhalte (insbesondere Texte, Fotografien, Bilder, Grafiken, Zeichnungen, Videos, Filme, Musik, Töne) für einzelne Kampagnen.</li>
        </ol>
        <ol start={9}>
          <li>Benutzerdaten sind alle Daten oder Inhalte, die Nutzer hochladen, übermitteln oder anderweitig auf oder über die Plattform übertragen.</li>
        </ol>
        <p>1.10 Aggregierte Daten sind Benutzerdaten, die (i) anonymisiert sind, so dass sie keine einzelne Person identifizieren, und (ii) mit den Daten anderer Benutzer oder zusätzlichen Datenquellen kombiniert werden.</p>
        <ol start={2}>
          <li>Leistungen von Doorboost</li>
        </ol>
        <ol start={10}>
          <li>Doorboost bietet über die Plattform den Zugang zu Funktionen und Services, die dem Hersteller helfen, Online-Marketing Inhalte zu entwickeln und diese mit Händler zu teilen. In einigen Fällen beinhaltet dies Kampagnen, die es den Händlern ermöglichen, an den Inhalten und/oder Kampagnen des Herstellers zu partizipieren, sie anzupassen und sie mit Verbrauchern zu teilen (einschließlich über</li>
        </ol>
        <p>Online-Kanäle wie Facebook, Google, Landingpages, E-Mail und/oder Plattformen anderer Drittanbieter). Die Leistungen von Doorboost bestehen lediglich in der Erbringung von Dienstleistungen; ein bestimmter Erfolg ist nicht geschuldet. Soweit möglich, steht Doorboost darüber hinaus für Beratungsdienstleistungen bei der Erstellung oder Anpassung von Kampagnen zur Verfügung.</p>
        <ol start={11}>
          <li>Wir bieten als Teil unserer Hauptleistungspflicht im Rahmen der technischen und betrieblichen Möglichkeiten von uns und unserem Provider eine mittlere Verfügbarkeit der Plattform von 99% im Monat. Wir sind in diesem Rahmen zur Abschaltung der Plattform zu Zwecken der Aktualisierung oder technischen Überprüfung und Wartung berechtigt. Der Nutzer willigt in alle – auch kurzfristigen und unangekündigten – aus technischen Gründen erforderlichen oder nützlichen Abschaltungen ein. Wir verpflichten uns im Gegenzug, solche Abschaltungen nur in Zeiten vorzunehmen, während der der Abruf von Daten nur gering ist.</li>
        </ol>
        <ol start={12}>
          <li>Die Service-Verfügbarkeitszeit wird als 100% abzüglich der Ausfallzeit berechnet, wobei die Ausfallzeit aus der Gesamtzahl der Minuten pro Monat besteht, in denen eine benutzerzugängliche Anwendung für 15 oder mehr aufeinanderfolgende Minuten für Nutzer nicht verfügbar ist, geteilt durch die Anzahl der verfügbaren Minuten in einem solchen Monat, wobei die geplante Ausfallzeit ausgeschlossen ist. Berechnung wie folgt:</li>
        </ol>
        <p>Serviceverfügbarkeit (in Minuten) = Geplante Serviceverfügbarkeit – (Geplante Ausfallzeit + Ungeplante Ausfallzeit)</p>
        <p>Serviceverfügbarkeit (in Prozent) = (Serviceverfügbarkeit (in Minuten) / (Geplante Serviceverfügbarkeit (in Minuten) – Geplante Ausfallzeit (in Minuten)) * 100%</p>
        <p>Wenn die Ursache für die Nichtverfügbarkeit nicht ermittelt werden kann oder bestritten wird, wird eine gemeinsame Überprüfung mit dem Nutzer und allen anderen beteiligten Drittanbietern (falls erforderlich) durchgeführt, um sich gemeinsam auf eine angemessene Aufteilung der Verantwortung für die Nichtverfügbarkeit auf die entsprechende(n) Partei(en) zu einigen. Die Ergebnisse der Prüfung der Serviceverfügbarkeit sind allen Parteien transparent zugänglich zu machen.</p>
        <p>Für den Fall, dass die für den Benutzer zugänglichen Anwendungen eine Verfügbarkeit von weniger als 99% in einem bestimmten Monat aufweisen, hat der Benutzer Anspruch auf ein</p>
        <p>Service-Verfügbarkeitsguthaben von Doorboost, wie in der untenstehenden Tabelle definiert. Die Höhe des Service-Verfügbarkeitsguthabens richtet sich nach dem Betrag der aktuellen monatlichen Plattformgebühr, wie sie zwischen Doorboost und dem Benutzer vereinbart wurde. Im Falle eines gewährten Service-Verfügbarkeitsguthabens wird dieses dem Benutzer innerhalb des darauffolgenden Monats gutgeschrieben, in dem der Serviceausfall eingetreten ist. Die Gutschrift wird mit der nächsten monatlichen Plattformgebühr verrechnet, oder im Falle eines endenden Vertrags auf das normalerweise zur Zahlung der monatlichen Plattformgebühr verwendete Konto überwiesen.</p>
        <p>Servicegutschrift/Rückerstattung. Wenn die tatsächliche Serviceverfügbarkeit für den Service in einem bestimmten Kalendermonat geringer ist als die Service-Verfügbarkeitsgarantie, erhält der Benutzer eine Gutschrift in Höhe eines Prozentsatzes der monatlichen Plattformgebühr für diesen Monat gemäß der folgenden Tabelle:</p>
        <p>&nbsp;</p>
        <table>
          <tbody>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>Service- verfügbarkeit größer oder gleich:</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>Service- verfügbarkeit kleiner als:</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>Service-Verfügbarkeitsguthaben (Prozentsatz der monatlichen Plattformgebühr)</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>95%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>98.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>5%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>90%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>94.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>10%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>85%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>89.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>15%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>80%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>84.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>20%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>75%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>79.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>25%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>50%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>74.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>50%</p>
              </td>
            </tr>
            <tr>
              <td colSpan={1} rowSpan={1}>
                <p>0%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>49.9%</p>
              </td>
              <td colSpan={1} rowSpan={1}>
                <p>100%</p>
              </td>
            </tr>
          </tbody>
        </table>
        <ol start={13}>
          <li>Soweit von uns Leistungen unentgeltlich angeboten werden, sind wir jederzeit und ohne vorherige Ankündigung berechtigt, diese Leistungen zu ändern, zu beschränken oder nicht mehr anzubieten. Unentgeltliche Leistungen stellen keine vertragswesentlichen Pflichten unsererseits dar.</li>
        </ol>
        <ol start={3}>
          <li>Technische Voraussetzungen des Nutzers</li>
        </ol>
        <ol start={14}>
          <li>Für die vertragsgemäße Nutzung unserer Inhalte wird ein Webbrowser benötigt. Zur Betrachtung der auf der Website enthaltenen Dokumenten im pdf-Format wird der Acrobat-Reader der Fa. Adobe benötigt.</li>
        </ol>
        <ol start={15}>
          <li>Im Übrigen benötigt der Nutzer einen eigenen Provider für eine Internet-Verbindung. Für Fehler oder Ausfallzeiten seines Providers ist ausschließlich der Nutzer verantwortlich.</li>
        </ol>
        <ol start={4}>
          <li>Content von Nutzern</li>
        </ol>
        <ol start={16}>
          <li>Für sämtlichen Content des Nutzers, für dessen Richtigkeit und rechtliche Zulässigkeit, trägt allein der Nutzer die Verantwortung. Der Nutzer sichert insbesondere zu, dass der von ihm eingestellte Content nicht Rechte Dritter, insbesondere Urheber-, Persönlichkeits-, Design- und/oder Markenrechte Dritter verletzt und nicht gegen rechtsgültige, insbesondere wettbewerbsrechtliche und/oder strafrechtliche und/oder datenschutzrechtliche Vorschriften verstößt.</li>
        </ol>
        <ol start={17}>
          <li>Sofern der Nutzer Content einstellt, so verpflichtet er sich, ausschließlich der Wahrheit entsprechende Angaben zu machen.</li>
        </ol>
        <ol start={18}>
          <li>Wir sind berechtigt, aber nicht verpflichtet, eingestellten Content auf die Beeinträchtigung entsprechender Rechte Dritter oder auf die Verletzung allgemeiner Rechtsvorschriften (insbesondere des UWG oder Strafvorschriften) hin zu überprüfen. Bis zu einer Klärung von Streitigkeiten darüber, ob Content Rechte Dritter oder allgemeine Rechtsvorschriften verletzt, sind wir stets berechtigt, Content zu entfernen oder zu sperren. Soweit der Nutzer gegen die Verpflichtungen aus vorstehender Ziff. 4.1. oder Ziff. 4.2. verstößt, ist der Nutzer verpflichtet, uns von etwaigen Ansprüchen Dritter freizustellen, die aus dieser Pflichtverletzung herrühren. Hiervon umfasst sind auch etwaige Geldstrafen, Bußgelder oder Kosten, die im Zusammenhang mit einer Strafverfolgung stehen sowie unsere etwaigen gerichtlichen und außergerichtlichen Kosten im Rahmen einer zivilrechtlichen Streitigkeit. Die Parteien werden sich stets bei der Abwehr von Ansprüchen Dritter bestmöglich unterstützen.</li>
        </ol>
        <ol start={5}>
          <li>Weitere Pflichten von Nutzern</li>
        </ol>
        <ol start={19}>
          <li>Der Nutzer darf keine Daten auf der Plattform speichern oder hochladen, die nach ihrer Art oder Beschaffenheit (z.B. Viren, beschädigte Dateien, "Trojanische Pferde" oder andere kontaminierende oder zerstörerische Merkmale), Größe oder Vervielfältigung (z.B. Spamming) geeignet sind, den Bestand oder Betrieb des Rechenzentrums oder Datennetzes von uns oder von uns beauftragten Dritten zu gefährden. Der Nutzer wird keine Maßnahmen ergreifen, die eine unangemessene oder unverhältnismäßig große Belastung unserer Infrastruktur darstellen oder Schäden verursachen oder das Funktionieren unserer Systeme oder Serviceleistungen stören könnten.</li>
          <li>Sofern der vom Nutzer eingestellte Content urheberrechtlich, markenrechtlich oder durch sonstige Schutzrechte geschützt ist, räumt er uns ein einfaches Nutzungsrecht für die Wiedergabe des geschützten Inhalts während der Vertragsdauer ein, insbesondere das entsprechende Vervielfältigungs-, Verbreitungs-, Bearbeitungsrecht und das Recht zur öffentlichen Zugänglichmachung.</li>
        </ol>
        <ol start={21}>
          <li>Der Nutzer ist verpflichtet, bei der Benutzung von Passwörtern, Benutzernamen oder anderen Sicherheitsvorrichtungen, die im Zusammenhang mit unseren Leistungen zur Verfügung gestellt werden, größtmögliche Sorgfalt walten zu lassen und sicherzustellen, dass deren Bekanntgabe an Dritte verhindert wird. Der Nutzer steht für Schäden ein, die aufgrund eines unberechtigten Gebrauchs von Passwörtern durch unbefugte Dritte entstehen.</li>
        </ol>
        <ol start={22}>
          <li>Der Nutzer wird keine unzulässige Werbung wie Kettenbriefe und Pyramidenschemata veröffentlichen und verbreiten.</li>
        </ol>
        <ol start={6}>
          <li>Aufrechnung und Zurückbehaltungsrecht</li>
        </ol>
        <ol start={23}>
          <li>Mit etwaigen Gegenforderungen kann nur aufgerechnet werden, wenn die Gegenforderungen unbestritten oder rechtskräftig festgestellt sind.</li>
        </ol>
        <ol start={24}>
          <li>Ein Nutzer kann nur wegen etwaigen Gegenforderungen seine Leistung verweigern oder sie zurückbehalten, wenn die Gegenforderungen unbestritten oder rechtskräftig festgestellt sind.</li>
        </ol>
        <ol start={7}>
          <li>Webseiten von Drittanbietern</li>
        </ol>
        <ol start={25}>
          <li>Die Plattform und die Dienstleistungen von uns beinhalten Systeme, die es dem Nutzer unter anderem ermöglichen, den Zugriff und die Veröffentlichung von Inhalten auf Werbeplattformen oder Werbesystemen (z.B. Facebook, Google, Instagram, Emails, sonstige Webseiten und Plattformen von Dritten) zu verwalten und den Zugriff auf solche Webseiten und Services zu ermöglichen, die von Dritten kontrolliert werden (nachfolgend alle "Werbeplattform"). Der Nutzer erteilt uns hiermit alle erforderlichen Berechtigungen, um in seinem Namen auf diese Werbeplattformen zuzugreifen, um ihm unsere Plattform zur Verfügung zu stellen, einschließlich der Nutzung und Analyse der von den Werbeplattformen zur Verfügung gestellten Informationen. Der Zugriff auf die Werbeplattformen wird dem Nutzer als Zusatzleistung zur Verfügung gestellt, und wir sind nicht verantwortlich für Inhalt, Handlungen oder der Leistung der Werbeplattformen oder von Webseiten oder Services Dritter, die mit diesen Werbeplattformen verbunden oder verlinkt sind. Der Nutzer stimmt ausdrücklich zu, jede Werbeplattform auf eigenes Risiko zu nutzen. Jede Werbeplattform, auf die über unsere Plattform zugegriffen wird, ist unabhängig von uns, und wir haben keine Kontrolle über den Inhalt, die Datenschutzerklärung und die Nutzungsbedingungen dieser Werbeplattformen. Jede Werbeplattform kann eigene Nutzungsbedingungen und Datenschutzrichtlinien haben und der Nutzer sollte die Bedingungen und Richtlinien, einschließlich der Datenschutz- und Datenerfassungspraktiken überprüfen, bevor er mit der Nutzung beginnt oder fortfährt. In keinem Fall darf ein Verweis auf ein Produkt eines Drittanbieters oder</li>
        </ol>
        <p>eine Werbeplattform eines Drittanbieters als Zustimmung oder Billigung dieses Drittanbieters oder eines von diesem Drittanbieter zur Verfügung gestellten Produkts oder einer Serviceleistung durch uns ausgelegt werden. Sie erklären sich damit einverstanden, die Bedingungen der jeweiligen Werbeplattform einzuhalten.</p>
        <ol start={26}>
          <li>Wir können die Fähigkeit einer Werbeplattform mit der Plattform zu interagieren jederzeit, mit oder ohne Vorankündigung und nach eigenem Ermessen, beenden. Jede Werbeplattform kann jederzeit und ohne unser Zutun Maßnahmen ergreifen, um die Funktionsfähigkeit der Plattform zu beeinträchtigen oder zu verhindern. Wir übernehmen keine Verantwortung für die Überprüfung von Änderungen oder Aktualisierungen oder der Qualität, des Inhalts, der Richtlinien, der Art oder der Zuverlässigkeit einer Werbeplattform.</li>
        </ol>
        <ol start={8}>
          <li>Benutzerdaten</li>
        </ol>
        <p>Der Nutzer gewährt Doorboost hiermit das nicht ausschließliche, weltweite, zeitlich unbeschränkte, unentgeltliche Recht, (a) den Service und alle damit verbundenen Serviceleistungen für den Nutzer zu erbringen und abgeleitete Informationen zu Benutzerdaten sowie die Benutzerdaten selbst zu aggregieren, zu verwenden, zu kopieren, zu speichern, zu übertragen, zu verteilen, anzuzeigen, öffentlich vorzuführen, und zu ändern (b) aggregierte Daten für unsere Geschäftszwecke zu verwenden, einschließlich, aber nicht beschränkt auf die Entwicklung, Veröffentlichung und Vermarktung von Benchmarks, Vorhersagemodellen, Vorhersageanalysen und Maßnahmen auf der Grundlage aggregierter Daten. Unsere Rechte in Bezug auf aggregierte Daten bleiben auch nach Ablauf oder Beendigung dieser Vereinbarung bestehen. Wir sind alleiniger Inhaber der aggregierten Daten.</p>
        <ol start={9}>
          <li>Vertragsdauer – Kündigung</li>
        </ol>
        <ol start={27}>
          <li>Die Dauer des Vertrages ergibt sich aus den jeweiligen in Anspruch genommenen Leistungen und den entsprechenden Bedingungen bzw. individuellen Vereinbarungen hierfür. Ist keine individuelle oder aus den besonderen Bestimmungen heraus erkennbare Kündigunsfrist vereinbart, so ist eine ordentliche Kündigung mit einer Frist von vier Wochen zum Monatsende möglich.</li>
        </ol>
        <ol start={28}>
          <li>Unbeschadet dessen kann der Vertrag von jedem Teil vorzeitig aus wichtigem Grund fristlos gekündigt werden, der insbesondere vorliegt</li>
        </ol>
        <ol start={1}>
          <li>für Doorboost, wenn der Nutzer trotz angemessenen befristeter Mahnung in Zahlungsverzug gerät und/oder der Nutzer entgegen Ziff. I.4.1. bzw. I.4.2. Inhalte einstellt,</li>
        </ol>
        <ol start={2}>
          <li>für beide Teile, wenn der jeweils andere Teil trotz angemessen befristeter Mahnung, verbunden mit der Aufforderung zur Abhilfe, eine wesentliche Vertragsverpflichtung verletzt und/oder über das Vermögen des anderen Teils ein Insolvenzverfahren eröffnet wird.</li>
        </ol>
        <ol start={1}>
          <li>Jede Kündigung oder Mahnung hat, um wirksam zu sein, schriftlich (E-Mail ausreichend) zu erfolgen.</li>
        </ol>
        <ol start={10}>
          <li>Sonstiges</li>
        </ol>
        <ol start={2}>
          <li>Die Vertragssprache ist deutsch. Auch wenn der Vertragstext in eine andere Sprache übersetzt werden sollte, bleibt der deutsche Vertragstext verbindlich.</li>
        </ol>
        <ol start={3}>
          <li>Für alle Rechtsbeziehungen zwischen dem Nutzer und uns gilt in Ergänzung zu diesen Bestimmungen deutsches Recht, insbesondere das Dienstvertragsrecht des BGB, unter Ausschluss der Bestimmungen des Übereinkommens vom 11.04.1980 über Verträge über den internationalen Warenkauf (CISG).</li>
        </ol>
        <ol start={4}>
          <li>Mit Kaufleuten wird als Gerichtsstand der Geschäftssitz von Doorboost vereinbart; wir sind jedoch berechtigt, auch an anderen gesetzlichen Gerichtsständen Klage zu erheben. Mit anderen Nutzern wird der Geschäftssitz von Doorboost nur dann als Gerichtsstand vereinbart, wenn der betreffende Nutzer keinen allgemeinen Gerichtsstand in der Bundesrepublik Deutschland hat.</li>
        </ol>
        <ol start={5}>
          <li>Sollte eine oder mehrere der Vertragsbestimmungen unwirksam oder nichtig sein, wird dadurch die Geltung des Vertrags im Übrigen nicht berührt.</li>
        </ol>
        <ol start={2}>
          <li>Besondere Bestimmungen für Verträge mit Herstellern</li>
        </ol>
        <ol start={1}>
          <li>Kampagnen</li>
        </ol>
        <p>Der Hersteller trägt alle Kosten für die Erstellung und Ausstrahlung der Kampagnen einschließlich der damit eventuell verbundenen Versandkosten, Steuern und sonstigen damit verbundenen Gebühren. Der Hersteller ist verantwortlich für alle notwendigen Lizenzen, Genehmigungen, Autorisierungen und Registrierungen, die erforderlich sind, um Kampagnen zu erstellen und bereitzustellen. Der Hersteller verpflichtet sich, Kampagnen auf sichere und professionelle Weise und in Übereinstimmung mit gesetzlichen Vorgaben zu erstellen, bzw. erstellen zu lassen. Der Hersteller ist alleine verantwortlich für die Einhaltung aller Erklärungen, Zusagen und/oder Garantien, die er im Zusammenhang mit den Kampagnen macht, sowie für alle Nutzerunterstützung, generelle Unterstützung und Garantieleistungen für Produkte des Herstellers. Der Hersteller erklärt sich damit einverstanden, ausreichende Kontaktinformationen für alle Kundenanfragen, Beschwerden oder Ansprüche zur Verfügung zu stellen.</p>
        <ol start={2}>
          <li>Informationen für Händler</li>
        </ol>
        <p>Der Hersteller wird die Händler über die Bedingungen des Rahmenvertrags, den er mit Doorboost abgeschlossen hat und insbesondere über die von ihm an Doorboost zu entrichtende Vergütung und insbesondere über auf die Funktionsweise der Plattform und die Bestimmungen dieser AGB hinweisen</p>
        <p>und diese darauf aufmerksam machen, dass eine Nutzung der Plattform nur für Händler möglich ist, die diese Bedingungen und die damit verbunden Pflichten akzeptieren.</p>
        <ol start={3}>
          <li>Vergütung von Doorboost</li>
        </ol>
        <ol start={1}>
          <li>Erfasst wird jeder Händler, der innerhalb der letzten neunzig (90) Tage die Plattform genutzt hat; ein „Händleraccount“ ist ein Händler, dem Zugriff auf die Plattform gewährt wird. In dem für die Nutzung der Plattform geltenden und vereinbarten Umfang wird die Anzahl der mit dem Konto des Herstellers verknüpften teilnehmenden Händler am letzten Tag jedes Kalendermonats berechnet (im Folgenden</li>
        </ol>
        <p>„Messdatum“). Sofern nichts anderes vereinbart ist, bleibt die anwendbare Abonnementgebühr, die im Zusammenhang mit der Nutzung der Plattform steht (die „Abonnementgebühr“), während der jeweiligen Abonnementlaufzeit fest, es sei denn, der Hersteller: (i) überschreitet die im Bestellformular angegebene maximalen Anzahl teilnehmender Händler (ii) ändert durch schriftliche Mitteilung die Produkte oder Basispakete, die im Bestellformular angegeben sind, oder (iii) bestellt zusätzliche Funktionen oder Serviceleistungen. Wenn sich die Abonnementgebühr aus den zuvor angegebenen Gründen erhöht, bleibt diese über die dann gültige Abonnementlaufzeit bestehen, auch wenn sich die Anzahl der mit dem Konto verbundenen teilnehmenden Händler reduziert.</p>
        <ol start={2}>
          <li>Die Rechnungsstellung erfolgt gemäß dem jeweiligen Bestellformular. Sofern in einem Bestellformular nichts anderes angegeben ist, sind alle Rechnungen innerhalb von vierzehn (14) Tagen nach Rechnungsdatum zur Zahlung fällig. Alle Zahlungen erfolgen in Euro oder US-Dollar, per Scheck, Kreditkarte oder Banküberweisung von auf das von uns schriftlich angegebene Konto, bzw. durch den von uns bestimmten Kreditkarten-Zahlungsmittelverarbeiter.</li>
        </ol>
        <ol start={3}>
          <li>Die in Rechnung gestellten Gebühren können je nach Geschäftssitz des Herstellers Verkaufs-, Nutzungs-, Umsatz-, Verbrauchssteuer oder ähnliche Steuern beinhalten. Sie werden alle diese Steuern auf der Grundlage dieser Bedingungen zahlen. Zudem werden Sie alle eventuell anfallenden Zinsen auf diese Steuern erstatten, wenn diese nicht auf unsere Verzögerung zurückzuführen sind.</li>
        </ol>
        <ol start={3}>
          <li>Besondere Bedingungen für Händler</li>
        </ol>
        <ol start={1}>
          <li>Nutzungsberechtigung</li>
        </ol>
        <p>Ein Händler ist nur zur Nutzung der Plattform berechtigt, wenn ein Hersteller, mit dem er vertraglich verbunden ist, einen Vertrag mit Doorboost abgeschlossen hat und ihm Zugangsdaten und eine Zugangsberechtigung übermittelt wurden. Die Zugangsberechtigung des Händlers endet stets, wenn der Hersteller nicht mehr zur Nutzung der Plattform berechtigt ist. Eine gesonderte Kündigung durch Doorboost bedarf es in einem solchen Fall nicht.</p>
        <ol start={2}>
          <li>Vergütung von Doorboost</li>
        </ol>
        <p>Die Vergütung richtet sich nach den Bedingungen des Rahmenvertrags des Herstellers mit Doorboost. Diese Bedingungen sind dem zugrundeliegenden Bestellformular zu entnehmen. Sofern Zusatzservices gebucht werden (z.B. Landingpages, zusätzliche Beratung, Vertriebsunterstützung, Emailversand), wird hier eventuell eine zusätzliche Vergütung fällig, deren Höhe sich ebenfalls nach dem Bestellformular richtet.</p>
      </div>
    </LayoutGrid1>
  )
};