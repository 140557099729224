import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";

import {Button, SectionTitle, DataTable, Alert} from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import {listScheduledItems, listScheduledItemsLogs} from "../services/schedule";

export default function ScheduledItems(props: any) {
  const history = useHistory();

  const [scheduledItems, setScheduledItems] = useState([]);

  const [
    listScheduledItemsAsync,
    loadingListScheduledItems,
    errorListScheduledItems,
  ] = useAsync<typeof listScheduledItems>(
    (...params) => listScheduledItems(...params),
    (res) => {
      return setScheduledItems(res);
    }
  );
  const [scheduledItemsLogs, setScheduledItemsLogs] = useState([]);
  const [
    listScheduledItemsLogsAsync,
    loadingListScheduledItemsLogs,
    errorListScheduledItemsLogs,
  ] = useAsync<typeof listScheduledItemsLogs>(
    (...params) => listScheduledItemsLogs(...params),
    (res) => {      
      return setScheduledItemsLogs(res);
    }
  );

  useEffect(() => {
    listScheduledItemsAsync();
    listScheduledItemsLogsAsync();
  }, []);

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Scheduled Items" />
        <div className="bg-green-100 p-5 border border-green-200 rounded">
          <ActionSectionHeader name={`Create a New Scheduled Item`} />
          <Button
            variant="white"
            label="Create"
            onClick={() => {
              history.push(`/create/schedule`);
            }}
          />
        </div>
        <br />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {errorListScheduledItems && (
            <Alert type="error" message={errorListScheduledItems} />
          )}
          <DataTable
            isLoading={loadingListScheduledItems}
            columns={[
                            
              {
                id: "campaignName",
                display: "Campaign Name",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "status",
                display: "Status",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "frequency",
                display: "frequency",
                type: "string",
                searchable: false,
                sortable: true,
              },
              {
                id: "dateTime",
                display: "Date/Time",
                type: "string",
                searchable: false,
                sortable: true,
              },              
              {
                id: "emails",
                display: "emails",
                type: "string",
                searchable: false,
                sortable: false,
              },
            ]}
            rows={
              scheduledItems &&
              scheduledItems.map((item: any) => {
                let dateTime = item.dayOfWeek ? `${item.dayOfWeek} - ` : "";
                dateTime += item.timeOfDay ? `${item.timeOfDay}` : "";
                return {
                  id: item.id,                                    
                  campaignName: item.campaignName,
                  status: item.status,
                  frequency: item.frequency,
                  dateTime: dateTime,
                  emails:
                    item.emails && item.emails.length
                      ? item.emails.map((email) => {
                          return (
                            <div className="my-2" key={email}>
                              <Button
                                variant="white"
                                label={email}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                          );
                        })
                      : "",
                  edit: (
                    <Button
                      variant="secondary"
                      label="Edit"
                      onClick={() => {
                        history.push(`/scheduled/${item.id}`);
                      }}
                    />
                  ),
                };
              })
            }
            onRowClick={(item, index) => {
              window.open(`/scheduled/${item.id}`);
            }}
          />
        </div>
      </div>
    </LayoutGrid1>
  );
}
