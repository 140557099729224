import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import {
  Button,
  SectionTitle,
  Form,
  Field,
  Input,
  ControlledSelect,
  ControlledReactSelect,
} from "@mutable/meiosis";

import useAsync from "../services/utils/useAsync";
import {getCampaigns} from "../services/campaigns";
import {getTemplates} from "../services/emails";
import {getUsers} from "../services/users";
import {createScheduledItem} from "../services/schedule";

export default function ScheduledItemsCreate(props: any) {
  const history = useHistory();

  const [campaigns, setCampaigns] = useState();
  const [templates, setTemplates] = useState();

  const [getCampaignsAsync, loadingGetCampaigns, errorGetCampaigns] = useAsync<
    typeof getCampaigns
  >(
    (...params) => getCampaigns(...params),
    (res) => {
      return setCampaigns(res);
    }
  );

  const [getTemplatesAsync, loadingGetTemplates, errorGetTemplates] = useAsync<
    typeof getTemplates
  >(
    (...params) => getTemplates(...params),
    (res) => {
      return setTemplates(res);
    }
  );

  const [emails, setEmails] = useState([]);
  const [getEmailsAsync, loadingGetEmails, errorGetEmails] = useAsync<
    typeof getUsers
  >(
    (...params) => getUsers(...params),
    (res) => {
      return setEmails(res);
    }
  );

  const [emailsListSelected, setEmailsListSelected] = useState<string>("");
  const [emailsSelected, setEmailsSelected] = useState<string[]>([]);

  const [
    createScheduledItemAsync,
    loadingCreateScheduledItem,
    errorCreateScheduledItem,
  ] = useAsync<typeof createScheduledItem>(
    (...params) => createScheduledItem(...params),
    (res) => {
      history.push(`/scheduled`);
    }
  );

  useEffect(() => {
    getCampaignsAsync();
    getTemplatesAsync();
    getEmailsAsync();
  }, []);

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Create a Scheduled Item" />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {campaigns && templates && (
            <Form
              onSubmit={(data) => {
                let timeOfDay = "";
                if (data.itemType.id === "report") {
                  timeOfDay += data.timeOfDayTime.value;
                  timeOfDay += data.timeOfDayTimeZone.id;
                }

                createScheduledItemAsync({
                  campaignId: data.campaign?.value ? data.campaign.value : null,
                  campaignName: data.campaign?.label
                    ? data.campaign.label
                    : null,
                  frequency: data.frequency.id,
                  dayOfWeek: data.dayOfWeek.id,
                  timeOfDay: timeOfDay,
                  itemType: data.itemType.id,
                  emailTemplate: data.emailTemplate?.value
                    ? data.emailTemplate.value
                    : null,
                  emailTemplateName: data.emailTemplate?.label
                    ? data.emailTemplate.label
                    : null,
                  emailTemplateNoInteraction: data.emailTemplateNoInteraction
                    ?.value
                    ? data.emailTemplateNoInteraction.value
                    : null,
                  emailTemplateNoInteractionName: data
                    .emailTemplateNoInteraction?.label
                    ? data.emailTemplateNoInteraction.label
                    : null,
                  emails: emailsSelected,
                  subject: data.subject,
                });
              }}
            >
              {({register, control, errors}) => {
                const _campaigns = campaigns.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                });

                const _templates = templates.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                });

                return (
                  <>
                    <Field
                      label="Scheduled Report Email Subject"
                      invalid={!!errors.name}
                      error="Subject is required."
                    >
                      <Input
                        name="subject"
                        type="text"
                        {...register("subject", {
                          required: true,
                        })}
                      />
                    </Field>
                    <Field
                      label="Item Type"
                      invalid={!!errors.itemType}
                      error="Please select an Item Type."
                    >
                      <ControlledSelect
                        name="itemType"
                        control={control}
                        options={[
                          {id: "report", value: "Report"},
                          {id: "csv", value: "CSV"},
                        ]}
                        defaultSelected={{id: "report", value: "Report"}}
                      />
                    </Field>
                    <Field
                      label="Day of Week"
                      invalid={!!errors.dayOfWeek}
                      error="Please select a a day."
                    >
                      <ControlledSelect
                        name="dayOfWeek"
                        control={control}
                        options={[
                          {id: "monday", value: "Monday"},
                          {id: "tuesday", value: "Tuesday"},
                          {id: "wednesday", value: "Wednesday"},
                          {id: "thursday", value: "Thursday"},
                          {id: "friday", value: "Friday"},
                          {id: "saturday", value: "Saturday"},
                          {id: "sunday", value: "Sunday"},
                        ]}
                        defaultSelected={{id: "monday", value: "Monday"}}
                      />
                    </Field>

                    <Field
                      label="Time for the report to be sent."
                      invalid={!!errors.timeOfDayTime}
                      error="Please select a Time."
                    >
                      <ControlledSelect
                        name="timeOfDayTime"
                        control={control}
                        options={[
                          {id: "0900", value: "09:00"},                          
                          {id: "1000", value: "10:00"},                          
                          {id: "1100", value: "11:00"},                          
                          {id: "1200", value: "12:00"},                          
                          {id: "1300", value: "13:00"},                          
                        ]}
                        defaultSelected={{id: "1000", value: "10:00"}}
                      />
                    </Field>
                    <Field
                      label="Time Zone"
                      invalid={!!errors.timeOfDayTimeZone}
                      error="Please select a Time Zone."
                    >
                      <ControlledSelect
                        name="timeOfDayTimeZone"
                        control={control}
                        options={[                          
                          {
                            id: "+01",
                            value: "CET	Central European Time	MEZ	UTC +1",
                          },                          
                        ]}
                        defaultSelected={{
                          id: "+01",
                          value: "CET	Central European Time	MEZ	UTC +1",
                        }}
                      />
                    </Field>
                    <Field
                      label="Frequency"
                      invalid={!!errors.frequency}
                      error="Please select a Frequency."
                    >
                      <ControlledSelect
                        name="frequency"
                        control={control}
                        options={[
                          // {id: "daily", value: "Daily"},
                          {id: "weekly", value: "Weekly"},
                          // {id: "bi-monthly", value: "Bi-Monthly"},
                          {id: "monthly", value: "Monthly"},
                          // {id: "quarterly", value: "Quarterly"},
                          // {id: "yearly", value: "Yearly"},
                        ]}
                        defaultSelected={{id: "weekly", value: "Weekly"}}
                      />
                    </Field>
                    <Field
                      label="Campaigns"
                      invalid={!!errors.campaign}
                      error="Please select a campaign."
                    >
                      <ControlledReactSelect
                        name="campaign"
                        control={control}
                        options={_campaigns}
                        defaultSelected={_campaigns[0]}
                        searchable
                      />
                    </Field>
                    <Field
                      label="Email Templates"
                      invalid={!!errors.emailTemplate}
                      error="Please select an email template."
                    >
                      <ControlledReactSelect
                        name="emailTemplate"
                        control={control}
                        options={_templates}
                        defaultSelected={_templates[0]}
                        searchable
                      />
                    </Field>                    
                    <Field
                      label="Email Templates No Interaction"
                      invalid={!!errors.emailTemplateNoInteraction}
                      error="Please select an email template for No Interaction."
                    >
                      <ControlledReactSelect
                        name="emailTemplateNoInteraction"
                        control={control}
                        options={_templates}
                        defaultSelected={_templates[0]}
                        searchable
                      />
                    </Field>
                    <br />

                    {emails && (
                      <>
                        <h4 className="font-semibold text-gray-900">
                          Select Emails to be added to the report
                        </h4>
                        <ControlledReactSelect
                          name="emails"
                          control={control}
                          options={emails.map((item) => ({
                            value: item.id,
                            label: item.email,
                          }))}
                          defaultSelected={emails.map(
                            (item) =>
                              ({
                                value: item.id,
                                label: item.email,
                              }[0])
                          )}
                          onChange={(e) => {
                            setEmailsListSelected(e.label);
                          }}
                          searchable
                        />

                        <Button
                          className="my-4"
                          variant="secondary"
                          label="Add Email to Report"
                          onClick={(e) => {
                            e.preventDefault();
                            const _temp: string[] =
                              emailsSelected && emailsSelected.length > 0
                                ? [...emailsSelected]
                                : [];
                            if (emailsListSelected.length < 1) return false;
                            if (_temp.includes(emailsListSelected))
                              return false;
                            _temp.push(emailsListSelected);
                            setEmailsSelected(_temp);
                          }}
                        />
                      </>
                    )}

                    {emailsSelected && (
                      <>
                        <br />
                        <div className="sm:flex-auto mb-2">
                          <h4 className="font-semibold text-gray-900">
                            Emails added to the report.
                          </h4>
                        </div>
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                              >
                                <span className="sr-only">Remove</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {emailsSelected.map((email) => (
                              <tr key={email}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {email}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Button
                                    variant="pink"
                                    label="Remove"
                                    onClick={(e) => {
                                      e.preventDefault();

                                      const _temp: string[] =
                                        emailsSelected &&
                                        emailsSelected.length > 0
                                          ? [...emailsSelected]
                                          : [];
                                      const _tempCleaned: string[] =
                                        _temp.filter((v) => v !== email);

                                      setEmailsSelected(_tempCleaned);
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}

                    <br />

                    <Button
                      variant="primary"
                      label="Create Scheduled Item"
                      loading={loadingCreateScheduledItem}
                    />
                  </>
                );
              }}
            </Form>
          )}
        </div>
      </div>
    </LayoutGrid1>
  );
}
