import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";
import { Button, SectionTitle, DataTable, Alert} from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import { getOrgs, searchOrgs } from "../services/orgs";

export default function Orgs(props: any) {
  const history = useHistory();

  const [orgs, setOrgs] = useState([]);
  const [errorSearch, setErrorSearch] = useState([]);

  const [getOrgsAsync, loadingOrgs, errorGetOrgs] = useAsync<typeof getOrgs>(
    (...params) => getOrgs(...params), res => {
      return setOrgs(res);
    });

  const [searchOrgsAsync, loadingSearchOrgs] = useAsync<typeof searchOrgs>(
    (...params) => searchOrgs(...params), res => {
      return setOrgs(res);
    });

  useEffect(() => {
    getOrgsAsync();
  }, [])

  const onSearch = (query) => {
    setErrorSearch([]);
    if(query.term.length >= 3){
      searchOrgsAsync(query)
    } else if(query.term.length === 0) {
      getOrgsAsync()
    }
    else {
      setErrorSearch(["Please type more than 3 characters"]);
    }
  }

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Organizations" />
        <div className="bg-green-100 p-5 border border-green-200 rounded">
          <ActionSectionHeader name={`Create a New Organization`} />
          <Button variant="white" label="Create" onClick={() => {
            history.push(`/create/org`);
          }} />
        </div>
        <br/>
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {errorSearch && errorSearch.length > 0 && <Alert type="error" message={errorSearch} />}
          {errorGetOrgs && <Alert type="error" message={errorGetOrgs} />}
          <DataTable
            isLoading={loadingOrgs || loadingSearchOrgs}
            columns={[
              {id: 'name', display: 'Name', type: 'string', searchable: true, sortable: true},
              {id: 'type', display: 'Type', type: 'string', sortable: true},
              {id: 'orgs', display: 'Orgs', type: 'number', sortable: true},
              {id: 'users', display: 'Users', type: 'number', sortable: true},
              {id: 'campaigns', display: 'Campaigns', type: 'number', sortable: true},
              {id: 'edit', display: 'Edit', type: 'custom'}
            ]}
            rows={orgs && orgs.map((item: any) => {
              return {
                id: item.id,
                name: item.name,
                type: item.type,                
                orgs: parseInt(item.orgs),                
                users: parseInt(item.users),
                campaigns: parseInt(item.campaigns),
                edit: <Button variant="secondary" label="Edit"
                onClick={() => {
                  history.push(`/orgs/${item.id}`);
                }} />
              }
            })}
            onRowClick={(item, index) => {
              history.push(`/orgs/${item.id}`);
            }}
            onSearch={onSearch}
          />
        </div>
      </div>
    </LayoutGrid1>
  )
};