import React from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import { Button, SectionTitle, Form, Field, Input, ControlledSelect } from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import { postOrg } from "../services/orgs";

export default function OrgCreate(props: any) {
  const history = useHistory();
  
  const [postOrgAsync, loadingPostOrg, errorPostOrg] = useAsync<typeof postOrg>(
    (...params) => postOrg(...params), res => {
      history.push(`/orgs`);
    });

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">                
        <SectionTitle title="Create a New Organization" />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          <Form
            onSubmit={(data) => {
              postOrgAsync({
                name: data.name,
                type: data.type.value,              
              });
            }}
            >
            {({ register, control, errors }) => {          
              return( <>
                <Field label="Name"
                  invalid={!!errors.name}
                  error="Name is required.">
                  <Input
                    name="name"
                    type="text"
                    {...register("name", {
                      required: true
                    })}
                  />
                </Field> 
                <Field label="Type" invalid={!!errors.type} error="Please select an org type.">
                  <ControlledSelect
                    name="type"
                    control={control}
                    options={[
                      { id: "retailer", value: "retailer" },
                      { id: "brand", value: "brand" },
                      { id: "sales", value: "sales" },
                    ]}
                    defaultSelected={{ id: "retailer", value: "retailer" }}
                  />
                </Field>
                <br/>
                <Button variant="primary" label="Create Organization" loading={loadingPostOrg} />
              </>)}
            }
          </Form>
        </div>
      </div>
    </LayoutGrid1>
  );
};
