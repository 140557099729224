import React from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import { Button, SectionTitle, Form, Field, Input } from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import { postBrand } from "../services/brands";

// https://www.geeksforgeeks.org/how-to-upload-files-in-firebase-storage-using-reactjs/

export default function BrandCreate(props: any) {
  const history = useHistory();  

  const [postBrandAsync, loadingPostBrand, errorPostBrand] = useAsync<typeof postBrand>(
    (...params) => postBrand(...params), res => {
      history.push(`/brands`);
    });

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Create a New Brand" />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          <Form
            onSubmit={(data) => {
              postBrandAsync({
                name: data.name,
                description: data.description,
                website: data.website,
              });
            }}
            >
            {({ register, control, errors }) => {          
              return( <>
                <Field label="Brand Name"
                  invalid={!!errors.name}
                  error="Brand Name is required.">
                  <Input
                    name="name"
                    type="text"
                    {...register("name", {
                      required: true
                    })}
                  />
                </Field>
                <Field label="Brand Description"
                  invalid={!!errors.description}
                  >
                  <Input
                    name="description"
                    type="text"
                    {...register("description", {
                    })}
                  />
                </Field>
                <Field label="Brand Website"
                  invalid={!!errors.website}
                  >
                  <Input
                    name="website"
                    type="text"
                    {...register("website", {
                    })}
                  />
                </Field>
                <br/>
                <Button variant="primary" label="Create Brand" loading={loadingPostBrand} />
              </>)}
            }
          </Form>
        </div>
      </div>
    </LayoutGrid1>
  );
};
