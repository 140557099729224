import React, {useContext, useEffect, useState} from "react";
import {useHistory, NavLink} from "react-router-dom";

import LayoutGrid1 from "../../boilerplate/layout-grid-1";

import useAsync from "../services/utils/useAsync";
import {googleAuth, googleAuthRedirect} from "../services/login";

import {UserContext} from "../UserProvider";

import Devider from "../components/Devider";

import logo from "../public/doorboost.png";

import {Button, SectionTitle, Form, Field, Input} from "@mutable/meiosis";

export default function Login(props: any) {
  const {login, logout}: any = useContext(UserContext);
  const history = useHistory();

  const [msg, setMsg] = useState(false);
  const [checkingState, setCheckingState] = useState(true);

  const [googleAsync, loadingGoogle, errorGoogle] = useAsync<typeof googleAuth>(
    (...params) => googleAuth(...params),
    (res) => {
      setMsg(true);
    }
  );

  const [googleRedirectAsync, loadingGoogleRedirect, errorGoogleRedirect] =
    useAsync<typeof googleAuthRedirect>(
      (...params) => googleAuthRedirect(...params),
      (res) => {
        setCheckingState(false);
        if (res?.isAdmin) {
          console.log("res?.isAdmin", res?.isAdmin);
          return login(res).then((response) => {
            history.push("/");
            return null;
          });
        } else {
          console.log("res?.isAdmin fasle");
          return logout();
          // .then((response) => {
          //   history.push('/');
          // })
        }
      }
    );

  useEffect(() => {
    googleRedirectAsync({something: "something"});
  }, []);

  return (
    <LayoutGrid1>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <NavLink
          to="/"
          className="px-3 py-6 text-white text-sm font-medium hover:text-pink-500 hover:opacity-80 flex items-center"
        >
          <img src={logo} alt="Logo" className="pl-10 pr-10" />
        </NavLink>

        {checkingState && (
          <SectionTitle title="Checking your Login State, please wait." />
        )}

        {msg && !checkingState && (
          <>
            <h5>Please check your email for a login link.</h5>
            <Button label="Try Again" onClick={() => setMsg(false)} />
          </>
        )}
        <Devider />
        {!msg && !checkingState && <SectionTitle title="Login With Google" />}
        {!msg && !checkingState && (
          <>
            <h5>
              Please provide your email address then click Login With Google
            </h5>
            <Form
              onSubmit={(data) => {
                googleAsync({
                  email: data.email,
                  url: `${window.location.origin}/callback?email=${data.email}&redirect=/`,
                });
              }}
            >
              {({register, control, errors}) => (
                <>
                  <Field
                    label="Email"
                    invalid={!!errors.email}
                    error="Email is required."
                  >
                    <Input
                      name="email"
                      type="text"
                      {...register("email", {
                        required: true,
                      })}
                    />
                  </Field>
                  <Devider />
                  <Button variant="primary" label="Login with Google" />
                </>
              )}
            </Form>
          </>
        )}

        <Devider />

        <NavLink
          to="/tos-eng"
          className="px-3 py-6 text-gray-400 text-sm font-medium hover:text-pink-500"
        >
          Terms of Service in English
        </NavLink>
        <NavLink
          to="/tos-de"
          className="px-3 py-6 text-gray-400 text-sm font-medium hover:text-pink-500"
        >
          Terms of Service in German
        </NavLink>
      </div>
    </LayoutGrid1>
  );
}
