import { useState } from "react";

const useAsync = <T extends (...args: any[]) => Promise<any>>(action: T, callback: (...args: ReturnType<T>[]) => any): [T, boolean, string] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const doAction = (...args: any[]) => {
    setLoading(true);
    setError("");

    return action(...args)
      .then((res: any) =>
        callback(res))
      .catch((err: { details: string }) =>
        setError(err.details))
      .finally(() =>
        setLoading(false)
      );
  };
  return [doAction as T, loading, error];
};

export default useAsync;