import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";

import {
  Button,
  SectionTitle,
  Form,
  Field,
  Input,
  ControlledSelect,
  Table,
  ControlledReactSelect,
} from "@mutable/meiosis";

import useAsync from "../services/utils/useAsync";
import WithLoading from "../services/utils/WithLoading";

import {
  getOrg,
  putOrg,
  deleteOrg,
  listOrgUsers,
  deleteOrgUser,
  addOrgUser,
  getOrgCampaigns,
  addOrgCampaign,
  deleteOrgCampaign,
  getOrgs,
  getOrgOrgs,
  addOrgOrg,
  deleteOrgOrg,
} from "../services/orgs";
import {getCampaigns} from "../services/campaigns";
import {getUsers} from "../services/users";

const TableWithLoading = WithLoading(Table);

export default function Org(props: any) {
  const history = useHistory();

  const [orgId, setOrgId] = useState<string>(props.match.params.id);
  const [org, setOrg] = useState();

  const [orgUsers, setOrgUsers] = useState();
  const [orgCampaigns, setOrgCampaigns] = useState();
  const [users, setUsers] = useState();
  const [campaigns, setCampaigns] = useState();

  const [orgs, setOrgs] = useState();
  const [orgOrgs, setOrgOrgs] = useState();

  const [getOrgAsync, loadingOrg, errorGetOrg] = useAsync<typeof getOrg>(
    (...params) => getOrg(...params),
    (res) => {
      return setOrg(res);
    }
  );

  const [putOrgAsync, loadingPutOrg, errorPutOrg] = useAsync<typeof putOrg>(
    (...params) => putOrg(...params),
    (res) => {
      return getOrgAsync({id: orgId});
    }
  );

  const [deleteOrgAsync, loadingDeleteOrg, errorDeleteOrg] = useAsync<
    typeof deleteOrg
  >(
    (...params) => deleteOrg(...params),
    (res) => {
      history.push(`/orgs`);
    }
  );

  const [getUsersAsync, loadingGetUsers, errorGetUsers] = useAsync<
    typeof getUsers
  >(
    (...params) => getUsers(...params),
    (res) => {
      return setUsers(res);
    }
  );

  const [getCampaignsAsync, loadingGetCampaigns, errorGetCampaigns] = useAsync<
    typeof getCampaigns
  >(
    (...params) => getCampaigns(...params),
    (res) => {
      return setCampaigns(res);
    }
  );

  const [addOrgUserAsync, loadingAddOrgUser, errorAddOrgUser] = useAsync<
    typeof addOrgUser
  >(
    (...params) => addOrgUser(...params),
    (res) => {
      return listOrgUsersAsync({id: orgId});
    }
  );

  const [listOrgUsersAsync, loadingOrgUsers, errorListOrgUsers] = useAsync<
    typeof listOrgUsers
  >(
    (...params) => listOrgUsers(...params),
    (res) => {
      return setOrgUsers(res);
    }
  );

  const [deleteOrgUserAsync, loadingDeleteOrgUser, errorDeleteOrgUser] =
    useAsync<typeof deleteOrgUser>(
      (...params) => deleteOrgUser(...params),
      (res) => {
        return listOrgUsersAsync({id: orgId});
      }
    );

  const [getOrgCampaignsAsync, loadingOrgCampaigns, errorGetOrgCampaigns] =
    useAsync<typeof getOrgCampaigns>(
      (...params) => getOrgCampaigns(...params),
      (res) => {
        return setOrgCampaigns(res);
      }
    );

  const [addOrgCampaignAsync, loadingAddOrgCampaign, errorAddOrgCampaign] =
    useAsync<typeof addOrgCampaign>(
      (...params) => addOrgCampaign(...params),
      (res) => {
        return getOrgCampaignsAsync({id: orgId});
      }
    );

  const [
    deleteOrgCampaignAsync,
    loadingDeleteOrgCampaign,
    errorDeleteOrgCampaign,
  ] = useAsync<typeof deleteOrgCampaign>(
    (...params) => deleteOrgCampaign(...params),
    (res) => {
      return getOrgCampaignsAsync({id: orgId});
    }
  );

  const [getOrgsAsync, loadingOrgs, errorGetOrgs] = useAsync<typeof getOrgs>(
    (...params) => getOrgs(...params),
    (res) => {
      return setOrgs(res);
    }
  );

  const [getOrgOrgsAsync, loadingOrgOrgs, errorGetOrgOrgs] = useAsync<
    typeof getOrgOrgs
  >(
    (...params) => getOrgOrgs(...params),
    (res) => {
      return setOrgOrgs(res);
    }
  );

  const [addOrgOrgAsync, loadingAddOrgOrg, errorAddOrgOrg] = useAsync<
    typeof addOrgOrg
  >(
    (...params) => addOrgOrg(...params),
    (res) => {
      return getOrgOrgsAsync({id: orgId});
    }
  );

  const [deleteOrgOrgAsync, loadingDeleteOrgOrg, errorDeleteOrgOrg] = useAsync<
    typeof deleteOrgOrg
  >(
    (...params) => deleteOrgOrg(...params),
    (res) => {
      return getOrgOrgsAsync({id: orgId});
    }
  );

  useEffect(() => {
    setOrgId(props.match.params.id);
    getOrgAsync({id: orgId});

    listOrgUsersAsync({id: orgId});
    getUsersAsync();
    getCampaignsAsync();
    getOrgCampaignsAsync({id: orgId});

    getOrgsAsync();
    getOrgOrgsAsync({id: orgId});
  }, []);

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        {org && (
          <>
            <SectionTitle title={`${org.name}`} />

            <div className="bg-gray-100 p-5 border border-gray-200 rounded">
              <ActionSectionHeader name={`Edit ${org.name}`} />
              <Form
                onSubmit={(data) => {
                  putOrgAsync({
                    id: data.id,
                    name: data.name,
                    type: data.type,
                  });
                }}
                defaultValues={org}
              >
                {({register, control, errors}) => {
                  return (
                    <>
                      <Field
                        label="Name"
                        invalid={!!errors.name}
                        error="Name is required."
                      >
                        <Input
                          name="name"
                          type="text"
                          {...register("name", {
                            required: true,
                          })}
                        />
                      </Field>
                      <Field label="Org Type (Uneditable)">
                        <Input
                          name="type"
                          type="text"
                          {...register("type")}
                          disabled
                        />
                      </Field>
                      <br />
                      <Button
                        variant="primary"
                        label="Update"
                        loading={loadingPutOrg}
                      />
                    </>
                  );
                }}
              </Form>
            </div>

            <div className="bg-red-100 mt-5 p-5 border border-red-200 rounded">
              <ActionSectionHeader name={`Delete ${org.name} org`} />
              <Button
                variant="pink"
                label="Delete"
                loading={loadingDeleteOrg}
                onClick={() => {
                  deleteOrgAsync({id: orgId});
                }}
              />
              <br />
            </div>
          </>
        )}

        <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
          <ActionSectionHeader name="Select and Add a User to this Organization" />
          {users && (
            <Form
              onSubmit={(data) => {
                addOrgUserAsync({id: orgId, users: [data.user.label]});
              }}
              defaultValues={users}
            >
              {({register, control, errors}) => {
                const _users = users.map((item) => {
                  return {
                    value: item.id,
                    label: item.email,
                  };
                });

                return (
                  <>
                    <Field
                      label="Users"
                      invalid={!!errors.user}
                      error="Please select a user."
                    >
                      <ControlledReactSelect
                        name="user"
                        control={control}
                        options={_users}
                        defaultSelected={_users[0]}
                        searchable
                      />
                    </Field>

                    <br />
                    <Button
                      variant="primary"
                      label="Add User to Organization"
                      loading={loadingAddOrgUser}
                    />
                  </>
                );
              }}
            </Form>
          )}

          <br />

          <ActionSectionHeader name="Users Connected to this Organization" />
          {orgUsers && (
            <>
              <TableWithLoading
                isLoading={loadingOrgUsers}
                columns={["User Email", ""]}
                rows={
                  orgUsers &&
                  orgUsers.map((item: any) => {
                    return {
                      email: (
                        <Button
                          variant="secondary"
                          label={item.email}
                          onClick={() => {
                            history.push(`/users/${item.id}`);
                          }}
                        />
                      ),
                      remove: (
                        <Button
                          variant="pink"
                          label="Remove"
                          onClick={() => {
                            deleteOrgUserAsync({
                              id: orgId,
                              users: [item.email],
                            });
                          }}
                        />
                      ),
                    };
                  })
                }
              />
            </>
          )}
        </div>

        <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
          <ActionSectionHeader name="Select and Add a Campaign to this Organization" />

          {campaigns && (
            <Form
              onSubmit={(data) => {
                addOrgCampaignAsync({id: orgId, campId: data.campaign.value});
              }}
              defaultValues={campaigns}
            >
              {({register, control, errors}) => {
                const _campaigns = campaigns.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                });

                return (
                  <>
                    <Field
                      label="Campaigns"
                      invalid={!!errors.campaign}
                      error="Please select a campaign."
                    >
                      <ControlledReactSelect
                        name="campaign"
                        control={control}
                        options={_campaigns}
                        defaultSelected={_campaigns[0]}
                        searchable
                      />
                    </Field>

                    <br />
                    <Button
                      variant="primary"
                      label="Add Campaign to Organization"
                      loading={loadingAddOrgCampaign}
                    />
                  </>
                );
              }}
            </Form>
          )}

          <br />
          <ActionSectionHeader name="Campaigns Connected to this Organization" />
          {orgCampaigns && (
            <>
              <TableWithLoading
                isLoading={loadingOrgCampaigns}
                columns={["Campaign Name", ""]}
                rows={
                  orgCampaigns &&
                  orgCampaigns.map((item: any) => {
                    return {                      
                      name: (
                        <Button
                          variant="secondary"
                          label={item.name}
                          onClick={() => {
                            history.push(`/campaigns/${item.id}`);
                          }}
                        />
                      ),
                      remove: (
                        <Button
                          variant="pink"
                          label="Remove"
                          onClick={() => {
                            deleteOrgCampaignAsync({
                              id: orgId,
                              campId: item.id,
                            });
                          }}
                        />
                      ),
                    };
                  })
                }
              />
            </>
          )}
        </div>

        <div className="bg-gray-100 my-5 p-5 border border-gray-200 rounded">
          <ActionSectionHeader name="Select and Connect a Sub Organization to this Organization" />

          {orgs && (
            <Form
              onSubmit={(data) => {
                addOrgOrgAsync({id: orgId, childId: data.org.value});
              }}
              defaultValues={orgs}
            >
              {({register, control, errors}) => {
                const _orgs = orgs.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                });

                return (
                  <>
                    <Field
                      label="Orgs"
                      invalid={!!errors.campaign}
                      error="Please select a org."
                    >
                      <ControlledReactSelect
                        name="org"
                        control={control}
                        options={_orgs}
                        defaultSelected={_orgs[0]}
                        searchable
                      />
                    </Field>

                    <br />
                    <Button
                      variant="primary"
                      label="Connect Sub Organization to this Organization"
                      loading={loadingAddOrgOrg}
                    />
                  </>
                );
              }}
            </Form>
          )}

          <br />

          <ActionSectionHeader name="Sub Organizations Connected to this Organization" />
          {orgOrgs && (
            <>
              <TableWithLoading
                isLoading={loadingOrgOrgs}
                columns={["Org Name", ""]}
                rows={
                  orgOrgs &&
                  orgOrgs.map((item: any) => {
                    return {
                      name: (
                        <Button
                          variant="secondary"
                          label={item.name}
                          onClick={() => {
                            history.push(`/orgs/${item.id}`);
                          }}
                        />
                      ),
                      remove: (
                        <Button
                          variant="pink"
                          label="Remove"
                          onClick={() => {
                            deleteOrgOrgAsync({id: orgId, childId: item.id});
                          }}
                        />
                      ),
                    };
                  })
                }
              />
            </>
          )}
        </div>
      </div>
    </LayoutGrid1>
  );
}
