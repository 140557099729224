import React, { useContext, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import qs from 'qs';
import { useHistory } from "react-router-dom";
import useAsync from "../services/utils/useAsync";
import { magicLink } from "../services/login";
import { UserContext } from "../UserProvider";

const LoginCallback = (props: any) => {
  const _isMounted = useRef(true);

  const { login, logout }: any = useContext(UserContext);
  const history = useHistory();

  const [setLoginAsync, loadingLogin, errorLogin] = useAsync<typeof magicLink>(
    (...params) => magicLink(...params), res => {
      _isMounted.current = false;
      if(res.isAdmin){
        return login(res)
        .then((response) => {
          history.push('/');
          return null;
        })
      } else {
        return logout()
        .then((response) => {
          history.push('/');
        })
      }
    });

  const email = qs.parse(props.location.search, { ignoreQueryPrefix: true }).email;
  // const redirect = qs.parse(props.location.search, { ignoreQueryPrefix: true }).redirect;
  
  useEffect(() => {
    setLoginAsync({email: email, url: window.location.href});
  }, []);
  
  return null;
}

export default LoginCallback;
