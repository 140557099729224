import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LayoutGrid1 from "../../boilerplate/layout-grid-1";
import ActionSectionHeader from "../components/ActionSectionHeader";
import { Button, SectionTitle, DataTable, Alert } from "@mutable/meiosis";
import useAsync from "../services/utils/useAsync";
import { getBrands, searchBrands } from "../services/brands";

export default function Brands(props: any) {
  const history = useHistory();

  const [brands, setBrands] = useState([]);
  const [errorSearch, setErrorSearch] = useState([]);

  const [getBrandsAsync, loadingBrands, errorGetBrands] = useAsync<
    typeof getBrands
  >(
    (...params) => getBrands(...params),
    (res) => {
      return setBrands(res);
    }
  );

  const [searchBrandsAsync, loadingSearchBrands, errorSearchBrands] = useAsync<
    typeof searchBrands
  >(
    (...params) => searchBrands(...params),
    (res) => {
      return setBrands(res);
    }
  );

  useEffect(() => {
    getBrandsAsync();
  }, []);

  const onSearch = (query) => {
    setErrorSearch([]);
    if (query.term.length >= 3) {
      searchBrandsAsync(query);
    } else if (query.term.length === 0) {
      getBrandsAsync();
    } else {
      setErrorSearch(["Please type more than 3 characters"]);
    }
  };

  return (
    <LayoutGrid1>
      <div className="min-h-screen mb-10">
        <SectionTitle title="Brands" />
        <div className="bg-green-100 p-5 border border-green-200 rounded">
          <ActionSectionHeader name={`Create a New Brand`} />
          <Button
            variant="white"
            label="Create"
            onClick={() => {
              history.push(`/create/brand`);
            }}
          />
        </div>
        <br />
        <div className="bg-gray-100 p-5 border border-gray-200 rounded">
          {errorSearch && errorSearch.length > 0 && (
            <Alert type="error" message={errorSearch} />
          )}
          {errorGetBrands && <Alert type="error" message={errorGetBrands} />}
          <DataTable
            isLoading={loadingBrands || loadingSearchBrands}
            columns={[
              {
                id: "name",
                display: "Name",
                type: "string",
                searchable: true,
                sortable: true,
              },
              { id: "campaigns", display: "Campaigns", type: "custom" },
              { id: "retailers", display: "Retailers", type: "custom" },
              { id: "edit", display: "Edit", type: "custom" },
            ]}
            rows={
              brands &&
              brands.map((item: any) => {
                return {
                  id: item.id,
                  name: item.name,
                  campaigns: (
                    <Button
                      variant="secondary"
                      label="Campaigns"
                      onClick={() => {
                        history.push(`/brands/${item.id}/campaigns`);
                      }}
                    />
                  ),
                  retailers: (
                    <Button
                      variant="secondary"
                      label="Retailers"
                      onClick={() => {
                        history.push(`/brands/${item.id}/retailers`);
                      }}
                    />
                  ),
                  edit: (
                    <Button
                      variant="secondary"
                      label="Edit"
                      onClick={() => {
                        history.push(`/brands/${item.id}`);
                      }}
                    />
                  ),
                };
              })
            }
            onRowClick={(item, index) => {
              history.push(`/brands/${item.id}`);
            }}
            onSearch={onSearch}
          />
        </div>
      </div>
    </LayoutGrid1>
  );
}
